import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export const UserSettingsFragmentDoc = gql`
    fragment UserSettings on UserSettingsObject {
  id
  selectedOrganization {
    id
    name
  }
}
    `;
export const UserFragmentDoc = gql`
    fragment User on UserObject {
  id
  firstName
  lastName
  fullName
  userName
  phoneNumber
  roles
  email
  profileBio
  offerCollectionEmail
  profilePicture {
    fileName
    url
  }
  settings {
    ...UserSettings
  }
}
    ${UserSettingsFragmentDoc}`;
export const AccessControlFragmentDoc = gql`
    fragment AccessControl on AccessControlObject {
  id
  accessRole
  accessUser {
    ...User
  }
}
    ${UserFragmentDoc}`;
export const PublicUserFragmentDoc = gql`
    fragment PublicUser on PublicUserObject {
  id
  firstName
  lastName
  fullName
  userName
  phoneNumber
  email
  offerCollectionEmail
  profilePicture {
    fileName
    url
  }
}
    `;
export const MlsListingAddressFragmentDoc = gql`
    fragment MLSListingAddress on MLSListingAddressObject {
  city
  country
  crossStreet
  full
  postalCode
  state
  streetName
  streetNumber
  streetNumberText
  unit
}
    `;
export const ActivityFragmentDoc = gql`
    fragment Activity on ActivityObject {
  id
  type
  createdAt
  metadata
  user {
    ...PublicUser
  }
  listing @include(if: $includeListing) {
    id
    property {
      id
      slug
    }
    mlsListing {
      id
      address {
        ...MLSListingAddress
      }
    }
  }
}
    ${PublicUserFragmentDoc}
${MlsListingAddressFragmentDoc}`;
export const AppEventFragmentDoc = gql`
    fragment AppEvent on AppEventObject {
  id
  name
  doneBy {
    id
  }
  offer {
    id
  }
}
    `;
export const MlsListingPropertyFragmentDoc = gql`
    fragment MLSListingProperty on MLSListingPropertyObject {
  roof
  cooling
  style
  area
  bathsFull
  bathsHalf
  stories
  fireplaces
  flooring
  heating
  bathrooms
  foundation
  poolFeatures
  laundryFeatures
  occupantName
  ownerName
  lotDescription
  acres
  subType
  bedrooms
  interiorFeatures
  lotSize
  areaSource
  maintenanceExpense
  additionalRooms
  exteriorFeatures
  water
  view
  lotSizeArea
  lotSizeAreaUnits
  subdivision
  construction
  type
  garageSpaces
  bathsThreeQuarter
  accessibility
  occupantType
  yearBuilt
  subTypeText
}
    `;
export const MlsListingContactFragmentDoc = gql`
    fragment MLSListingContact on MLSListingContactObject {
  email
  office
  cell
}
    `;
export const MlsListingAgentObjectFragmentDoc = gql`
    fragment MLSListingAgentObject on MLSListingAgentObject {
  id
  mlsId
  firstName
  lastName
  contact {
    ...MLSListingContact
  }
}
    ${MlsListingContactFragmentDoc}`;
export const MlsListingMlsFragmentDoc = gql`
    fragment MLSListingMLS on MLSListingMLSObject {
  statusText
  status
  daysOnMarket
  originalEntryTimestamp
  originatingSystemName
  logoUrl
  disclaimer
}
    `;
export const MlsListingAssociationFragmentDoc = gql`
    fragment MLSListingAssociation on MLSListingAssociationObject {
  fee
  frequency
  name
  amenities
}
    `;
export const MlsListingComplianceFragmentDoc = gql`
    fragment MLSListingCompliance on MLSListingComplianceObject {
  buyerAgencyCompensation
  buyerAgencyCompensationType
  subAgencyCompensation
  subAgencyCompensationType
  attributionContact
}
    `;
export const MlsListingSalesFragmentDoc = gql`
    fragment MLSListingSales on MLSListingSalesObject {
  closeDate
  closePrice
  contractDate
}
    `;
export const MlsListingGeoFragmentDoc = gql`
    fragment MLSListingGeo on MLSListingGeoObject {
  location {
    lat
    lon
  }
}
    `;
export const MlsListingFragmentDoc = gql`
    fragment MLSListing on MLSListingObject {
  id
  mlsId
  listingId
  listDate
  listPrice
  originalListPrice
  terms
  photos
  remarks
  modified
  mlsName
  address {
    ...MLSListingAddress
  }
  addressFull
  property {
    ...MLSListingProperty
  }
  office {
    brokerid
    name
    servingName
    contact {
      ...MLSListingContact
    }
  }
  agent {
    ...MLSListingAgentObject
  }
  mls {
    ...MLSListingMLS
  }
  coAgent {
    ...MLSListingAgentObject
  }
  association {
    ...MLSListingAssociation
  }
  compliance {
    ...MLSListingCompliance
  }
  sales {
    ...MLSListingSales
  }
  geo {
    ...MLSListingGeo
  }
}
    ${MlsListingAddressFragmentDoc}
${MlsListingPropertyFragmentDoc}
${MlsListingContactFragmentDoc}
${MlsListingAgentObjectFragmentDoc}
${MlsListingMlsFragmentDoc}
${MlsListingAssociationFragmentDoc}
${MlsListingComplianceFragmentDoc}
${MlsListingSalesFragmentDoc}
${MlsListingGeoFragmentDoc}`;
export const PreferenceFragmentDoc = gql`
    fragment Preference on PreferenceObject {
  id
  name
  slug
  usStates
  fieldId
  valueType
}
    `;
export const PreferenceValueFragmentDoc = gql`
    fragment PreferenceValue on PreferenceValueObject {
  preference {
    ...Preference
  }
  numberValue
  textValue
  stringValue
  stringArray
  booleanValue
  dateTimeValue
  readOnly
}
    ${PreferenceFragmentDoc}`;
export const PublicStorefrontConfigurationStatusObjectFragmentDoc = gql`
    fragment PublicStorefrontConfigurationStatusObject on StorefrontConfigurationStatusObject {
  hasSetup
  configuredCount
  progressTotal
  sellerPreferences
  comparables
  documents
}
    `;
export const ListingFragmentDoc = gql`
    fragment Listing on ListingObject {
  id
  isOwner
  property {
    id
    slug
    state
  }
  offersSubmitted {
    id
    status
  }
  listDate
  status
  offersCountDisplay
  submittedOffersCount
  followerCount
  listingAgent {
    id
    inboundEmail
    user {
      id
      firstName
      lastName
      fullName
      profilePicture {
        fileName
        url
      }
      userName
    }
  }
  coListingAgent {
    id
    inboundEmail
    user {
      id
      firstName
      lastName
      fullName
      phoneNumber
      profilePicture {
        fileName
        url
      }
      email
    }
  }
  mlsListing {
    ...MLSListing
  }
  listingPreferences @include(if: $preferenceValues) {
    ...PreferenceValue
  }
  isPremium
  storefrontSetup {
    listingPackage {
      fileName
      url
    }
    continueStep
  }
  setupDetails {
    ...PublicStorefrontConfigurationStatusObject
  }
}
    ${MlsListingFragmentDoc}
${PreferenceValueFragmentDoc}
${PublicStorefrontConfigurationStatusObjectFragmentDoc}`;
export const ConsumerFragmentDoc = gql`
    fragment Consumer on ConsumerObject {
  id
  email
  fullName
  phoneNumber
  isEntity
  entityName
  entityTitle
  profilePicture {
    fileName
    url
  }
}
    `;
export const PartyFragmentDoc = gql`
    fragment Party on PartyObject {
  id
  purchaseOffers {
    id
    listing {
      id
      property {
        id
        slug
        state
      }
      mlsListing {
        ...MLSListing
      }
      isPremium
      listingAgent {
        id
        user {
          id
        }
      }
    }
  }
  primaryConsumer {
    ...Consumer
  }
  secondaryConsumers {
    ...Consumer
  }
  partyFinancing {
    id
    financingType
    loanAmount
    percentDownPayment
    cashReserves
    preapprovalFile {
      fileName
      url
    }
  }
}
    ${MlsListingFragmentDoc}
${ConsumerFragmentDoc}`;
export const IndigoOffersSourceFragmentDoc = gql`
    fragment IndigoOffersSource on IndigoOffersSource {
  type
  metadata {
    confidence
    distanceMiles
    timePeriodMonths
  }
}
    `;
export const CompsSourceFragmentDoc = gql`
    fragment CompsSource on CompsSource {
  type
}
    `;
export const MarketDataSourceFragmentDoc = gql`
    fragment MarketDataSource on MarketDataSource {
  __typename
  ... on IndigoOffersSource {
    ...IndigoOffersSource
  }
  ... on CompsSource {
    ...CompsSource
  }
}
    ${IndigoOffersSourceFragmentDoc}
${CompsSourceFragmentDoc}`;
export const KeyTermFragmentDoc = gql`
    fragment KeyTerm on KeyTerm {
  type
  unit
  context {
    marketData {
      low
      high
    }
    marketDataPercentages {
      yes
      no
    }
    source {
      ...MarketDataSource
    }
  }
  recommendation
}
    ${MarketDataSourceFragmentDoc}`;
export const KeyTermErrorFragmentDoc = gql`
    fragment KeyTermError on KeyTermError {
  type
  unit
  reason
  sourceType
}
    `;
export const KeyTermResultFragmentDoc = gql`
    fragment KeyTermResult on KeyTermResult {
  __typename
  ... on KeyTerm {
    ...KeyTerm
  }
  ... on KeyTermError {
    ...KeyTermError
  }
}
    ${KeyTermFragmentDoc}
${KeyTermErrorFragmentDoc}`;
export const ContingencyFragmentDoc = gql`
    fragment Contingency on Contingency {
  type
  context {
    marketData {
      yes
      no
    }
    source {
      ...MarketDataSource
    }
  }
  recommendation
}
    ${MarketDataSourceFragmentDoc}`;
export const ContingencyErrorFragmentDoc = gql`
    fragment ContingencyError on ContingencyError {
  type
  reason
}
    `;
export const ContingencyResultFragmentDoc = gql`
    fragment ContingencyResult on ContingencyResult {
  __typename
  ... on Contingency {
    ...Contingency
  }
  ... on ContingencyError {
    ...ContingencyError
  }
}
    ${ContingencyFragmentDoc}
${ContingencyErrorFragmentDoc}`;
export const PriceAnalysisFragmentDoc = gql`
    fragment PriceAnalysis on PriceAnalysis {
  avgCompBidAskRatio
  isPotentiallyOverpriced
  isPotentiallyUnderpriced
  pricePositioning
}
    `;
export const CompetitivenessMetricsFragmentDoc = gql`
    fragment CompetitivenessMetrics on CompetitivenessMetrics {
  bidAsk
  bidAskScore
  daysOnMarket
  daysOnMarketScore
  priceAnalysis {
    ...PriceAnalysis
  }
}
    ${PriceAnalysisFragmentDoc}`;
export const CompetitivenessScoreFragmentDoc = gql`
    fragment CompetitivenessScore on CompetitivenessScore {
  error
  metrics {
    ...CompetitivenessMetrics
  }
  rating
  score
}
    ${CompetitivenessMetricsFragmentDoc}`;
export const OfferRecsFragmentDoc = gql`
    fragment OfferRecs on OfferRecsPayload {
  keyTerms {
    ...KeyTermResult
  }
  contingencies {
    ...ContingencyResult
  }
  competitiveness {
    ...CompetitivenessScore
  }
}
    ${KeyTermResultFragmentDoc}
${ContingencyResultFragmentDoc}
${CompetitivenessScoreFragmentDoc}`;
export const AgentContingencyOfferRecFragmentDoc = gql`
    fragment AgentContingencyOfferRec on AgentContingencyOfferRec {
  value
  notes
  type
}
    `;
export const AgentKeyTermsOfferRecFragmentDoc = gql`
    fragment AgentKeyTermsOfferRec on AgentKeyTermsOfferRec {
  value
  notes
  type
}
    `;
export const AgentOfferRecsFragmentDoc = gql`
    fragment AgentOfferRecs on AgentOfferRecsPayload {
  contingencies {
    ...AgentContingencyOfferRec
  }
  keyTerms {
    ...AgentKeyTermsOfferRec
  }
}
    ${AgentContingencyOfferRecFragmentDoc}
${AgentKeyTermsOfferRecFragmentDoc}`;
export const OfferScoreFragmentDoc = gql`
    fragment OfferScore on OfferScore {
  reason
  value
}
    `;
export const ShareLinkFragmentDoc = gql`
    fragment ShareLink on ShareLinkObject {
  id
  token
  expiresAt
  accessType
  status
  recipientEmail
  recipientPhoneNumber
}
    `;
export const BuyerOfferAnalysisFragmentDoc = gql`
    fragment BuyerOfferAnalysis on BuyerOfferAnalysisObject {
  id
  buyersAgent {
    id
    user {
      ...User
    }
  }
  listing {
    ...Listing
  }
  party {
    ...Party
  }
  indigoOfferRecs {
    ...OfferRecs
  }
  agentOfferRecs {
    ...AgentOfferRecs
  }
  offerScore {
    ...OfferScore
  }
  agentNotes
  title
  shareLinks {
    ...ShareLink
  }
}
    ${UserFragmentDoc}
${ListingFragmentDoc}
${PartyFragmentDoc}
${OfferRecsFragmentDoc}
${AgentOfferRecsFragmentDoc}
${OfferScoreFragmentDoc}
${ShareLinkFragmentDoc}`;
export const InputFormatFragmentDoc = gql`
    fragment InputFormat on InputFormatObject {
  type
  options {
    precision
    formatString
    currencySymbol
  }
}
    `;
export const BoundingBoxFragmentDoc = gql`
    fragment BoundingBox on BoundingBoxObject {
  left
  top
  right
  bottom
}
    `;
export const PublicDocumentVersionFieldFragmentDoc = gql`
    fragment PublicDocumentVersionField on PublicDocumentVersionFieldObject {
  id
  align
  pageNumber
  fontFamily
  fontSize
  label
  inputFormat {
    ...InputFormat
  }
  boundingBox {
    ...BoundingBox
  }
  readOnly
  width
  height
  x
  y
  mappingKey
  fieldType
}
    ${InputFormatFragmentDoc}
${BoundingBoxFragmentDoc}`;
export const ResoOfficeFragmentDoc = gql`
    fragment RESOOffice on RESOOfficeObject {
  OfficeName
  OfficeEmail
  OfficeKey
  OfficeMlsId
  OfficeStatus
  OriginatingSystemName
}
    `;
export const GuestbookSignerFragmentDoc = gql`
    fragment GuestbookSigner on GuestbookSignerObject {
  user {
    ...User
  }
  signedAt
  isSubscribed
  openHouseId
  isWorkingWithAgent
  office {
    ...RESOOffice
  }
}
    ${UserFragmentDoc}
${ResoOfficeFragmentDoc}`;
export const InvitationFragmentDoc = gql`
    fragment Invitation on InvitationObject {
  id
  acceptedAt
  revokedAt
  expiresAt
  notificationMeta
  invitee {
    ...User
  }
  inviter {
    ...User
  }
}
    ${UserFragmentDoc}`;
export const ListingAnnouncementFragmentDoc = gql`
    fragment ListingAnnouncement on ListingAnnouncementObject {
  id
  message
  type
  bestAndFinalDate
  createdAt
}
    `;
export const ListingComparableAdjustmentsFragmentDoc = gql`
    fragment ListingComparableAdjustments on ListingComparableAdjustmentsObject {
  id
  priceAdjustment
  notes
}
    `;
export const ListingComparableFragmentDoc = gql`
    fragment ListingComparable on ListingComparableObject {
  id
  mlsListing {
    ...MLSListing
  }
  order
  adjustments {
    ...ListingComparableAdjustments
  }
}
    ${MlsListingFragmentDoc}
${ListingComparableAdjustmentsFragmentDoc}`;
export const ListingComparableSetFragmentDoc = gql`
    fragment ListingComparableSet on ListingComparableSetObject {
  id
  listingComparables {
    ...ListingComparable
  }
  agent {
    id
  }
  listing {
    id
  }
  buyerOfferAnalysis {
    id
  }
  agentNotes
}
    ${ListingComparableFragmentDoc}`;
export const ListingDocumentTypeFragmentDoc = gql`
    fragment ListingDocumentType on ListingDocumentTypeObject {
  id
  slug
  usState
  name
  formName
  type
}
    `;
export const ListingDocumentUploadFragmentDoc = gql`
    fragment ListingDocumentUpload on ListingDocumentUploadObject {
  id
  file {
    fileName
    url
  }
  listingDocumentType {
    ...ListingDocumentType
  }
  customDocumentType
  customDocumentName
}
    ${ListingDocumentTypeFragmentDoc}`;
export const ListingDocumentTypeWithDocumentsFragmentDoc = gql`
    fragment ListingDocumentTypeWithDocuments on ListingDocumentTypeObject {
  id
  document {
    id
    form
    name
    description
    activeDocumentVersion {
      id
      mappingKeys
      previewImages: hiddenPreviewImages {
        id
        ordering
        file {
          url
        }
      }
    }
  }
}
    `;
export const ListingFollowerFragmentDoc = gql`
    fragment ListingFollower on ListingFollowerObject {
  user {
    ...User
  }
  followedAt
}
    ${UserFragmentDoc}`;
export const OfferScoreNotCalculatedFragmentDoc = gql`
    fragment OfferScoreNotCalculated on OfferScoreNotCalculated {
  reason
}
    `;
export const OfferScoreResultFragmentDoc = gql`
    fragment OfferScoreResult on OfferScoreResult {
  __typename
  ... on OfferScore {
    ...OfferScore
  }
  ... on OfferScoreNotCalculated {
    ...OfferScoreNotCalculated
  }
}
    ${OfferScoreFragmentDoc}
${OfferScoreNotCalculatedFragmentDoc}`;
export const PageInfoFragmentDoc = gql`
    fragment PageInfo on PageInfoObject {
  totalCount
  hasNextPage
  hasPrevPage
  nextCursor
  prevCursor
}
    `;
export const PropertyFragmentDoc = gql`
    fragment Property on PropertyObject {
  id
  streetAddress
  unit
  city
  state
  zipCode
  zipCodePlus4
  county
  lot
  block
  platBook
  platBookPage
  subdivision
  parcelId
  yearBuilt
  otherLegalDescription
}
    `;
export const StorefrontActivitySummaryFragmentDoc = gql`
    fragment StorefrontActivitySummary on StorefrontActivitySummaryObject {
  count
  activityType
  users {
    ...User
  }
  activities {
    id
    type
    createdAt
    user {
      id
    }
  }
}
    ${UserFragmentDoc}`;
export const OpenHouseCardFragmentDoc = gql`
    fragment OpenHouseCard on OpenHouseObject {
  id
  date
  startTime
  endTime
  numGuestbookSigners
  source
}
    `;
export const PublicOpenHouseFragmentDoc = gql`
    fragment PublicOpenHouse on PublicOpenHouseObject {
  id
  date
  startTime
  endTime
}
    `;
export const PublicPreferenceFragmentDoc = gql`
    fragment PublicPreference on PublicPreferenceObject {
  id
  name
  slug
  usStates
  valueType
}
    `;
export const PublicPreferenceValueFragmentDoc = gql`
    fragment PublicPreferenceValue on PublicPreferenceValueObject {
  preference {
    ...PublicPreference
  }
  numberValue
  textValue
  stringValue
  stringArray
  booleanValue
  dateTimeValue
  readOnly
}
    ${PublicPreferenceFragmentDoc}`;
export const PublicListingFragmentDoc = gql`
    fragment PublicListing on PublicListingObject {
  id
  property {
    id
    slug
    state
    otherLegalDescription
    county
  }
  status
  offersCountDisplay
  listDate
  followerCount
  guestbookSignersCount
  currentAndUpcomingOpenHouses {
    ...PublicOpenHouse
  }
  listingAgent {
    id
    inboundEmail
    user {
      id
      firstName
      lastName
      fullName
      phoneNumber
      profilePicture {
        fileName
        url
      }
      email
      userName
    }
  }
  coListingAgent {
    id
    inboundEmail
    user {
      id
      firstName
      lastName
      fullName
      phoneNumber
      profilePicture {
        fileName
        url
      }
      email
    }
  }
  mlsListing {
    ...MLSListing
  }
  listingPreferences {
    ...PublicPreferenceValue
  }
  isPremium
  setupDetails {
    ...PublicStorefrontConfigurationStatusObject
  }
}
    ${PublicOpenHouseFragmentDoc}
${MlsListingFragmentDoc}
${PublicPreferenceValueFragmentDoc}
${PublicStorefrontConfigurationStatusObjectFragmentDoc}`;
export const PublicListingAnnouncementFragmentDoc = gql`
    fragment PublicListingAnnouncement on PublicListingAnnouncementObject {
  id
  message
  createdAt
}
    `;
export const PublicListingComparableFragmentDoc = gql`
    fragment PublicListingComparable on PublicListingComparableObject {
  id
  mlsListing {
    ...MLSListing
  }
  order
}
    ${MlsListingFragmentDoc}`;
export const PublicListingComparableSetFragmentDoc = gql`
    fragment PublicListingComparableSet on PublicListingComparableSetObject {
  listingComparables {
    ...PublicListingComparable
  }
  agent {
    id
  }
  listing {
    id
  }
  agentNotes
}
    ${PublicListingComparableFragmentDoc}`;
export const PublicListingDocumentTypeFragmentDoc = gql`
    fragment PublicListingDocumentType on PublicListingDocumentTypeObject {
  id
  slug
  usState
  name
  formName
  type
}
    `;
export const PublicListingDocumentUploadFragmentDoc = gql`
    fragment PublicListingDocumentUpload on PublicListingDocumentUploadObject {
  id
  listingDocumentType {
    ...PublicListingDocumentType
  }
  file {
    fileName
    url
  }
  customDocumentName
  customDocumentType
}
    ${PublicListingDocumentTypeFragmentDoc}`;
export const PublicListingDocumentTypeWithDocumentsFragmentDoc = gql`
    fragment PublicListingDocumentTypeWithDocuments on PublicListingDocumentTypeObject {
  id
  document {
    id
    form
    name
    description
    activeDocumentVersion {
      id
      mappingKeys
      previewImages: hiddenPreviewImages {
        id
        ordering
        file {
          url
        }
      }
    }
  }
}
    `;
export const PublicUserListingFragmentDoc = gql`
    fragment PublicUserListing on PublicListingObject {
  id
  status
  listDate
  offersCountDisplay
  property {
    id
    slug
  }
  isPremium
  mlsListing {
    ...MLSListing
  }
}
    ${MlsListingFragmentDoc}`;
export const PublicUserWithLicensesFragmentDoc = gql`
    fragment PublicUserWithLicenses on PublicUserWithLicensesObject {
  id
  firstName
  lastName
  fullName
  phoneNumber
  email
  profileBio
  profilePicture {
    fileName
    url
  }
  hasListings
  licenses {
    id
    licenseType
    licenseNumber
    mls {
      id
      name
      originatingSystemName
    }
  }
}
    `;
export const MlsObjectForAllMlSesFragmentDoc = gql`
    fragment MLSObjectForAllMLSes on MLSObject {
  id
  name
  originatingSystemName
}
    `;
export const AutofillConsumerFragmentDoc = gql`
    fragment AutofillConsumer on AutofillConsumerObject {
  email
  name
  phoneNumber
  isEntity
  entityName
  entityTitle
  id
}
    `;
export const CurrentUserFragmentDoc = gql`
    fragment CurrentUser on UserObject {
  id
  firstName
  lastName
  fullName
  userName
  email
  phoneNumber
  roles
  hasListings
  profilePicture {
    fileName
    url
  }
  profileBio
  offerCollectionEmail
  settings {
    ...UserSettings
  }
  licenses {
    id
    licenseNumber
    licenseType
    states
    mls {
      id
      shortName
      name
      states
    }
  }
  tours {
    id
    tourName
  }
}
    ${UserSettingsFragmentDoc}`;
export const ResoMemberFragmentDoc = gql`
    fragment RESOMember on RESOMemberObject {
  MemberFirstName
  MemberLastName
  MemberFullName
  MemberMiddleName
  MemberEmail
  MemberDirectPhone
  MemberMobilePhone
  MemberMlsId
  MemberNationalAssociationId
  OfficeMlsId
  MemberStatus
  OriginatingSystemName
  Media {
    MediaKey
    MediaURL
    ImageWidth
    ImageHeight
    ImageSizeDescription
    MediaModificationTimestamp
  }
}
    `;
export const FindMlsAgentFragmentDoc = gql`
    fragment FindMLSAgent on FindMLSAgentPayload {
  mlsAgent {
    ...RESOMember
  }
  mls {
    id
    name
    memberIdPrefix
    originatingSystemName
  }
  office {
    ...RESOOffice
  }
}
    ${ResoMemberFragmentDoc}
${ResoOfficeFragmentDoc}`;
export const IndigoContractsForCurrentUserFragmentDoc = gql`
    fragment IndigoContractsForCurrentUser on IndigoContractObject {
  id
  createdAt
  contract {
    id
    autofillValues @include(if: $includeFieldValues)
    contractDocuments @include(if: $includeContractDocuments) {
      id
      order
      documentVersion {
        id
        document {
          id
        }
      }
    }
    fieldValues @include(if: $includeFieldValues)
    signatureRequests {
      id
      events {
        id
        createdAt
        eventType
      }
      allSignedDocument {
        fileName
        url
      }
    }
  }
  party {
    id
    buyersAgent {
      user {
        ...User
      }
    }
    primaryConsumer {
      ...Consumer
    }
    secondaryConsumers {
      ...Consumer
    }
  }
}
    ${UserFragmentDoc}
${ConsumerFragmentDoc}`;
export const ContractValidationFragmentDoc = gql`
    fragment ContractValidation on ContractValidationObject {
  field {
    id
    mappingKey
    fieldType
    label
    displayLabel
    group
    inputFormat {
      type
      options {
        precision
        formatString
        currencySymbol
      }
    }
  }
  documentVersionField {
    id
    mappingKey
    fieldType
    label
    displayLabel
    inputFormat {
      type
      options {
        precision
        formatString
        currencySymbol
      }
    }
  }
  results {
    inputValue
    message
    validationValue
    rule {
      id
      ruleType
    }
  }
}
    `;
export const DocumentVersionFieldFragmentDoc = gql`
    fragment DocumentVersionField on DocumentVersionFieldObject {
  id
  align
  pageNumber
  fontFamily
  fontSize
  label
  inputFormat {
    ...InputFormat
  }
  boundingBox {
    ...BoundingBox
  }
  readOnly
  width
  height
  x
  y
  mappingKey
  fieldType
  labelBoundingBox {
    ...BoundingBox
  }
  labelField {
    height
    width
    x
    y
  }
}
    ${InputFormatFragmentDoc}
${BoundingBoxFragmentDoc}`;
export const ContractDocumentFragmentDoc = gql`
    fragment ContractDocument on ContractDocumentObject {
  id
  order
  documentVersion {
    id
    document {
      id
      name
      form
      documentType
    }
    mappingKeys
    version
  }
  file {
    fileName
    url
  }
  fields {
    ...DocumentVersionField
  }
  externalDocumentType
  externalDocumentName
}
    ${DocumentVersionFieldFragmentDoc}`;
export const OfferContractFragmentDoc = gql`
    fragment OfferContract on ContractObject {
  id
  fieldValues
  validations {
    ...ContractValidation
  }
  contractDocuments {
    ...ContractDocument
  }
  enabledSecondaryDocumentVersionsFromRules
  signatureRequests {
    id
    provider
    externalId
    allSignedDocument {
      fileName
      url
    }
  }
}
    ${ContractValidationFragmentDoc}
${ContractDocumentFragmentDoc}`;
export const OfferFragmentDoc = gql`
    fragment Offer on OfferObject {
  id
  status
  source
  isUploaded
  submittedAt
  noteToListingAgent
  primaryBuyer {
    ...Consumer
  }
  secondaryBuyers {
    ...Consumer
  }
  file {
    fileName
    url
  }
  events {
    id
    name
    createdAt
  }
  buyersAgent {
    id
    fullName
    user {
      ...User
    }
  }
  party {
    id
  }
}
    ${ConsumerFragmentDoc}
${UserFragmentDoc}`;
export const OffersForListingFragmentDoc = gql`
    fragment OffersForListing on OfferObject {
  ...Offer
  contract {
    id
    fieldValues
    contractDocuments {
      id
      documentVersion {
        id
        document {
          id
          documentType
        }
      }
      file {
        fileName
        url
      }
    }
    validations {
      ...ContractValidation
    }
    signatureRequests {
      id
      provider
      externalId
      allSignedDocument {
        fileName
        url
      }
    }
  }
}
    ${OfferFragmentDoc}
${ContractValidationFragmentDoc}`;
export const ListingForOrganizationFragmentDoc = gql`
    fragment ListingForOrganization on ListingObject {
  id
  status
  offersCountDisplay
  guestbookSignersCount
  submittedOffersCount
  listDate
  property {
    id
    slug
    state
    geo {
      lat
      lng
    }
  }
  currentAndUpcomingOpenHouses {
    id
    date
    startTime
    endTime
  }
  listingAgent {
    id
    user {
      ...User
    }
  }
  coListingAgent {
    id
    user {
      ...User
    }
  }
  mlsListing {
    ...MLSListing
  }
  isPremium
  organizations {
    id
  }
  setupDetails {
    configuredCount
    documents
    progressTotal
    sellerPreferences
    comparables
  }
  isSharedListing
}
    ${UserFragmentDoc}
${MlsListingFragmentDoc}`;
export const OffersForCurrentUserFragmentDoc = gql`
    fragment OffersForCurrentUser on OfferObject {
  id
  updatedAt
  status
  buyersAgent {
    id
    user {
      ...User
    }
  }
  party {
    id
  }
  primaryBuyer {
    id
    ...Consumer
  }
  secondaryBuyers {
    id
    ...Consumer
  }
  listing {
    id
    listingAgent {
      id
      user {
        id
      }
    }
    mlsListing {
      id
      photos
      address {
        ...MLSListingAddress
      }
    }
  }
}
    ${UserFragmentDoc}
${ConsumerFragmentDoc}
${MlsListingAddressFragmentDoc}`;
export const OpenHouseForCurrentUserFragmentDoc = gql`
    fragment OpenHouseForCurrentUser on OpenHouseObject {
  id
  date
  startTime
  endTime
  listing {
    id
    followerCount
    mlsListing {
      ...MLSListing
    }
  }
}
    ${MlsListingFragmentDoc}`;
export const OrganizationFragmentDoc = gql`
    fragment Organization on OrganizationObject {
  id
  name
}
    `;
export const PerAgentOfferAcceptRateFragmentDoc = gql`
    fragment PerAgentOfferAcceptRate on PerAgentOfferAcceptRateObject {
  agent_name
  accepted_offers
  offer_accept_rate
  submitted_offers
  total_offers
  average_bid_ask
  average_dd_days
  average_ddf_ratio
  average_offer_to_close_days
  average_purchase_price
  closed_volume
  pending_volume
  total_volume
}
    `;
export const PerAgentTransactionStatsFragmentDoc = gql`
    fragment PerAgentTransactionStats on PerAgentTransactionStatsObject {
  agent_name
  buy_side_homes_count
  sell_side_homes_count
  closed_homes_count
  pending_homes_count
  total_homes_count
  unknown_homes_count
  terminated_homes_count
  buy_side_volume
  closed_volume
  pending_volume
  sell_side_volume
  total_volume
}
    `;
export const PrioritizedListingFragmentDoc = gql`
    fragment PrioritizedListing on ListingObject {
  lastActivityDate
  ...Listing
  currentAndUpcomingOpenHouses {
    ...PublicOpenHouse
  }
}
    ${ListingFragmentDoc}
${PublicOpenHouseFragmentDoc}`;
export const PublicFeaturedStorefrontFragmentDoc = gql`
    fragment PublicFeaturedStorefront on PublicListingObject {
  id
  property {
    id
    slug
    state
    otherLegalDescription
    county
  }
  status
  offersCountDisplay
  listDate
  followerCount
  guestbookSignersCount
  listingAgent {
    id
    user {
      id
      firstName
      lastName
    }
  }
  mlsListing {
    ...MLSListing
  }
  isPremium
}
    ${MlsListingFragmentDoc}`;
export const ListingForSearchMlsListingFragmentDoc = gql`
    fragment ListingForSearchMlsListing on PublicListingObject {
  id
  status
  offersCountDisplay
  listDate
  property {
    id
    slug
  }
  isPremium
}
    `;
export const UpdateOfferWithPartyDocument = gql`
    mutation UpdateOfferWithParty($input: UpdateOfferWithPartyInput!) {
  updateOfferWithParty(input: $input) {
    offer {
      id
    }
  }
}
    `;
export type UpdateOfferWithPartyMutationFn = Apollo.MutationFunction<Types.UpdateOfferWithPartyMutation, Types.UpdateOfferWithPartyMutationVariables>;

/**
 * __useUpdateOfferWithPartyMutation__
 *
 * To run a mutation, you first call `useUpdateOfferWithPartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOfferWithPartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOfferWithPartyMutation, { data, loading, error }] = useUpdateOfferWithPartyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOfferWithPartyMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateOfferWithPartyMutation, Types.UpdateOfferWithPartyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateOfferWithPartyMutation, Types.UpdateOfferWithPartyMutationVariables>(UpdateOfferWithPartyDocument, options);
      }
export type UpdateOfferWithPartyMutationHookResult = ReturnType<typeof useUpdateOfferWithPartyMutation>;
export type UpdateOfferWithPartyMutationResult = Apollo.MutationResult<Types.UpdateOfferWithPartyMutation>;
export type UpdateOfferWithPartyMutationOptions = Apollo.BaseMutationOptions<Types.UpdateOfferWithPartyMutation, Types.UpdateOfferWithPartyMutationVariables>;
export const AcceptAccessControlInviteDocument = gql`
    mutation acceptAccessControlInvite($input: AcceptAccessControlInviteInput!) {
  acceptAccessControlInvite(input: $input) {
    user {
      ...User
    }
    targetId
    targetEntityName
  }
}
    ${UserFragmentDoc}`;
export type AcceptAccessControlInviteMutationFn = Apollo.MutationFunction<Types.AcceptAccessControlInviteMutation, Types.AcceptAccessControlInviteMutationVariables>;

/**
 * __useAcceptAccessControlInviteMutation__
 *
 * To run a mutation, you first call `useAcceptAccessControlInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptAccessControlInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptAccessControlInviteMutation, { data, loading, error }] = useAcceptAccessControlInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptAccessControlInviteMutation(baseOptions?: Apollo.MutationHookOptions<Types.AcceptAccessControlInviteMutation, Types.AcceptAccessControlInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AcceptAccessControlInviteMutation, Types.AcceptAccessControlInviteMutationVariables>(AcceptAccessControlInviteDocument, options);
      }
export type AcceptAccessControlInviteMutationHookResult = ReturnType<typeof useAcceptAccessControlInviteMutation>;
export type AcceptAccessControlInviteMutationResult = Apollo.MutationResult<Types.AcceptAccessControlInviteMutation>;
export type AcceptAccessControlInviteMutationOptions = Apollo.BaseMutationOptions<Types.AcceptAccessControlInviteMutation, Types.AcceptAccessControlInviteMutationVariables>;
export const AccessControlInviteDocument = gql`
    mutation accessControlInvite($input: AccessControlInviteInput!) {
  accessControlInvite(input: $input) {
    invitation {
      id
    }
  }
}
    `;
export type AccessControlInviteMutationFn = Apollo.MutationFunction<Types.AccessControlInviteMutation, Types.AccessControlInviteMutationVariables>;

/**
 * __useAccessControlInviteMutation__
 *
 * To run a mutation, you first call `useAccessControlInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccessControlInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accessControlInviteMutation, { data, loading, error }] = useAccessControlInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccessControlInviteMutation(baseOptions?: Apollo.MutationHookOptions<Types.AccessControlInviteMutation, Types.AccessControlInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AccessControlInviteMutation, Types.AccessControlInviteMutationVariables>(AccessControlInviteDocument, options);
      }
export type AccessControlInviteMutationHookResult = ReturnType<typeof useAccessControlInviteMutation>;
export type AccessControlInviteMutationResult = Apollo.MutationResult<Types.AccessControlInviteMutation>;
export type AccessControlInviteMutationOptions = Apollo.BaseMutationOptions<Types.AccessControlInviteMutation, Types.AccessControlInviteMutationVariables>;
export const CalculateOfferScoreDocument = gql`
    mutation calculateOfferScore($buyerOfferAnalysisId: String!) {
  calculateOfferScore(buyerOfferAnalysisId: $buyerOfferAnalysisId) {
    ...OfferScoreResult
  }
}
    ${OfferScoreResultFragmentDoc}`;
export type CalculateOfferScoreMutationFn = Apollo.MutationFunction<Types.CalculateOfferScoreMutation, Types.CalculateOfferScoreMutationVariables>;

/**
 * __useCalculateOfferScoreMutation__
 *
 * To run a mutation, you first call `useCalculateOfferScoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalculateOfferScoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calculateOfferScoreMutation, { data, loading, error }] = useCalculateOfferScoreMutation({
 *   variables: {
 *      buyerOfferAnalysisId: // value for 'buyerOfferAnalysisId'
 *   },
 * });
 */
export function useCalculateOfferScoreMutation(baseOptions?: Apollo.MutationHookOptions<Types.CalculateOfferScoreMutation, Types.CalculateOfferScoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CalculateOfferScoreMutation, Types.CalculateOfferScoreMutationVariables>(CalculateOfferScoreDocument, options);
      }
export type CalculateOfferScoreMutationHookResult = ReturnType<typeof useCalculateOfferScoreMutation>;
export type CalculateOfferScoreMutationResult = Apollo.MutationResult<Types.CalculateOfferScoreMutation>;
export type CalculateOfferScoreMutationOptions = Apollo.BaseMutationOptions<Types.CalculateOfferScoreMutation, Types.CalculateOfferScoreMutationVariables>;
export const CreateBuyerOfferAnalysisDocument = gql`
    mutation CreateBuyerOfferAnalysis($input: CreateBuyerOfferAnalysisInput!) {
  createBuyerOfferAnalysis(input: $input) {
    id
  }
}
    `;
export type CreateBuyerOfferAnalysisMutationFn = Apollo.MutationFunction<Types.CreateBuyerOfferAnalysisMutation, Types.CreateBuyerOfferAnalysisMutationVariables>;

/**
 * __useCreateBuyerOfferAnalysisMutation__
 *
 * To run a mutation, you first call `useCreateBuyerOfferAnalysisMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBuyerOfferAnalysisMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBuyerOfferAnalysisMutation, { data, loading, error }] = useCreateBuyerOfferAnalysisMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBuyerOfferAnalysisMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateBuyerOfferAnalysisMutation, Types.CreateBuyerOfferAnalysisMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateBuyerOfferAnalysisMutation, Types.CreateBuyerOfferAnalysisMutationVariables>(CreateBuyerOfferAnalysisDocument, options);
      }
export type CreateBuyerOfferAnalysisMutationHookResult = ReturnType<typeof useCreateBuyerOfferAnalysisMutation>;
export type CreateBuyerOfferAnalysisMutationResult = Apollo.MutationResult<Types.CreateBuyerOfferAnalysisMutation>;
export type CreateBuyerOfferAnalysisMutationOptions = Apollo.BaseMutationOptions<Types.CreateBuyerOfferAnalysisMutation, Types.CreateBuyerOfferAnalysisMutationVariables>;
export const CreateEmbeddedSignatureRequestDocument = gql`
    mutation createEmbeddedSignatureRequest($input: CreateEmbeddedSignatureRequestInput!) {
  createEmbeddedSignatureRequest(input: $input) {
    embeddedSignUrl
  }
}
    `;
export type CreateEmbeddedSignatureRequestMutationFn = Apollo.MutationFunction<Types.CreateEmbeddedSignatureRequestMutation, Types.CreateEmbeddedSignatureRequestMutationVariables>;

/**
 * __useCreateEmbeddedSignatureRequestMutation__
 *
 * To run a mutation, you first call `useCreateEmbeddedSignatureRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmbeddedSignatureRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmbeddedSignatureRequestMutation, { data, loading, error }] = useCreateEmbeddedSignatureRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEmbeddedSignatureRequestMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateEmbeddedSignatureRequestMutation, Types.CreateEmbeddedSignatureRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateEmbeddedSignatureRequestMutation, Types.CreateEmbeddedSignatureRequestMutationVariables>(CreateEmbeddedSignatureRequestDocument, options);
      }
export type CreateEmbeddedSignatureRequestMutationHookResult = ReturnType<typeof useCreateEmbeddedSignatureRequestMutation>;
export type CreateEmbeddedSignatureRequestMutationResult = Apollo.MutationResult<Types.CreateEmbeddedSignatureRequestMutation>;
export type CreateEmbeddedSignatureRequestMutationOptions = Apollo.BaseMutationOptions<Types.CreateEmbeddedSignatureRequestMutation, Types.CreateEmbeddedSignatureRequestMutationVariables>;
export const CreateEventForOfferDocument = gql`
    mutation CreateEventForOffer($input: CreateEventForOfferInput!) {
  createEventForOffer(input: $input) {
    ...AppEvent
  }
}
    ${AppEventFragmentDoc}`;
export type CreateEventForOfferMutationFn = Apollo.MutationFunction<Types.CreateEventForOfferMutation, Types.CreateEventForOfferMutationVariables>;

/**
 * __useCreateEventForOfferMutation__
 *
 * To run a mutation, you first call `useCreateEventForOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventForOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventForOfferMutation, { data, loading, error }] = useCreateEventForOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventForOfferMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateEventForOfferMutation, Types.CreateEventForOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateEventForOfferMutation, Types.CreateEventForOfferMutationVariables>(CreateEventForOfferDocument, options);
      }
export type CreateEventForOfferMutationHookResult = ReturnType<typeof useCreateEventForOfferMutation>;
export type CreateEventForOfferMutationResult = Apollo.MutationResult<Types.CreateEventForOfferMutation>;
export type CreateEventForOfferMutationOptions = Apollo.BaseMutationOptions<Types.CreateEventForOfferMutation, Types.CreateEventForOfferMutationVariables>;
export const CreateIndigoContractDocument = gql`
    mutation createIndigoContract($input: CreateIndigoContractInput!) {
  createIndigoContract(input: $input) {
    id
  }
}
    `;
export type CreateIndigoContractMutationFn = Apollo.MutationFunction<Types.CreateIndigoContractMutation, Types.CreateIndigoContractMutationVariables>;

/**
 * __useCreateIndigoContractMutation__
 *
 * To run a mutation, you first call `useCreateIndigoContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIndigoContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIndigoContractMutation, { data, loading, error }] = useCreateIndigoContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateIndigoContractMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateIndigoContractMutation, Types.CreateIndigoContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateIndigoContractMutation, Types.CreateIndigoContractMutationVariables>(CreateIndigoContractDocument, options);
      }
export type CreateIndigoContractMutationHookResult = ReturnType<typeof useCreateIndigoContractMutation>;
export type CreateIndigoContractMutationResult = Apollo.MutationResult<Types.CreateIndigoContractMutation>;
export type CreateIndigoContractMutationOptions = Apollo.BaseMutationOptions<Types.CreateIndigoContractMutation, Types.CreateIndigoContractMutationVariables>;
export const CreateOfferDocument = gql`
    mutation createOffer($input: CreateOfferInput!, $isAgentVerified: Boolean = false) {
  createOfferForBuyersAgent(input: $input) @include(if: $isAgentVerified) {
    offer {
      id
      contractId
    }
  }
  createOfferForUnauthenticatedBuyersAgent(input: $input) @skip(if: $isAgentVerified) {
    offer {
      id
      contractId
    }
  }
}
    `;
export type CreateOfferMutationFn = Apollo.MutationFunction<Types.CreateOfferMutation, Types.CreateOfferMutationVariables>;

/**
 * __useCreateOfferMutation__
 *
 * To run a mutation, you first call `useCreateOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOfferMutation, { data, loading, error }] = useCreateOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *      isAgentVerified: // value for 'isAgentVerified'
 *   },
 * });
 */
export function useCreateOfferMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateOfferMutation, Types.CreateOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateOfferMutation, Types.CreateOfferMutationVariables>(CreateOfferDocument, options);
      }
export type CreateOfferMutationHookResult = ReturnType<typeof useCreateOfferMutation>;
export type CreateOfferMutationResult = Apollo.MutationResult<Types.CreateOfferMutation>;
export type CreateOfferMutationOptions = Apollo.BaseMutationOptions<Types.CreateOfferMutation, Types.CreateOfferMutationVariables>;
export const CreateOrUpdateListingAnnouncementDocument = gql`
    mutation createOrUpdateListingAnnouncement($input: CreateOrUpdateListingAnnouncementInput!) {
  createOrUpdateListingAnnouncement(input: $input) {
    ...ListingAnnouncement
  }
}
    ${ListingAnnouncementFragmentDoc}`;
export type CreateOrUpdateListingAnnouncementMutationFn = Apollo.MutationFunction<Types.CreateOrUpdateListingAnnouncementMutation, Types.CreateOrUpdateListingAnnouncementMutationVariables>;

/**
 * __useCreateOrUpdateListingAnnouncementMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateListingAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateListingAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateListingAnnouncementMutation, { data, loading, error }] = useCreateOrUpdateListingAnnouncementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateListingAnnouncementMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateOrUpdateListingAnnouncementMutation, Types.CreateOrUpdateListingAnnouncementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateOrUpdateListingAnnouncementMutation, Types.CreateOrUpdateListingAnnouncementMutationVariables>(CreateOrUpdateListingAnnouncementDocument, options);
      }
export type CreateOrUpdateListingAnnouncementMutationHookResult = ReturnType<typeof useCreateOrUpdateListingAnnouncementMutation>;
export type CreateOrUpdateListingAnnouncementMutationResult = Apollo.MutationResult<Types.CreateOrUpdateListingAnnouncementMutation>;
export type CreateOrUpdateListingAnnouncementMutationOptions = Apollo.BaseMutationOptions<Types.CreateOrUpdateListingAnnouncementMutation, Types.CreateOrUpdateListingAnnouncementMutationVariables>;
export const CreateOrUpdateListingComparableAdjustmentDocument = gql`
    mutation createOrUpdateListingComparableAdjustment($input: CreateOrUpdateListingComparableAdjustmentInput!) {
  createOrUpdateListingComparableAdjustment(input: $input) {
    ...ListingComparable
  }
}
    ${ListingComparableFragmentDoc}`;
export type CreateOrUpdateListingComparableAdjustmentMutationFn = Apollo.MutationFunction<Types.CreateOrUpdateListingComparableAdjustmentMutation, Types.CreateOrUpdateListingComparableAdjustmentMutationVariables>;

/**
 * __useCreateOrUpdateListingComparableAdjustmentMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateListingComparableAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateListingComparableAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateListingComparableAdjustmentMutation, { data, loading, error }] = useCreateOrUpdateListingComparableAdjustmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateListingComparableAdjustmentMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateOrUpdateListingComparableAdjustmentMutation, Types.CreateOrUpdateListingComparableAdjustmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateOrUpdateListingComparableAdjustmentMutation, Types.CreateOrUpdateListingComparableAdjustmentMutationVariables>(CreateOrUpdateListingComparableAdjustmentDocument, options);
      }
export type CreateOrUpdateListingComparableAdjustmentMutationHookResult = ReturnType<typeof useCreateOrUpdateListingComparableAdjustmentMutation>;
export type CreateOrUpdateListingComparableAdjustmentMutationResult = Apollo.MutationResult<Types.CreateOrUpdateListingComparableAdjustmentMutation>;
export type CreateOrUpdateListingComparableAdjustmentMutationOptions = Apollo.BaseMutationOptions<Types.CreateOrUpdateListingComparableAdjustmentMutation, Types.CreateOrUpdateListingComparableAdjustmentMutationVariables>;
export const CreatePartyForBuyersAgentDocument = gql`
    mutation CreatePartyForBuyersAgent($input: CreatePartyForBuyersAgentInput!) {
  createPartyForBuyersAgent(input: $input) {
    id
  }
}
    `;
export type CreatePartyForBuyersAgentMutationFn = Apollo.MutationFunction<Types.CreatePartyForBuyersAgentMutation, Types.CreatePartyForBuyersAgentMutationVariables>;

/**
 * __useCreatePartyForBuyersAgentMutation__
 *
 * To run a mutation, you first call `useCreatePartyForBuyersAgentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartyForBuyersAgentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartyForBuyersAgentMutation, { data, loading, error }] = useCreatePartyForBuyersAgentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePartyForBuyersAgentMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreatePartyForBuyersAgentMutation, Types.CreatePartyForBuyersAgentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreatePartyForBuyersAgentMutation, Types.CreatePartyForBuyersAgentMutationVariables>(CreatePartyForBuyersAgentDocument, options);
      }
export type CreatePartyForBuyersAgentMutationHookResult = ReturnType<typeof useCreatePartyForBuyersAgentMutation>;
export type CreatePartyForBuyersAgentMutationResult = Apollo.MutationResult<Types.CreatePartyForBuyersAgentMutation>;
export type CreatePartyForBuyersAgentMutationOptions = Apollo.BaseMutationOptions<Types.CreatePartyForBuyersAgentMutation, Types.CreatePartyForBuyersAgentMutationVariables>;
export const CreateUserTourDocument = gql`
    mutation createUserTour($tourNames: [TourType!]!) {
  createUserTour(tourNames: $tourNames) {
    id
    tourName
  }
}
    `;
export type CreateUserTourMutationFn = Apollo.MutationFunction<Types.CreateUserTourMutation, Types.CreateUserTourMutationVariables>;

/**
 * __useCreateUserTourMutation__
 *
 * To run a mutation, you first call `useCreateUserTourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserTourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserTourMutation, { data, loading, error }] = useCreateUserTourMutation({
 *   variables: {
 *      tourNames: // value for 'tourNames'
 *   },
 * });
 */
export function useCreateUserTourMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateUserTourMutation, Types.CreateUserTourMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateUserTourMutation, Types.CreateUserTourMutationVariables>(CreateUserTourDocument, options);
      }
export type CreateUserTourMutationHookResult = ReturnType<typeof useCreateUserTourMutation>;
export type CreateUserTourMutationResult = Apollo.MutationResult<Types.CreateUserTourMutation>;
export type CreateUserTourMutationOptions = Apollo.BaseMutationOptions<Types.CreateUserTourMutation, Types.CreateUserTourMutationVariables>;
export const DeleteAccessControlDocument = gql`
    mutation deleteAccessControl($input: DeleteAccessControlInput!) {
  deleteAccessControl(input: $input) {
    deleted
  }
}
    `;
export type DeleteAccessControlMutationFn = Apollo.MutationFunction<Types.DeleteAccessControlMutation, Types.DeleteAccessControlMutationVariables>;

/**
 * __useDeleteAccessControlMutation__
 *
 * To run a mutation, you first call `useDeleteAccessControlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccessControlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccessControlMutation, { data, loading, error }] = useDeleteAccessControlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAccessControlMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteAccessControlMutation, Types.DeleteAccessControlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteAccessControlMutation, Types.DeleteAccessControlMutationVariables>(DeleteAccessControlDocument, options);
      }
export type DeleteAccessControlMutationHookResult = ReturnType<typeof useDeleteAccessControlMutation>;
export type DeleteAccessControlMutationResult = Apollo.MutationResult<Types.DeleteAccessControlMutation>;
export type DeleteAccessControlMutationOptions = Apollo.BaseMutationOptions<Types.DeleteAccessControlMutation, Types.DeleteAccessControlMutationVariables>;
export const DeleteListingDocumentsDocument = gql`
    mutation deleteListingDocuments($input: DeleteListingDocumentsInput!) {
  deleteListingDocuments(input: $input) {
    id
  }
}
    `;
export type DeleteListingDocumentsMutationFn = Apollo.MutationFunction<Types.DeleteListingDocumentsMutation, Types.DeleteListingDocumentsMutationVariables>;

/**
 * __useDeleteListingDocumentsMutation__
 *
 * To run a mutation, you first call `useDeleteListingDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteListingDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteListingDocumentsMutation, { data, loading, error }] = useDeleteListingDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteListingDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteListingDocumentsMutation, Types.DeleteListingDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteListingDocumentsMutation, Types.DeleteListingDocumentsMutationVariables>(DeleteListingDocumentsDocument, options);
      }
export type DeleteListingDocumentsMutationHookResult = ReturnType<typeof useDeleteListingDocumentsMutation>;
export type DeleteListingDocumentsMutationResult = Apollo.MutationResult<Types.DeleteListingDocumentsMutation>;
export type DeleteListingDocumentsMutationOptions = Apollo.BaseMutationOptions<Types.DeleteListingDocumentsMutation, Types.DeleteListingDocumentsMutationVariables>;
export const FollowListingDocument = gql`
    mutation followListing($input: FollowListingInput!) {
  followListing(input: $input) {
    isFollowingListing
  }
}
    `;
export type FollowListingMutationFn = Apollo.MutationFunction<Types.FollowListingMutation, Types.FollowListingMutationVariables>;

/**
 * __useFollowListingMutation__
 *
 * To run a mutation, you first call `useFollowListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followListingMutation, { data, loading, error }] = useFollowListingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFollowListingMutation(baseOptions?: Apollo.MutationHookOptions<Types.FollowListingMutation, Types.FollowListingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.FollowListingMutation, Types.FollowListingMutationVariables>(FollowListingDocument, options);
      }
export type FollowListingMutationHookResult = ReturnType<typeof useFollowListingMutation>;
export type FollowListingMutationResult = Apollo.MutationResult<Types.FollowListingMutation>;
export type FollowListingMutationOptions = Apollo.BaseMutationOptions<Types.FollowListingMutation, Types.FollowListingMutationVariables>;
export const GenerateListingPackagePdfDocument = gql`
    mutation GenerateListingPackagePdf($input: ListingInput!) {
  generateListingPackagePdf(input: $input) {
    fileName
    url
  }
}
    `;
export type GenerateListingPackagePdfMutationFn = Apollo.MutationFunction<Types.GenerateListingPackagePdfMutation, Types.GenerateListingPackagePdfMutationVariables>;

/**
 * __useGenerateListingPackagePdfMutation__
 *
 * To run a mutation, you first call `useGenerateListingPackagePdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateListingPackagePdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateListingPackagePdfMutation, { data, loading, error }] = useGenerateListingPackagePdfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateListingPackagePdfMutation(baseOptions?: Apollo.MutationHookOptions<Types.GenerateListingPackagePdfMutation, Types.GenerateListingPackagePdfMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.GenerateListingPackagePdfMutation, Types.GenerateListingPackagePdfMutationVariables>(GenerateListingPackagePdfDocument, options);
      }
export type GenerateListingPackagePdfMutationHookResult = ReturnType<typeof useGenerateListingPackagePdfMutation>;
export type GenerateListingPackagePdfMutationResult = Apollo.MutationResult<Types.GenerateListingPackagePdfMutation>;
export type GenerateListingPackagePdfMutationOptions = Apollo.BaseMutationOptions<Types.GenerateListingPackagePdfMutation, Types.GenerateListingPackagePdfMutationVariables>;
export const GetOrCreateAgentByPhoneNumberDocument = gql`
    mutation GetOrCreateAgentByPhoneNumber($input: GetOrCreateAgentByPhoneNumberInput!) {
  getOrCreateAgentByPhoneNumber(input: $input) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type GetOrCreateAgentByPhoneNumberMutationFn = Apollo.MutationFunction<Types.GetOrCreateAgentByPhoneNumberMutation, Types.GetOrCreateAgentByPhoneNumberMutationVariables>;

/**
 * __useGetOrCreateAgentByPhoneNumberMutation__
 *
 * To run a mutation, you first call `useGetOrCreateAgentByPhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetOrCreateAgentByPhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getOrCreateAgentByPhoneNumberMutation, { data, loading, error }] = useGetOrCreateAgentByPhoneNumberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOrCreateAgentByPhoneNumberMutation(baseOptions?: Apollo.MutationHookOptions<Types.GetOrCreateAgentByPhoneNumberMutation, Types.GetOrCreateAgentByPhoneNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.GetOrCreateAgentByPhoneNumberMutation, Types.GetOrCreateAgentByPhoneNumberMutationVariables>(GetOrCreateAgentByPhoneNumberDocument, options);
      }
export type GetOrCreateAgentByPhoneNumberMutationHookResult = ReturnType<typeof useGetOrCreateAgentByPhoneNumberMutation>;
export type GetOrCreateAgentByPhoneNumberMutationResult = Apollo.MutationResult<Types.GetOrCreateAgentByPhoneNumberMutation>;
export type GetOrCreateAgentByPhoneNumberMutationOptions = Apollo.BaseMutationOptions<Types.GetOrCreateAgentByPhoneNumberMutation, Types.GetOrCreateAgentByPhoneNumberMutationVariables>;
export const GetOrCreateConsumerByPhoneNumberDocument = gql`
    mutation GetOrCreateConsumerByPhoneNumber($input: CreateOrUpdateConsumerByPhoneNumberInput!) {
  getOrCreateConsumerByPhoneNumber(input: $input) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type GetOrCreateConsumerByPhoneNumberMutationFn = Apollo.MutationFunction<Types.GetOrCreateConsumerByPhoneNumberMutation, Types.GetOrCreateConsumerByPhoneNumberMutationVariables>;

/**
 * __useGetOrCreateConsumerByPhoneNumberMutation__
 *
 * To run a mutation, you first call `useGetOrCreateConsumerByPhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetOrCreateConsumerByPhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getOrCreateConsumerByPhoneNumberMutation, { data, loading, error }] = useGetOrCreateConsumerByPhoneNumberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOrCreateConsumerByPhoneNumberMutation(baseOptions?: Apollo.MutationHookOptions<Types.GetOrCreateConsumerByPhoneNumberMutation, Types.GetOrCreateConsumerByPhoneNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.GetOrCreateConsumerByPhoneNumberMutation, Types.GetOrCreateConsumerByPhoneNumberMutationVariables>(GetOrCreateConsumerByPhoneNumberDocument, options);
      }
export type GetOrCreateConsumerByPhoneNumberMutationHookResult = ReturnType<typeof useGetOrCreateConsumerByPhoneNumberMutation>;
export type GetOrCreateConsumerByPhoneNumberMutationResult = Apollo.MutationResult<Types.GetOrCreateConsumerByPhoneNumberMutation>;
export type GetOrCreateConsumerByPhoneNumberMutationOptions = Apollo.BaseMutationOptions<Types.GetOrCreateConsumerByPhoneNumberMutation, Types.GetOrCreateConsumerByPhoneNumberMutationVariables>;
export const UpdateUserNotificationSettingsDocument = gql`
    mutation UpdateUserNotificationSettings($input: NotificationSettingsInput!) {
  updateUserNotificationSettings(input: $input) {
    email
    sms
  }
}
    `;
export type UpdateUserNotificationSettingsMutationFn = Apollo.MutationFunction<Types.UpdateUserNotificationSettingsMutation, Types.UpdateUserNotificationSettingsMutationVariables>;

/**
 * __useUpdateUserNotificationSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUserNotificationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserNotificationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserNotificationSettingsMutation, { data, loading, error }] = useUpdateUserNotificationSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserNotificationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserNotificationSettingsMutation, Types.UpdateUserNotificationSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateUserNotificationSettingsMutation, Types.UpdateUserNotificationSettingsMutationVariables>(UpdateUserNotificationSettingsDocument, options);
      }
export type UpdateUserNotificationSettingsMutationHookResult = ReturnType<typeof useUpdateUserNotificationSettingsMutation>;
export type UpdateUserNotificationSettingsMutationResult = Apollo.MutationResult<Types.UpdateUserNotificationSettingsMutation>;
export type UpdateUserNotificationSettingsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserNotificationSettingsMutation, Types.UpdateUserNotificationSettingsMutationVariables>;
export const CreateOpenHouseDocument = gql`
    mutation CreateOpenHouse($input: CreateOpenHouseInput!) {
  createOpenHouse(input: $input) {
    openHouse {
      ...OpenHouseCard
    }
  }
}
    ${OpenHouseCardFragmentDoc}`;
export type CreateOpenHouseMutationFn = Apollo.MutationFunction<Types.CreateOpenHouseMutation, Types.CreateOpenHouseMutationVariables>;

/**
 * __useCreateOpenHouseMutation__
 *
 * To run a mutation, you first call `useCreateOpenHouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOpenHouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOpenHouseMutation, { data, loading, error }] = useCreateOpenHouseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOpenHouseMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateOpenHouseMutation, Types.CreateOpenHouseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateOpenHouseMutation, Types.CreateOpenHouseMutationVariables>(CreateOpenHouseDocument, options);
      }
export type CreateOpenHouseMutationHookResult = ReturnType<typeof useCreateOpenHouseMutation>;
export type CreateOpenHouseMutationResult = Apollo.MutationResult<Types.CreateOpenHouseMutation>;
export type CreateOpenHouseMutationOptions = Apollo.BaseMutationOptions<Types.CreateOpenHouseMutation, Types.CreateOpenHouseMutationVariables>;
export const UpdateOpenHouseDocument = gql`
    mutation UpdateOpenHouse($input: UpdateOpenHouseInput!) {
  updateOpenHouse(input: $input) {
    openHouse {
      ...OpenHouseCard
    }
  }
}
    ${OpenHouseCardFragmentDoc}`;
export type UpdateOpenHouseMutationFn = Apollo.MutationFunction<Types.UpdateOpenHouseMutation, Types.UpdateOpenHouseMutationVariables>;

/**
 * __useUpdateOpenHouseMutation__
 *
 * To run a mutation, you first call `useUpdateOpenHouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOpenHouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOpenHouseMutation, { data, loading, error }] = useUpdateOpenHouseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOpenHouseMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateOpenHouseMutation, Types.UpdateOpenHouseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateOpenHouseMutation, Types.UpdateOpenHouseMutationVariables>(UpdateOpenHouseDocument, options);
      }
export type UpdateOpenHouseMutationHookResult = ReturnType<typeof useUpdateOpenHouseMutation>;
export type UpdateOpenHouseMutationResult = Apollo.MutationResult<Types.UpdateOpenHouseMutation>;
export type UpdateOpenHouseMutationOptions = Apollo.BaseMutationOptions<Types.UpdateOpenHouseMutation, Types.UpdateOpenHouseMutationVariables>;
export const DeleteOpenHouseDocument = gql`
    mutation DeleteOpenHouse($input: DeleteOpenHouseInput!) {
  deleteOpenHouse(input: $input) {
    success
  }
}
    `;
export type DeleteOpenHouseMutationFn = Apollo.MutationFunction<Types.DeleteOpenHouseMutation, Types.DeleteOpenHouseMutationVariables>;

/**
 * __useDeleteOpenHouseMutation__
 *
 * To run a mutation, you first call `useDeleteOpenHouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOpenHouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOpenHouseMutation, { data, loading, error }] = useDeleteOpenHouseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOpenHouseMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteOpenHouseMutation, Types.DeleteOpenHouseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteOpenHouseMutation, Types.DeleteOpenHouseMutationVariables>(DeleteOpenHouseDocument, options);
      }
export type DeleteOpenHouseMutationHookResult = ReturnType<typeof useDeleteOpenHouseMutation>;
export type DeleteOpenHouseMutationResult = Apollo.MutationResult<Types.DeleteOpenHouseMutation>;
export type DeleteOpenHouseMutationOptions = Apollo.BaseMutationOptions<Types.DeleteOpenHouseMutation, Types.DeleteOpenHouseMutationVariables>;
export const ResendBoaShareLinkDocument = gql`
    mutation ResendBOAShareLink($token: String!) {
  resendBOAShareLink(token: $token)
}
    `;
export type ResendBoaShareLinkMutationFn = Apollo.MutationFunction<Types.ResendBoaShareLinkMutation, Types.ResendBoaShareLinkMutationVariables>;

/**
 * __useResendBoaShareLinkMutation__
 *
 * To run a mutation, you first call `useResendBoaShareLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendBoaShareLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendBoaShareLinkMutation, { data, loading, error }] = useResendBoaShareLinkMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResendBoaShareLinkMutation(baseOptions?: Apollo.MutationHookOptions<Types.ResendBoaShareLinkMutation, Types.ResendBoaShareLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ResendBoaShareLinkMutation, Types.ResendBoaShareLinkMutationVariables>(ResendBoaShareLinkDocument, options);
      }
export type ResendBoaShareLinkMutationHookResult = ReturnType<typeof useResendBoaShareLinkMutation>;
export type ResendBoaShareLinkMutationResult = Apollo.MutationResult<Types.ResendBoaShareLinkMutation>;
export type ResendBoaShareLinkMutationOptions = Apollo.BaseMutationOptions<Types.ResendBoaShareLinkMutation, Types.ResendBoaShareLinkMutationVariables>;
export const RevokeBoaShareLinkDocument = gql`
    mutation RevokeBOAShareLink($input: RevokeBOAShareLinkInput!) {
  revokeBOAShareLink(input: $input) {
    ...ShareLink
  }
}
    ${ShareLinkFragmentDoc}`;
export type RevokeBoaShareLinkMutationFn = Apollo.MutationFunction<Types.RevokeBoaShareLinkMutation, Types.RevokeBoaShareLinkMutationVariables>;

/**
 * __useRevokeBoaShareLinkMutation__
 *
 * To run a mutation, you first call `useRevokeBoaShareLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeBoaShareLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeBoaShareLinkMutation, { data, loading, error }] = useRevokeBoaShareLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRevokeBoaShareLinkMutation(baseOptions?: Apollo.MutationHookOptions<Types.RevokeBoaShareLinkMutation, Types.RevokeBoaShareLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RevokeBoaShareLinkMutation, Types.RevokeBoaShareLinkMutationVariables>(RevokeBoaShareLinkDocument, options);
      }
export type RevokeBoaShareLinkMutationHookResult = ReturnType<typeof useRevokeBoaShareLinkMutation>;
export type RevokeBoaShareLinkMutationResult = Apollo.MutationResult<Types.RevokeBoaShareLinkMutation>;
export type RevokeBoaShareLinkMutationOptions = Apollo.BaseMutationOptions<Types.RevokeBoaShareLinkMutation, Types.RevokeBoaShareLinkMutationVariables>;
export const RevokeInvitationDocument = gql`
    mutation revokeInvitation($id: ID!) {
  revokeInvitation(id: $id)
}
    `;
export type RevokeInvitationMutationFn = Apollo.MutationFunction<Types.RevokeInvitationMutation, Types.RevokeInvitationMutationVariables>;

/**
 * __useRevokeInvitationMutation__
 *
 * To run a mutation, you first call `useRevokeInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeInvitationMutation, { data, loading, error }] = useRevokeInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRevokeInvitationMutation(baseOptions?: Apollo.MutationHookOptions<Types.RevokeInvitationMutation, Types.RevokeInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RevokeInvitationMutation, Types.RevokeInvitationMutationVariables>(RevokeInvitationDocument, options);
      }
export type RevokeInvitationMutationHookResult = ReturnType<typeof useRevokeInvitationMutation>;
export type RevokeInvitationMutationResult = Apollo.MutationResult<Types.RevokeInvitationMutation>;
export type RevokeInvitationMutationOptions = Apollo.BaseMutationOptions<Types.RevokeInvitationMutation, Types.RevokeInvitationMutationVariables>;
export const SendBoaShareLinkDocument = gql`
    mutation sendBOAShareLink($input: SendBOAShareLinkInput!) {
  sendBOAShareLink(input: $input) {
    ...ShareLink
  }
}
    ${ShareLinkFragmentDoc}`;
export type SendBoaShareLinkMutationFn = Apollo.MutationFunction<Types.SendBoaShareLinkMutation, Types.SendBoaShareLinkMutationVariables>;

/**
 * __useSendBoaShareLinkMutation__
 *
 * To run a mutation, you first call `useSendBoaShareLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendBoaShareLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendBoaShareLinkMutation, { data, loading, error }] = useSendBoaShareLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendBoaShareLinkMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendBoaShareLinkMutation, Types.SendBoaShareLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendBoaShareLinkMutation, Types.SendBoaShareLinkMutationVariables>(SendBoaShareLinkDocument, options);
      }
export type SendBoaShareLinkMutationHookResult = ReturnType<typeof useSendBoaShareLinkMutation>;
export type SendBoaShareLinkMutationResult = Apollo.MutationResult<Types.SendBoaShareLinkMutation>;
export type SendBoaShareLinkMutationOptions = Apollo.BaseMutationOptions<Types.SendBoaShareLinkMutation, Types.SendBoaShareLinkMutationVariables>;
export const SendOtpFromInvitationLinkDocument = gql`
    mutation sendOTPFromInvitationLink($input: SendOTPFromInvitationLinkInput!) {
  sendOTPFromInvitationLink(input: $input) {
    agentName
    invitationId
    methodId
    name
    phoneNumberLast4
    listingId
  }
}
    `;
export type SendOtpFromInvitationLinkMutationFn = Apollo.MutationFunction<Types.SendOtpFromInvitationLinkMutation, Types.SendOtpFromInvitationLinkMutationVariables>;

/**
 * __useSendOtpFromInvitationLinkMutation__
 *
 * To run a mutation, you first call `useSendOtpFromInvitationLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendOtpFromInvitationLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendOtpFromInvitationLinkMutation, { data, loading, error }] = useSendOtpFromInvitationLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendOtpFromInvitationLinkMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendOtpFromInvitationLinkMutation, Types.SendOtpFromInvitationLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendOtpFromInvitationLinkMutation, Types.SendOtpFromInvitationLinkMutationVariables>(SendOtpFromInvitationLinkDocument, options);
      }
export type SendOtpFromInvitationLinkMutationHookResult = ReturnType<typeof useSendOtpFromInvitationLinkMutation>;
export type SendOtpFromInvitationLinkMutationResult = Apollo.MutationResult<Types.SendOtpFromInvitationLinkMutation>;
export type SendOtpFromInvitationLinkMutationOptions = Apollo.BaseMutationOptions<Types.SendOtpFromInvitationLinkMutation, Types.SendOtpFromInvitationLinkMutationVariables>;
export const SendSellerInvitationDocument = gql`
    mutation sendSellerInvitation($input: SendSellerInvitationInput!) {
  sendSellerInvitation(input: $input) {
    invitation {
      ...Invitation
    }
  }
}
    ${InvitationFragmentDoc}`;
export type SendSellerInvitationMutationFn = Apollo.MutationFunction<Types.SendSellerInvitationMutation, Types.SendSellerInvitationMutationVariables>;

/**
 * __useSendSellerInvitationMutation__
 *
 * To run a mutation, you first call `useSendSellerInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSellerInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSellerInvitationMutation, { data, loading, error }] = useSendSellerInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendSellerInvitationMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendSellerInvitationMutation, Types.SendSellerInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendSellerInvitationMutation, Types.SendSellerInvitationMutationVariables>(SendSellerInvitationDocument, options);
      }
export type SendSellerInvitationMutationHookResult = ReturnType<typeof useSendSellerInvitationMutation>;
export type SendSellerInvitationMutationResult = Apollo.MutationResult<Types.SendSellerInvitationMutation>;
export type SendSellerInvitationMutationOptions = Apollo.BaseMutationOptions<Types.SendSellerInvitationMutation, Types.SendSellerInvitationMutationVariables>;
export const SendSignatureRequestToBuyersForOfferDocument = gql`
    mutation sendSignatureRequestToBuyersForOffer($input: SendSignatureRequestToBuyersForOfferInput!) {
  sendSignatureRequestToBuyersForOffer(input: $input) {
    offer {
      id
      status
    }
  }
}
    `;
export type SendSignatureRequestToBuyersForOfferMutationFn = Apollo.MutationFunction<Types.SendSignatureRequestToBuyersForOfferMutation, Types.SendSignatureRequestToBuyersForOfferMutationVariables>;

/**
 * __useSendSignatureRequestToBuyersForOfferMutation__
 *
 * To run a mutation, you first call `useSendSignatureRequestToBuyersForOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSignatureRequestToBuyersForOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSignatureRequestToBuyersForOfferMutation, { data, loading, error }] = useSendSignatureRequestToBuyersForOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendSignatureRequestToBuyersForOfferMutation(baseOptions?: Apollo.MutationHookOptions<Types.SendSignatureRequestToBuyersForOfferMutation, Types.SendSignatureRequestToBuyersForOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SendSignatureRequestToBuyersForOfferMutation, Types.SendSignatureRequestToBuyersForOfferMutationVariables>(SendSignatureRequestToBuyersForOfferDocument, options);
      }
export type SendSignatureRequestToBuyersForOfferMutationHookResult = ReturnType<typeof useSendSignatureRequestToBuyersForOfferMutation>;
export type SendSignatureRequestToBuyersForOfferMutationResult = Apollo.MutationResult<Types.SendSignatureRequestToBuyersForOfferMutation>;
export type SendSignatureRequestToBuyersForOfferMutationOptions = Apollo.BaseMutationOptions<Types.SendSignatureRequestToBuyersForOfferMutation, Types.SendSignatureRequestToBuyersForOfferMutationVariables>;
export const ShareStorefrontWithBuyerDocument = gql`
    mutation ShareStorefrontWithBuyer($input: ShareStorefrontWithBuyerInput!) {
  shareStorefrontWithBuyer(input: $input)
}
    `;
export type ShareStorefrontWithBuyerMutationFn = Apollo.MutationFunction<Types.ShareStorefrontWithBuyerMutation, Types.ShareStorefrontWithBuyerMutationVariables>;

/**
 * __useShareStorefrontWithBuyerMutation__
 *
 * To run a mutation, you first call `useShareStorefrontWithBuyerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareStorefrontWithBuyerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareStorefrontWithBuyerMutation, { data, loading, error }] = useShareStorefrontWithBuyerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShareStorefrontWithBuyerMutation(baseOptions?: Apollo.MutationHookOptions<Types.ShareStorefrontWithBuyerMutation, Types.ShareStorefrontWithBuyerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ShareStorefrontWithBuyerMutation, Types.ShareStorefrontWithBuyerMutationVariables>(ShareStorefrontWithBuyerDocument, options);
      }
export type ShareStorefrontWithBuyerMutationHookResult = ReturnType<typeof useShareStorefrontWithBuyerMutation>;
export type ShareStorefrontWithBuyerMutationResult = Apollo.MutationResult<Types.ShareStorefrontWithBuyerMutation>;
export type ShareStorefrontWithBuyerMutationOptions = Apollo.BaseMutationOptions<Types.ShareStorefrontWithBuyerMutation, Types.ShareStorefrontWithBuyerMutationVariables>;
export const SignListingGuestbookDocument = gql`
    mutation signListingGuestbook($listingId: String!, $followListingReason: StorefrontSubscriptionReason!) {
  signListingGuestbook(input: {id: $listingId})
  followListing(input: {id: $listingId, reason: $followListingReason}) {
    isFollowingListing
  }
}
    `;
export type SignListingGuestbookMutationFn = Apollo.MutationFunction<Types.SignListingGuestbookMutation, Types.SignListingGuestbookMutationVariables>;

/**
 * __useSignListingGuestbookMutation__
 *
 * To run a mutation, you first call `useSignListingGuestbookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignListingGuestbookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signListingGuestbookMutation, { data, loading, error }] = useSignListingGuestbookMutation({
 *   variables: {
 *      listingId: // value for 'listingId'
 *      followListingReason: // value for 'followListingReason'
 *   },
 * });
 */
export function useSignListingGuestbookMutation(baseOptions?: Apollo.MutationHookOptions<Types.SignListingGuestbookMutation, Types.SignListingGuestbookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SignListingGuestbookMutation, Types.SignListingGuestbookMutationVariables>(SignListingGuestbookDocument, options);
      }
export type SignListingGuestbookMutationHookResult = ReturnType<typeof useSignListingGuestbookMutation>;
export type SignListingGuestbookMutationResult = Apollo.MutationResult<Types.SignListingGuestbookMutation>;
export type SignListingGuestbookMutationOptions = Apollo.BaseMutationOptions<Types.SignListingGuestbookMutation, Types.SignListingGuestbookMutationVariables>;
export const SignOpenHouseGuestbookDocument = gql`
    mutation SignOpenHouseGuestbook($input: OpenHouseGuestbookInput!) {
  signOpenHouseGuestbook(input: $input)
}
    `;
export type SignOpenHouseGuestbookMutationFn = Apollo.MutationFunction<Types.SignOpenHouseGuestbookMutation, Types.SignOpenHouseGuestbookMutationVariables>;

/**
 * __useSignOpenHouseGuestbookMutation__
 *
 * To run a mutation, you first call `useSignOpenHouseGuestbookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignOpenHouseGuestbookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signOpenHouseGuestbookMutation, { data, loading, error }] = useSignOpenHouseGuestbookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignOpenHouseGuestbookMutation(baseOptions?: Apollo.MutationHookOptions<Types.SignOpenHouseGuestbookMutation, Types.SignOpenHouseGuestbookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SignOpenHouseGuestbookMutation, Types.SignOpenHouseGuestbookMutationVariables>(SignOpenHouseGuestbookDocument, options);
      }
export type SignOpenHouseGuestbookMutationHookResult = ReturnType<typeof useSignOpenHouseGuestbookMutation>;
export type SignOpenHouseGuestbookMutationResult = Apollo.MutationResult<Types.SignOpenHouseGuestbookMutation>;
export type SignOpenHouseGuestbookMutationOptions = Apollo.BaseMutationOptions<Types.SignOpenHouseGuestbookMutation, Types.SignOpenHouseGuestbookMutationVariables>;
export const SignUpConsumerDocument = gql`
    mutation SignUpConsumer($input: SignUpInput!) {
  signUpConsumer(input: $input) {
    user {
      ...User
    }
  }
}
    ${UserFragmentDoc}`;
export type SignUpConsumerMutationFn = Apollo.MutationFunction<Types.SignUpConsumerMutation, Types.SignUpConsumerMutationVariables>;

/**
 * __useSignUpConsumerMutation__
 *
 * To run a mutation, you first call `useSignUpConsumerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpConsumerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpConsumerMutation, { data, loading, error }] = useSignUpConsumerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpConsumerMutation(baseOptions?: Apollo.MutationHookOptions<Types.SignUpConsumerMutation, Types.SignUpConsumerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SignUpConsumerMutation, Types.SignUpConsumerMutationVariables>(SignUpConsumerDocument, options);
      }
export type SignUpConsumerMutationHookResult = ReturnType<typeof useSignUpConsumerMutation>;
export type SignUpConsumerMutationResult = Apollo.MutationResult<Types.SignUpConsumerMutation>;
export type SignUpConsumerMutationOptions = Apollo.BaseMutationOptions<Types.SignUpConsumerMutation, Types.SignUpConsumerMutationVariables>;
export const SignUpAgentDocument = gql`
    mutation signUpAgent($input: SignUpAgentInput!) {
  signUpAgent(input: $input) {
    user {
      ...User
      licenses {
        id
      }
    }
  }
}
    ${UserFragmentDoc}`;
export type SignUpAgentMutationFn = Apollo.MutationFunction<Types.SignUpAgentMutation, Types.SignUpAgentMutationVariables>;

/**
 * __useSignUpAgentMutation__
 *
 * To run a mutation, you first call `useSignUpAgentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpAgentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpAgentMutation, { data, loading, error }] = useSignUpAgentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpAgentMutation(baseOptions?: Apollo.MutationHookOptions<Types.SignUpAgentMutation, Types.SignUpAgentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SignUpAgentMutation, Types.SignUpAgentMutationVariables>(SignUpAgentDocument, options);
      }
export type SignUpAgentMutationHookResult = ReturnType<typeof useSignUpAgentMutation>;
export type SignUpAgentMutationResult = Apollo.MutationResult<Types.SignUpAgentMutation>;
export type SignUpAgentMutationOptions = Apollo.BaseMutationOptions<Types.SignUpAgentMutation, Types.SignUpAgentMutationVariables>;
export const SignUpAgentV2Document = gql`
    mutation signUpAgentV2($input: SignUpAgentV2Input!) {
  signUpAgentV2(input: $input) {
    user {
      ...User
      licenses {
        id
      }
    }
  }
}
    ${UserFragmentDoc}`;
export type SignUpAgentV2MutationFn = Apollo.MutationFunction<Types.SignUpAgentV2Mutation, Types.SignUpAgentV2MutationVariables>;

/**
 * __useSignUpAgentV2Mutation__
 *
 * To run a mutation, you first call `useSignUpAgentV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpAgentV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpAgentV2Mutation, { data, loading, error }] = useSignUpAgentV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpAgentV2Mutation(baseOptions?: Apollo.MutationHookOptions<Types.SignUpAgentV2Mutation, Types.SignUpAgentV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SignUpAgentV2Mutation, Types.SignUpAgentV2MutationVariables>(SignUpAgentV2Document, options);
      }
export type SignUpAgentV2MutationHookResult = ReturnType<typeof useSignUpAgentV2Mutation>;
export type SignUpAgentV2MutationResult = Apollo.MutationResult<Types.SignUpAgentV2Mutation>;
export type SignUpAgentV2MutationOptions = Apollo.BaseMutationOptions<Types.SignUpAgentV2Mutation, Types.SignUpAgentV2MutationVariables>;
export const SubmitOfferForBuyersAgentDocument = gql`
    mutation submitOfferForBuyersAgent($input: SubmitOfferInput!) {
  submitOfferForBuyersAgent(input: $input) {
    offer {
      id
      noteToListingAgent
      submittedAt
    }
  }
}
    `;
export type SubmitOfferForBuyersAgentMutationFn = Apollo.MutationFunction<Types.SubmitOfferForBuyersAgentMutation, Types.SubmitOfferForBuyersAgentMutationVariables>;

/**
 * __useSubmitOfferForBuyersAgentMutation__
 *
 * To run a mutation, you first call `useSubmitOfferForBuyersAgentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitOfferForBuyersAgentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitOfferForBuyersAgentMutation, { data, loading, error }] = useSubmitOfferForBuyersAgentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitOfferForBuyersAgentMutation(baseOptions?: Apollo.MutationHookOptions<Types.SubmitOfferForBuyersAgentMutation, Types.SubmitOfferForBuyersAgentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SubmitOfferForBuyersAgentMutation, Types.SubmitOfferForBuyersAgentMutationVariables>(SubmitOfferForBuyersAgentDocument, options);
      }
export type SubmitOfferForBuyersAgentMutationHookResult = ReturnType<typeof useSubmitOfferForBuyersAgentMutation>;
export type SubmitOfferForBuyersAgentMutationResult = Apollo.MutationResult<Types.SubmitOfferForBuyersAgentMutation>;
export type SubmitOfferForBuyersAgentMutationOptions = Apollo.BaseMutationOptions<Types.SubmitOfferForBuyersAgentMutation, Types.SubmitOfferForBuyersAgentMutationVariables>;
export const SubmitOfferToNonIndigoListingAgentDocument = gql`
    mutation SubmitOfferToNonIndigoListingAgent($input: SubmitOfferToNonIndigoListingAgentInput!) {
  submitOfferToNonIndigoListingAgent(input: $input) {
    offer {
      id
    }
  }
}
    `;
export type SubmitOfferToNonIndigoListingAgentMutationFn = Apollo.MutationFunction<Types.SubmitOfferToNonIndigoListingAgentMutation, Types.SubmitOfferToNonIndigoListingAgentMutationVariables>;

/**
 * __useSubmitOfferToNonIndigoListingAgentMutation__
 *
 * To run a mutation, you first call `useSubmitOfferToNonIndigoListingAgentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitOfferToNonIndigoListingAgentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitOfferToNonIndigoListingAgentMutation, { data, loading, error }] = useSubmitOfferToNonIndigoListingAgentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitOfferToNonIndigoListingAgentMutation(baseOptions?: Apollo.MutationHookOptions<Types.SubmitOfferToNonIndigoListingAgentMutation, Types.SubmitOfferToNonIndigoListingAgentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SubmitOfferToNonIndigoListingAgentMutation, Types.SubmitOfferToNonIndigoListingAgentMutationVariables>(SubmitOfferToNonIndigoListingAgentDocument, options);
      }
export type SubmitOfferToNonIndigoListingAgentMutationHookResult = ReturnType<typeof useSubmitOfferToNonIndigoListingAgentMutation>;
export type SubmitOfferToNonIndigoListingAgentMutationResult = Apollo.MutationResult<Types.SubmitOfferToNonIndigoListingAgentMutation>;
export type SubmitOfferToNonIndigoListingAgentMutationOptions = Apollo.BaseMutationOptions<Types.SubmitOfferToNonIndigoListingAgentMutation, Types.SubmitOfferToNonIndigoListingAgentMutationVariables>;
export const TrackListingDocumentActionDocument = gql`
    mutation trackListingDocumentAction($input: TrackListingDocumentActionInput!) {
  trackListingDocumentAction(input: $input) {
    success
  }
}
    `;
export type TrackListingDocumentActionMutationFn = Apollo.MutationFunction<Types.TrackListingDocumentActionMutation, Types.TrackListingDocumentActionMutationVariables>;

/**
 * __useTrackListingDocumentActionMutation__
 *
 * To run a mutation, you first call `useTrackListingDocumentActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackListingDocumentActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackListingDocumentActionMutation, { data, loading, error }] = useTrackListingDocumentActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrackListingDocumentActionMutation(baseOptions?: Apollo.MutationHookOptions<Types.TrackListingDocumentActionMutation, Types.TrackListingDocumentActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.TrackListingDocumentActionMutation, Types.TrackListingDocumentActionMutationVariables>(TrackListingDocumentActionDocument, options);
      }
export type TrackListingDocumentActionMutationHookResult = ReturnType<typeof useTrackListingDocumentActionMutation>;
export type TrackListingDocumentActionMutationResult = Apollo.MutationResult<Types.TrackListingDocumentActionMutation>;
export type TrackListingDocumentActionMutationOptions = Apollo.BaseMutationOptions<Types.TrackListingDocumentActionMutation, Types.TrackListingDocumentActionMutationVariables>;
export const UnfollowListingDocument = gql`
    mutation unfollowListing($input: ListingInput!) {
  unfollowListing(input: $input) {
    isFollowingListing
  }
}
    `;
export type UnfollowListingMutationFn = Apollo.MutationFunction<Types.UnfollowListingMutation, Types.UnfollowListingMutationVariables>;

/**
 * __useUnfollowListingMutation__
 *
 * To run a mutation, you first call `useUnfollowListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfollowListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfollowListingMutation, { data, loading, error }] = useUnfollowListingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnfollowListingMutation(baseOptions?: Apollo.MutationHookOptions<Types.UnfollowListingMutation, Types.UnfollowListingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UnfollowListingMutation, Types.UnfollowListingMutationVariables>(UnfollowListingDocument, options);
      }
export type UnfollowListingMutationHookResult = ReturnType<typeof useUnfollowListingMutation>;
export type UnfollowListingMutationResult = Apollo.MutationResult<Types.UnfollowListingMutation>;
export type UnfollowListingMutationOptions = Apollo.BaseMutationOptions<Types.UnfollowListingMutation, Types.UnfollowListingMutationVariables>;
export const UpdateBuyerOfferAnalysisDocument = gql`
    mutation UpdateBuyerOfferAnalysis($input: UpdateBuyerOfferAnalysisInput!) {
  updateBuyerOfferAnalysis(input: $input) {
    id
  }
}
    `;
export type UpdateBuyerOfferAnalysisMutationFn = Apollo.MutationFunction<Types.UpdateBuyerOfferAnalysisMutation, Types.UpdateBuyerOfferAnalysisMutationVariables>;

/**
 * __useUpdateBuyerOfferAnalysisMutation__
 *
 * To run a mutation, you first call `useUpdateBuyerOfferAnalysisMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBuyerOfferAnalysisMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBuyerOfferAnalysisMutation, { data, loading, error }] = useUpdateBuyerOfferAnalysisMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBuyerOfferAnalysisMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateBuyerOfferAnalysisMutation, Types.UpdateBuyerOfferAnalysisMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateBuyerOfferAnalysisMutation, Types.UpdateBuyerOfferAnalysisMutationVariables>(UpdateBuyerOfferAnalysisDocument, options);
      }
export type UpdateBuyerOfferAnalysisMutationHookResult = ReturnType<typeof useUpdateBuyerOfferAnalysisMutation>;
export type UpdateBuyerOfferAnalysisMutationResult = Apollo.MutationResult<Types.UpdateBuyerOfferAnalysisMutation>;
export type UpdateBuyerOfferAnalysisMutationOptions = Apollo.BaseMutationOptions<Types.UpdateBuyerOfferAnalysisMutation, Types.UpdateBuyerOfferAnalysisMutationVariables>;
export const UpdateIndigoContractDocument = gql`
    mutation updateIndigoContract($input: UpdateIndigoContractInput!, $includeFieldValues: Boolean = true, $includeContractDocuments: Boolean = false) {
  updateIndigoContract(input: $input) {
    ...IndigoContractsForCurrentUser
  }
}
    ${IndigoContractsForCurrentUserFragmentDoc}`;
export type UpdateIndigoContractMutationFn = Apollo.MutationFunction<Types.UpdateIndigoContractMutation, Types.UpdateIndigoContractMutationVariables>;

/**
 * __useUpdateIndigoContractMutation__
 *
 * To run a mutation, you first call `useUpdateIndigoContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIndigoContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIndigoContractMutation, { data, loading, error }] = useUpdateIndigoContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *      includeFieldValues: // value for 'includeFieldValues'
 *      includeContractDocuments: // value for 'includeContractDocuments'
 *   },
 * });
 */
export function useUpdateIndigoContractMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateIndigoContractMutation, Types.UpdateIndigoContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateIndigoContractMutation, Types.UpdateIndigoContractMutationVariables>(UpdateIndigoContractDocument, options);
      }
export type UpdateIndigoContractMutationHookResult = ReturnType<typeof useUpdateIndigoContractMutation>;
export type UpdateIndigoContractMutationResult = Apollo.MutationResult<Types.UpdateIndigoContractMutation>;
export type UpdateIndigoContractMutationOptions = Apollo.BaseMutationOptions<Types.UpdateIndigoContractMutation, Types.UpdateIndigoContractMutationVariables>;
export const UpdateListingComparableSetDocument = gql`
    mutation updateListingComparableSet($input: UpdateListingComparableSetInput!) {
  updateListingComparableSet(input: $input) {
    ...ListingComparableSet
  }
}
    ${ListingComparableSetFragmentDoc}`;
export type UpdateListingComparableSetMutationFn = Apollo.MutationFunction<Types.UpdateListingComparableSetMutation, Types.UpdateListingComparableSetMutationVariables>;

/**
 * __useUpdateListingComparableSetMutation__
 *
 * To run a mutation, you first call `useUpdateListingComparableSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateListingComparableSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateListingComparableSetMutation, { data, loading, error }] = useUpdateListingComparableSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateListingComparableSetMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateListingComparableSetMutation, Types.UpdateListingComparableSetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateListingComparableSetMutation, Types.UpdateListingComparableSetMutationVariables>(UpdateListingComparableSetDocument, options);
      }
export type UpdateListingComparableSetMutationHookResult = ReturnType<typeof useUpdateListingComparableSetMutation>;
export type UpdateListingComparableSetMutationResult = Apollo.MutationResult<Types.UpdateListingComparableSetMutation>;
export type UpdateListingComparableSetMutationOptions = Apollo.BaseMutationOptions<Types.UpdateListingComparableSetMutation, Types.UpdateListingComparableSetMutationVariables>;
export const UpdateListingComparableSetForBuyerOfferAnalysisDocument = gql`
    mutation UpdateListingComparableSetForBuyerOfferAnalysis($input: UpdateListingComparableSetForBuyerOfferAnalysisInput!) {
  updateListingComparableSetForBuyerOfferAnalysis(input: $input) {
    ...ListingComparableSet
  }
}
    ${ListingComparableSetFragmentDoc}`;
export type UpdateListingComparableSetForBuyerOfferAnalysisMutationFn = Apollo.MutationFunction<Types.UpdateListingComparableSetForBuyerOfferAnalysisMutation, Types.UpdateListingComparableSetForBuyerOfferAnalysisMutationVariables>;

/**
 * __useUpdateListingComparableSetForBuyerOfferAnalysisMutation__
 *
 * To run a mutation, you first call `useUpdateListingComparableSetForBuyerOfferAnalysisMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateListingComparableSetForBuyerOfferAnalysisMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateListingComparableSetForBuyerOfferAnalysisMutation, { data, loading, error }] = useUpdateListingComparableSetForBuyerOfferAnalysisMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateListingComparableSetForBuyerOfferAnalysisMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateListingComparableSetForBuyerOfferAnalysisMutation, Types.UpdateListingComparableSetForBuyerOfferAnalysisMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateListingComparableSetForBuyerOfferAnalysisMutation, Types.UpdateListingComparableSetForBuyerOfferAnalysisMutationVariables>(UpdateListingComparableSetForBuyerOfferAnalysisDocument, options);
      }
export type UpdateListingComparableSetForBuyerOfferAnalysisMutationHookResult = ReturnType<typeof useUpdateListingComparableSetForBuyerOfferAnalysisMutation>;
export type UpdateListingComparableSetForBuyerOfferAnalysisMutationResult = Apollo.MutationResult<Types.UpdateListingComparableSetForBuyerOfferAnalysisMutation>;
export type UpdateListingComparableSetForBuyerOfferAnalysisMutationOptions = Apollo.BaseMutationOptions<Types.UpdateListingComparableSetForBuyerOfferAnalysisMutation, Types.UpdateListingComparableSetForBuyerOfferAnalysisMutationVariables>;
export const UpdateListingPreferenceDocument = gql`
    mutation updateListingPreference($input: UpdateListingPreferenceValueInput!, $preferenceValues: Boolean = true) {
  updateListingPreference(input: $input) {
    ...Listing
  }
}
    ${ListingFragmentDoc}`;
export type UpdateListingPreferenceMutationFn = Apollo.MutationFunction<Types.UpdateListingPreferenceMutation, Types.UpdateListingPreferenceMutationVariables>;

/**
 * __useUpdateListingPreferenceMutation__
 *
 * To run a mutation, you first call `useUpdateListingPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateListingPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateListingPreferenceMutation, { data, loading, error }] = useUpdateListingPreferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *      preferenceValues: // value for 'preferenceValues'
 *   },
 * });
 */
export function useUpdateListingPreferenceMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateListingPreferenceMutation, Types.UpdateListingPreferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateListingPreferenceMutation, Types.UpdateListingPreferenceMutationVariables>(UpdateListingPreferenceDocument, options);
      }
export type UpdateListingPreferenceMutationHookResult = ReturnType<typeof useUpdateListingPreferenceMutation>;
export type UpdateListingPreferenceMutationResult = Apollo.MutationResult<Types.UpdateListingPreferenceMutation>;
export type UpdateListingPreferenceMutationOptions = Apollo.BaseMutationOptions<Types.UpdateListingPreferenceMutation, Types.UpdateListingPreferenceMutationVariables>;
export const UpdateOfferForBuyersAgentDocument = gql`
    mutation updateOfferForBuyersAgent($input: UpdateOfferForBuyersAgentInput!) {
  updateOfferForBuyersAgent(input: $input) {
    offer {
      id
      contract {
        id
        fieldValues
        validations {
          ...ContractValidation
        }
      }
    }
  }
}
    ${ContractValidationFragmentDoc}`;
export type UpdateOfferForBuyersAgentMutationFn = Apollo.MutationFunction<Types.UpdateOfferForBuyersAgentMutation, Types.UpdateOfferForBuyersAgentMutationVariables>;

/**
 * __useUpdateOfferForBuyersAgentMutation__
 *
 * To run a mutation, you first call `useUpdateOfferForBuyersAgentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOfferForBuyersAgentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOfferForBuyersAgentMutation, { data, loading, error }] = useUpdateOfferForBuyersAgentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOfferForBuyersAgentMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateOfferForBuyersAgentMutation, Types.UpdateOfferForBuyersAgentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateOfferForBuyersAgentMutation, Types.UpdateOfferForBuyersAgentMutationVariables>(UpdateOfferForBuyersAgentDocument, options);
      }
export type UpdateOfferForBuyersAgentMutationHookResult = ReturnType<typeof useUpdateOfferForBuyersAgentMutation>;
export type UpdateOfferForBuyersAgentMutationResult = Apollo.MutationResult<Types.UpdateOfferForBuyersAgentMutation>;
export type UpdateOfferForBuyersAgentMutationOptions = Apollo.BaseMutationOptions<Types.UpdateOfferForBuyersAgentMutation, Types.UpdateOfferForBuyersAgentMutationVariables>;
export const UpdatePartyForBuyersAgentDocument = gql`
    mutation UpdatePartyForBuyersAgent($input: UpdatePartyForBuyersAgentInput!) {
  updatePartyForBuyersAgent(input: $input) {
    ...Party
  }
}
    ${PartyFragmentDoc}`;
export type UpdatePartyForBuyersAgentMutationFn = Apollo.MutationFunction<Types.UpdatePartyForBuyersAgentMutation, Types.UpdatePartyForBuyersAgentMutationVariables>;

/**
 * __useUpdatePartyForBuyersAgentMutation__
 *
 * To run a mutation, you first call `useUpdatePartyForBuyersAgentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartyForBuyersAgentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartyForBuyersAgentMutation, { data, loading, error }] = useUpdatePartyForBuyersAgentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePartyForBuyersAgentMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePartyForBuyersAgentMutation, Types.UpdatePartyForBuyersAgentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePartyForBuyersAgentMutation, Types.UpdatePartyForBuyersAgentMutationVariables>(UpdatePartyForBuyersAgentDocument, options);
      }
export type UpdatePartyForBuyersAgentMutationHookResult = ReturnType<typeof useUpdatePartyForBuyersAgentMutation>;
export type UpdatePartyForBuyersAgentMutationResult = Apollo.MutationResult<Types.UpdatePartyForBuyersAgentMutation>;
export type UpdatePartyForBuyersAgentMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePartyForBuyersAgentMutation, Types.UpdatePartyForBuyersAgentMutationVariables>;
export const UpdateStorefrontSetupDocument = gql`
    mutation UpdateStorefrontSetup($input: UpdateStorefrontSetupInput!) {
  updateStorefrontSetup(input: $input) {
    id
  }
}
    `;
export type UpdateStorefrontSetupMutationFn = Apollo.MutationFunction<Types.UpdateStorefrontSetupMutation, Types.UpdateStorefrontSetupMutationVariables>;

/**
 * __useUpdateStorefrontSetupMutation__
 *
 * To run a mutation, you first call `useUpdateStorefrontSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStorefrontSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStorefrontSetupMutation, { data, loading, error }] = useUpdateStorefrontSetupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStorefrontSetupMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateStorefrontSetupMutation, Types.UpdateStorefrontSetupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateStorefrontSetupMutation, Types.UpdateStorefrontSetupMutationVariables>(UpdateStorefrontSetupDocument, options);
      }
export type UpdateStorefrontSetupMutationHookResult = ReturnType<typeof useUpdateStorefrontSetupMutation>;
export type UpdateStorefrontSetupMutationResult = Apollo.MutationResult<Types.UpdateStorefrontSetupMutation>;
export type UpdateStorefrontSetupMutationOptions = Apollo.BaseMutationOptions<Types.UpdateStorefrontSetupMutation, Types.UpdateStorefrontSetupMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    user {
      ...User
    }
  }
}
    ${UserFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<Types.UpdateUserMutation, Types.UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserMutation, Types.UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateUserMutation, Types.UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<Types.UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserMutation, Types.UpdateUserMutationVariables>;
export const UpdateUserSettingsDocument = gql`
    mutation updateUserSettings($input: UpdateUserSettingsInput!) {
  updateUserSettings(input: $input) {
    user {
      ...User
    }
  }
}
    ${UserFragmentDoc}`;
export type UpdateUserSettingsMutationFn = Apollo.MutationFunction<Types.UpdateUserSettingsMutation, Types.UpdateUserSettingsMutationVariables>;

/**
 * __useUpdateUserSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSettingsMutation, { data, loading, error }] = useUpdateUserSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserSettingsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateUserSettingsMutation, Types.UpdateUserSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateUserSettingsMutation, Types.UpdateUserSettingsMutationVariables>(UpdateUserSettingsDocument, options);
      }
export type UpdateUserSettingsMutationHookResult = ReturnType<typeof useUpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationResult = Apollo.MutationResult<Types.UpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateUserSettingsMutation, Types.UpdateUserSettingsMutationVariables>;
export const UploadAndExtractAgentNegotiationDocumentsDocument = gql`
    mutation uploadAndExtractAgentNegotiationDocuments($input: UploadListingDocumentInput!) {
  uploadAndExtractAgentNegotiationDocuments(input: $input) {
    listingDocumentUploads {
      id
      file {
        fileName
        url
      }
    }
    agentCompensationFields {
      flatFee
      isListingFirmPaying
      isSellerPaying
      percentOfGrossSalesPrice
    }
  }
}
    `;
export type UploadAndExtractAgentNegotiationDocumentsMutationFn = Apollo.MutationFunction<Types.UploadAndExtractAgentNegotiationDocumentsMutation, Types.UploadAndExtractAgentNegotiationDocumentsMutationVariables>;

/**
 * __useUploadAndExtractAgentNegotiationDocumentsMutation__
 *
 * To run a mutation, you first call `useUploadAndExtractAgentNegotiationDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadAndExtractAgentNegotiationDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadAndExtractAgentNegotiationDocumentsMutation, { data, loading, error }] = useUploadAndExtractAgentNegotiationDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadAndExtractAgentNegotiationDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UploadAndExtractAgentNegotiationDocumentsMutation, Types.UploadAndExtractAgentNegotiationDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UploadAndExtractAgentNegotiationDocumentsMutation, Types.UploadAndExtractAgentNegotiationDocumentsMutationVariables>(UploadAndExtractAgentNegotiationDocumentsDocument, options);
      }
export type UploadAndExtractAgentNegotiationDocumentsMutationHookResult = ReturnType<typeof useUploadAndExtractAgentNegotiationDocumentsMutation>;
export type UploadAndExtractAgentNegotiationDocumentsMutationResult = Apollo.MutationResult<Types.UploadAndExtractAgentNegotiationDocumentsMutation>;
export type UploadAndExtractAgentNegotiationDocumentsMutationOptions = Apollo.BaseMutationOptions<Types.UploadAndExtractAgentNegotiationDocumentsMutation, Types.UploadAndExtractAgentNegotiationDocumentsMutationVariables>;
export const UploadListingDocumentDocument = gql`
    mutation uploadListingDocument($input: UploadListingDocumentInput!) {
  uploadListingDocument(input: $input) {
    id
    file {
      fileName
      url
    }
  }
}
    `;
export type UploadListingDocumentMutationFn = Apollo.MutationFunction<Types.UploadListingDocumentMutation, Types.UploadListingDocumentMutationVariables>;

/**
 * __useUploadListingDocumentMutation__
 *
 * To run a mutation, you first call `useUploadListingDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadListingDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadListingDocumentMutation, { data, loading, error }] = useUploadListingDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadListingDocumentMutation(baseOptions?: Apollo.MutationHookOptions<Types.UploadListingDocumentMutation, Types.UploadListingDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UploadListingDocumentMutation, Types.UploadListingDocumentMutationVariables>(UploadListingDocumentDocument, options);
      }
export type UploadListingDocumentMutationHookResult = ReturnType<typeof useUploadListingDocumentMutation>;
export type UploadListingDocumentMutationResult = Apollo.MutationResult<Types.UploadListingDocumentMutation>;
export type UploadListingDocumentMutationOptions = Apollo.BaseMutationOptions<Types.UploadListingDocumentMutation, Types.UploadListingDocumentMutationVariables>;
export const UploadOfferAsListingAgentDocument = gql`
    mutation uploadOfferAsListingAgent($input: UploadOfferInput!) {
  uploadOfferAsListingAgent(input: $input) {
    offer {
      id
      contractId
      file {
        fileName
        url
      }
    }
  }
}
    `;
export type UploadOfferAsListingAgentMutationFn = Apollo.MutationFunction<Types.UploadOfferAsListingAgentMutation, Types.UploadOfferAsListingAgentMutationVariables>;

/**
 * __useUploadOfferAsListingAgentMutation__
 *
 * To run a mutation, you first call `useUploadOfferAsListingAgentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadOfferAsListingAgentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadOfferAsListingAgentMutation, { data, loading, error }] = useUploadOfferAsListingAgentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadOfferAsListingAgentMutation(baseOptions?: Apollo.MutationHookOptions<Types.UploadOfferAsListingAgentMutation, Types.UploadOfferAsListingAgentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UploadOfferAsListingAgentMutation, Types.UploadOfferAsListingAgentMutationVariables>(UploadOfferAsListingAgentDocument, options);
      }
export type UploadOfferAsListingAgentMutationHookResult = ReturnType<typeof useUploadOfferAsListingAgentMutation>;
export type UploadOfferAsListingAgentMutationResult = Apollo.MutationResult<Types.UploadOfferAsListingAgentMutation>;
export type UploadOfferAsListingAgentMutationOptions = Apollo.BaseMutationOptions<Types.UploadOfferAsListingAgentMutation, Types.UploadOfferAsListingAgentMutationVariables>;
export const UploadOfferForBuyersAgentDocument = gql`
    mutation uploadOfferForBuyersAgent($input: UploadOfferInput!, $isAgentVerified: Boolean = false) {
  uploadOfferForBuyersAgent(input: $input) @include(if: $isAgentVerified) {
    offer {
      id
      contractId
      file {
        fileName
        url
      }
    }
  }
  uploadOfferForUnauthenticatedBuyersAgent(input: $input) @skip(if: $isAgentVerified) {
    offer {
      id
      contractId
      file {
        fileName
        url
      }
    }
  }
}
    `;
export type UploadOfferForBuyersAgentMutationFn = Apollo.MutationFunction<Types.UploadOfferForBuyersAgentMutation, Types.UploadOfferForBuyersAgentMutationVariables>;

/**
 * __useUploadOfferForBuyersAgentMutation__
 *
 * To run a mutation, you first call `useUploadOfferForBuyersAgentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadOfferForBuyersAgentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadOfferForBuyersAgentMutation, { data, loading, error }] = useUploadOfferForBuyersAgentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      isAgentVerified: // value for 'isAgentVerified'
 *   },
 * });
 */
export function useUploadOfferForBuyersAgentMutation(baseOptions?: Apollo.MutationHookOptions<Types.UploadOfferForBuyersAgentMutation, Types.UploadOfferForBuyersAgentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UploadOfferForBuyersAgentMutation, Types.UploadOfferForBuyersAgentMutationVariables>(UploadOfferForBuyersAgentDocument, options);
      }
export type UploadOfferForBuyersAgentMutationHookResult = ReturnType<typeof useUploadOfferForBuyersAgentMutation>;
export type UploadOfferForBuyersAgentMutationResult = Apollo.MutationResult<Types.UploadOfferForBuyersAgentMutation>;
export type UploadOfferForBuyersAgentMutationOptions = Apollo.BaseMutationOptions<Types.UploadOfferForBuyersAgentMutation, Types.UploadOfferForBuyersAgentMutationVariables>;
export const VerifyShareLinkDocument = gql`
    mutation VerifyShareLink($token: String!) {
  verifyShareLink(input: {token: $token}) {
    isValid
    isExpired
    accessType
  }
}
    `;
export type VerifyShareLinkMutationFn = Apollo.MutationFunction<Types.VerifyShareLinkMutation, Types.VerifyShareLinkMutationVariables>;

/**
 * __useVerifyShareLinkMutation__
 *
 * To run a mutation, you first call `useVerifyShareLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyShareLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyShareLinkMutation, { data, loading, error }] = useVerifyShareLinkMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyShareLinkMutation(baseOptions?: Apollo.MutationHookOptions<Types.VerifyShareLinkMutation, Types.VerifyShareLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.VerifyShareLinkMutation, Types.VerifyShareLinkMutationVariables>(VerifyShareLinkDocument, options);
      }
export type VerifyShareLinkMutationHookResult = ReturnType<typeof useVerifyShareLinkMutation>;
export type VerifyShareLinkMutationResult = Apollo.MutationResult<Types.VerifyShareLinkMutation>;
export type VerifyShareLinkMutationOptions = Apollo.BaseMutationOptions<Types.VerifyShareLinkMutation, Types.VerifyShareLinkMutationVariables>;
export const AccessControlsListDocument = gql`
    query accessControlsList($cursor: String, $orderBy: String = "id", $perPage: Int = 100, $filters: AccessControlsListFilters!) {
  accessControlsList(
    cursor: $cursor
    orderBy: $orderBy
    perPage: $perPage
    filters: $filters
  ) {
    results {
      ...AccessControl
    }
  }
}
    ${AccessControlFragmentDoc}`;

/**
 * __useAccessControlsListQuery__
 *
 * To run a query within a React component, call `useAccessControlsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessControlsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessControlsListQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      orderBy: // value for 'orderBy'
 *      perPage: // value for 'perPage'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAccessControlsListQuery(baseOptions: Apollo.QueryHookOptions<Types.AccessControlsListQuery, Types.AccessControlsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AccessControlsListQuery, Types.AccessControlsListQueryVariables>(AccessControlsListDocument, options);
      }
export function useAccessControlsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AccessControlsListQuery, Types.AccessControlsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AccessControlsListQuery, Types.AccessControlsListQueryVariables>(AccessControlsListDocument, options);
        }
export type AccessControlsListQueryHookResult = ReturnType<typeof useAccessControlsListQuery>;
export type AccessControlsListLazyQueryHookResult = ReturnType<typeof useAccessControlsListLazyQuery>;
export type AccessControlsListQueryResult = Apollo.QueryResult<Types.AccessControlsListQuery, Types.AccessControlsListQueryVariables>;
export const AllListingActivitiesForCurrentUserDocument = gql`
    query allListingActivitiesForCurrentUser($includeListing: Boolean! = true, $limit: Float = 10, $offset: Float = 0) {
  allListingActivitiesForCurrentUser(limit: $limit, offset: $offset) {
    results {
      ...Activity
    }
    hasNext
  }
}
    ${ActivityFragmentDoc}`;

/**
 * __useAllListingActivitiesForCurrentUserQuery__
 *
 * To run a query within a React component, call `useAllListingActivitiesForCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllListingActivitiesForCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllListingActivitiesForCurrentUserQuery({
 *   variables: {
 *      includeListing: // value for 'includeListing'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllListingActivitiesForCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllListingActivitiesForCurrentUserQuery, Types.AllListingActivitiesForCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllListingActivitiesForCurrentUserQuery, Types.AllListingActivitiesForCurrentUserQueryVariables>(AllListingActivitiesForCurrentUserDocument, options);
      }
export function useAllListingActivitiesForCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllListingActivitiesForCurrentUserQuery, Types.AllListingActivitiesForCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllListingActivitiesForCurrentUserQuery, Types.AllListingActivitiesForCurrentUserQueryVariables>(AllListingActivitiesForCurrentUserDocument, options);
        }
export type AllListingActivitiesForCurrentUserQueryHookResult = ReturnType<typeof useAllListingActivitiesForCurrentUserQuery>;
export type AllListingActivitiesForCurrentUserLazyQueryHookResult = ReturnType<typeof useAllListingActivitiesForCurrentUserLazyQuery>;
export type AllListingActivitiesForCurrentUserQueryResult = Apollo.QueryResult<Types.AllListingActivitiesForCurrentUserQuery, Types.AllListingActivitiesForCurrentUserQueryVariables>;
export const AllMlSesDocument = gql`
    query allMLSes {
  allMLSes {
    mls {
      ...MLSObjectForAllMLSes
    }
  }
}
    ${MlsObjectForAllMlSesFragmentDoc}`;

/**
 * __useAllMlSesQuery__
 *
 * To run a query within a React component, call `useAllMlSesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMlSesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMlSesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllMlSesQuery(baseOptions?: Apollo.QueryHookOptions<Types.AllMlSesQuery, Types.AllMlSesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AllMlSesQuery, Types.AllMlSesQueryVariables>(AllMlSesDocument, options);
      }
export function useAllMlSesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AllMlSesQuery, Types.AllMlSesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AllMlSesQuery, Types.AllMlSesQueryVariables>(AllMlSesDocument, options);
        }
export type AllMlSesQueryHookResult = ReturnType<typeof useAllMlSesQuery>;
export type AllMlSesLazyQueryHookResult = ReturnType<typeof useAllMlSesLazyQuery>;
export type AllMlSesQueryResult = Apollo.QueryResult<Types.AllMlSesQuery, Types.AllMlSesQueryVariables>;
export const OfferAutofillVariablesSchemaDocument = gql`
    query offerAutofillVariablesSchema($usState: USState!) {
  offerAutofillVariablesSchema(usState: $usState)
}
    `;

/**
 * __useOfferAutofillVariablesSchemaQuery__
 *
 * To run a query within a React component, call `useOfferAutofillVariablesSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferAutofillVariablesSchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferAutofillVariablesSchemaQuery({
 *   variables: {
 *      usState: // value for 'usState'
 *   },
 * });
 */
export function useOfferAutofillVariablesSchemaQuery(baseOptions: Apollo.QueryHookOptions<Types.OfferAutofillVariablesSchemaQuery, Types.OfferAutofillVariablesSchemaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OfferAutofillVariablesSchemaQuery, Types.OfferAutofillVariablesSchemaQueryVariables>(OfferAutofillVariablesSchemaDocument, options);
      }
export function useOfferAutofillVariablesSchemaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OfferAutofillVariablesSchemaQuery, Types.OfferAutofillVariablesSchemaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OfferAutofillVariablesSchemaQuery, Types.OfferAutofillVariablesSchemaQueryVariables>(OfferAutofillVariablesSchemaDocument, options);
        }
export type OfferAutofillVariablesSchemaQueryHookResult = ReturnType<typeof useOfferAutofillVariablesSchemaQuery>;
export type OfferAutofillVariablesSchemaLazyQueryHookResult = ReturnType<typeof useOfferAutofillVariablesSchemaLazyQuery>;
export type OfferAutofillVariablesSchemaQueryResult = Apollo.QueryResult<Types.OfferAutofillVariablesSchemaQuery, Types.OfferAutofillVariablesSchemaQueryVariables>;
export const OfferAutofillVariableValuesDocument = gql`
    query offerAutofillVariableValues($offerId: ID!) {
  offerAutofillVariableValues(offerIds: [$offerId]) {
    preference
    property {
      ...Property
    }
    seller {
      ...AutofillConsumer
    }
    buyer {
      ...AutofillConsumer
    }
    coBuyer {
      ...AutofillConsumer
    }
    coSeller {
      ...AutofillConsumer
    }
    latestSale {
      closedDate
      deedBook
      deedPage
      salePrice
    }
    listingAgent {
      ...User
    }
    mlsListing {
      ...MLSListing
    }
    buyersAgent {
      ...User
    }
  }
}
    ${PropertyFragmentDoc}
${AutofillConsumerFragmentDoc}
${UserFragmentDoc}
${MlsListingFragmentDoc}`;

/**
 * __useOfferAutofillVariableValuesQuery__
 *
 * To run a query within a React component, call `useOfferAutofillVariableValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferAutofillVariableValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferAutofillVariableValuesQuery({
 *   variables: {
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useOfferAutofillVariableValuesQuery(baseOptions: Apollo.QueryHookOptions<Types.OfferAutofillVariableValuesQuery, Types.OfferAutofillVariableValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OfferAutofillVariableValuesQuery, Types.OfferAutofillVariableValuesQueryVariables>(OfferAutofillVariableValuesDocument, options);
      }
export function useOfferAutofillVariableValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OfferAutofillVariableValuesQuery, Types.OfferAutofillVariableValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OfferAutofillVariableValuesQuery, Types.OfferAutofillVariableValuesQueryVariables>(OfferAutofillVariableValuesDocument, options);
        }
export type OfferAutofillVariableValuesQueryHookResult = ReturnType<typeof useOfferAutofillVariableValuesQuery>;
export type OfferAutofillVariableValuesLazyQueryHookResult = ReturnType<typeof useOfferAutofillVariableValuesLazyQuery>;
export type OfferAutofillVariableValuesQueryResult = Apollo.QueryResult<Types.OfferAutofillVariableValuesQuery, Types.OfferAutofillVariableValuesQueryVariables>;
export const ListingAutofillVariableValuesDocument = gql`
    query listingAutofillVariableValues($listingIds: [ID!]!) {
  listingAutofillVariableValues(listingIds: $listingIds) {
    preference
    mlsListing {
      ...MLSListing
    }
  }
}
    ${MlsListingFragmentDoc}`;

/**
 * __useListingAutofillVariableValuesQuery__
 *
 * To run a query within a React component, call `useListingAutofillVariableValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingAutofillVariableValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingAutofillVariableValuesQuery({
 *   variables: {
 *      listingIds: // value for 'listingIds'
 *   },
 * });
 */
export function useListingAutofillVariableValuesQuery(baseOptions: Apollo.QueryHookOptions<Types.ListingAutofillVariableValuesQuery, Types.ListingAutofillVariableValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListingAutofillVariableValuesQuery, Types.ListingAutofillVariableValuesQueryVariables>(ListingAutofillVariableValuesDocument, options);
      }
export function useListingAutofillVariableValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListingAutofillVariableValuesQuery, Types.ListingAutofillVariableValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListingAutofillVariableValuesQuery, Types.ListingAutofillVariableValuesQueryVariables>(ListingAutofillVariableValuesDocument, options);
        }
export type ListingAutofillVariableValuesQueryHookResult = ReturnType<typeof useListingAutofillVariableValuesQuery>;
export type ListingAutofillVariableValuesLazyQueryHookResult = ReturnType<typeof useListingAutofillVariableValuesLazyQuery>;
export type ListingAutofillVariableValuesQueryResult = Apollo.QueryResult<Types.ListingAutofillVariableValuesQuery, Types.ListingAutofillVariableValuesQueryVariables>;
export const OfferAutofillValuesFromContractDocument = gql`
    query offerAutofillValuesFromContract($offerId: ID!) {
  offerAutofillVariableValuesFromContract(offerId: $offerId) {
    extractedBuyerInfos {
      primary {
        fullName
        entity {
          name
          title
        }
      }
      secondary {
        fullName
        entity {
          name
          title
        }
      }
    }
  }
}
    `;

/**
 * __useOfferAutofillValuesFromContractQuery__
 *
 * To run a query within a React component, call `useOfferAutofillValuesFromContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferAutofillValuesFromContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferAutofillValuesFromContractQuery({
 *   variables: {
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useOfferAutofillValuesFromContractQuery(baseOptions: Apollo.QueryHookOptions<Types.OfferAutofillValuesFromContractQuery, Types.OfferAutofillValuesFromContractQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OfferAutofillValuesFromContractQuery, Types.OfferAutofillValuesFromContractQueryVariables>(OfferAutofillValuesFromContractDocument, options);
      }
export function useOfferAutofillValuesFromContractLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OfferAutofillValuesFromContractQuery, Types.OfferAutofillValuesFromContractQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OfferAutofillValuesFromContractQuery, Types.OfferAutofillValuesFromContractQueryVariables>(OfferAutofillValuesFromContractDocument, options);
        }
export type OfferAutofillValuesFromContractQueryHookResult = ReturnType<typeof useOfferAutofillValuesFromContractQuery>;
export type OfferAutofillValuesFromContractLazyQueryHookResult = ReturnType<typeof useOfferAutofillValuesFromContractLazyQuery>;
export type OfferAutofillValuesFromContractQueryResult = Apollo.QueryResult<Types.OfferAutofillValuesFromContractQuery, Types.OfferAutofillValuesFromContractQueryVariables>;
export const BuyerClientsForCurrentAgentUserDocument = gql`
    query BuyerClientsForCurrentAgentUser {
  buyerClientsForCurrentAgentUser {
    ...Party
  }
}
    ${PartyFragmentDoc}`;

/**
 * __useBuyerClientsForCurrentAgentUserQuery__
 *
 * To run a query within a React component, call `useBuyerClientsForCurrentAgentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyerClientsForCurrentAgentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyerClientsForCurrentAgentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useBuyerClientsForCurrentAgentUserQuery(baseOptions?: Apollo.QueryHookOptions<Types.BuyerClientsForCurrentAgentUserQuery, Types.BuyerClientsForCurrentAgentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BuyerClientsForCurrentAgentUserQuery, Types.BuyerClientsForCurrentAgentUserQueryVariables>(BuyerClientsForCurrentAgentUserDocument, options);
      }
export function useBuyerClientsForCurrentAgentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BuyerClientsForCurrentAgentUserQuery, Types.BuyerClientsForCurrentAgentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BuyerClientsForCurrentAgentUserQuery, Types.BuyerClientsForCurrentAgentUserQueryVariables>(BuyerClientsForCurrentAgentUserDocument, options);
        }
export type BuyerClientsForCurrentAgentUserQueryHookResult = ReturnType<typeof useBuyerClientsForCurrentAgentUserQuery>;
export type BuyerClientsForCurrentAgentUserLazyQueryHookResult = ReturnType<typeof useBuyerClientsForCurrentAgentUserLazyQuery>;
export type BuyerClientsForCurrentAgentUserQueryResult = Apollo.QueryResult<Types.BuyerClientsForCurrentAgentUserQuery, Types.BuyerClientsForCurrentAgentUserQueryVariables>;
export const CheckPermissionDocument = gql`
    query checkPermission($input: CheckPermissionInput!) {
  checkPermission(input: $input) {
    isAuthorized
    message
  }
}
    `;

/**
 * __useCheckPermissionQuery__
 *
 * To run a query within a React component, call `useCheckPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckPermissionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckPermissionQuery(baseOptions: Apollo.QueryHookOptions<Types.CheckPermissionQuery, Types.CheckPermissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CheckPermissionQuery, Types.CheckPermissionQueryVariables>(CheckPermissionDocument, options);
      }
export function useCheckPermissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CheckPermissionQuery, Types.CheckPermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CheckPermissionQuery, Types.CheckPermissionQueryVariables>(CheckPermissionDocument, options);
        }
export type CheckPermissionQueryHookResult = ReturnType<typeof useCheckPermissionQuery>;
export type CheckPermissionLazyQueryHookResult = ReturnType<typeof useCheckPermissionLazyQuery>;
export type CheckPermissionQueryResult = Apollo.QueryResult<Types.CheckPermissionQuery, Types.CheckPermissionQueryVariables>;
export const CurrentUserDocument = gql`
    query currentUser {
  currentUser {
    ...CurrentUser
  }
}
    ${CurrentUserFragmentDoc}`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<Types.CurrentUserQuery, Types.CurrentUserQueryVariables>;
export const EventsForOfferDocument = gql`
    query EventsForOffer($input: EventsForOfferInput!) {
  eventsForOffer(input: $input) {
    name
    isDone
    doneAt
    label
  }
}
    `;

/**
 * __useEventsForOfferQuery__
 *
 * To run a query within a React component, call `useEventsForOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsForOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsForOfferQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEventsForOfferQuery(baseOptions: Apollo.QueryHookOptions<Types.EventsForOfferQuery, Types.EventsForOfferQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.EventsForOfferQuery, Types.EventsForOfferQueryVariables>(EventsForOfferDocument, options);
      }
export function useEventsForOfferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.EventsForOfferQuery, Types.EventsForOfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.EventsForOfferQuery, Types.EventsForOfferQueryVariables>(EventsForOfferDocument, options);
        }
export type EventsForOfferQueryHookResult = ReturnType<typeof useEventsForOfferQuery>;
export type EventsForOfferLazyQueryHookResult = ReturnType<typeof useEventsForOfferLazyQuery>;
export type EventsForOfferQueryResult = Apollo.QueryResult<Types.EventsForOfferQuery, Types.EventsForOfferQueryVariables>;
export const FindMlsAgentDocument = gql`
    query findMLSAgent($input: FindMLSAgentInput!) {
  findMLSAgent(input: $input) {
    ...FindMLSAgent
  }
}
    ${FindMlsAgentFragmentDoc}`;

/**
 * __useFindMlsAgentQuery__
 *
 * To run a query within a React component, call `useFindMlsAgentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMlsAgentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMlsAgentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindMlsAgentQuery(baseOptions: Apollo.QueryHookOptions<Types.FindMlsAgentQuery, Types.FindMlsAgentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FindMlsAgentQuery, Types.FindMlsAgentQueryVariables>(FindMlsAgentDocument, options);
      }
export function useFindMlsAgentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FindMlsAgentQuery, Types.FindMlsAgentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FindMlsAgentQuery, Types.FindMlsAgentQueryVariables>(FindMlsAgentDocument, options);
        }
export type FindMlsAgentQueryHookResult = ReturnType<typeof useFindMlsAgentQuery>;
export type FindMlsAgentLazyQueryHookResult = ReturnType<typeof useFindMlsAgentLazyQuery>;
export type FindMlsAgentQueryResult = Apollo.QueryResult<Types.FindMlsAgentQuery, Types.FindMlsAgentQueryVariables>;
export const FindMlsAgentByMlsIdDocument = gql`
    query findMLSAgentByMlsId($input: FindMLSAgentByMlsIdInput!) {
  findMLSAgentByMlsId(input: $input) {
    ...FindMLSAgent
  }
}
    ${FindMlsAgentFragmentDoc}`;

/**
 * __useFindMlsAgentByMlsIdQuery__
 *
 * To run a query within a React component, call `useFindMlsAgentByMlsIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMlsAgentByMlsIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMlsAgentByMlsIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindMlsAgentByMlsIdQuery(baseOptions: Apollo.QueryHookOptions<Types.FindMlsAgentByMlsIdQuery, Types.FindMlsAgentByMlsIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FindMlsAgentByMlsIdQuery, Types.FindMlsAgentByMlsIdQueryVariables>(FindMlsAgentByMlsIdDocument, options);
      }
export function useFindMlsAgentByMlsIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FindMlsAgentByMlsIdQuery, Types.FindMlsAgentByMlsIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FindMlsAgentByMlsIdQuery, Types.FindMlsAgentByMlsIdQueryVariables>(FindMlsAgentByMlsIdDocument, options);
        }
export type FindMlsAgentByMlsIdQueryHookResult = ReturnType<typeof useFindMlsAgentByMlsIdQuery>;
export type FindMlsAgentByMlsIdLazyQueryHookResult = ReturnType<typeof useFindMlsAgentByMlsIdLazyQuery>;
export type FindMlsAgentByMlsIdQueryResult = Apollo.QueryResult<Types.FindMlsAgentByMlsIdQuery, Types.FindMlsAgentByMlsIdQueryVariables>;
export const FindMlsAgentsByPhoneNumberDocument = gql`
    query findMLSAgentsByPhoneNumber($input: FindMLSAgentsByPhoneNumberInput!) {
  findMLSAgentsByPhoneNumber(input: $input) {
    ...FindMLSAgent
  }
}
    ${FindMlsAgentFragmentDoc}`;

/**
 * __useFindMlsAgentsByPhoneNumberQuery__
 *
 * To run a query within a React component, call `useFindMlsAgentsByPhoneNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMlsAgentsByPhoneNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMlsAgentsByPhoneNumberQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindMlsAgentsByPhoneNumberQuery(baseOptions: Apollo.QueryHookOptions<Types.FindMlsAgentsByPhoneNumberQuery, Types.FindMlsAgentsByPhoneNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FindMlsAgentsByPhoneNumberQuery, Types.FindMlsAgentsByPhoneNumberQueryVariables>(FindMlsAgentsByPhoneNumberDocument, options);
      }
export function useFindMlsAgentsByPhoneNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FindMlsAgentsByPhoneNumberQuery, Types.FindMlsAgentsByPhoneNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FindMlsAgentsByPhoneNumberQuery, Types.FindMlsAgentsByPhoneNumberQueryVariables>(FindMlsAgentsByPhoneNumberDocument, options);
        }
export type FindMlsAgentsByPhoneNumberQueryHookResult = ReturnType<typeof useFindMlsAgentsByPhoneNumberQuery>;
export type FindMlsAgentsByPhoneNumberLazyQueryHookResult = ReturnType<typeof useFindMlsAgentsByPhoneNumberLazyQuery>;
export type FindMlsAgentsByPhoneNumberQueryResult = Apollo.QueryResult<Types.FindMlsAgentsByPhoneNumberQuery, Types.FindMlsAgentsByPhoneNumberQueryVariables>;
export const FollowedListingsDocument = gql`
    query FollowedListings($preferenceValues: Boolean = false) {
  followedListings {
    ...Listing
  }
}
    ${ListingFragmentDoc}`;

/**
 * __useFollowedListingsQuery__
 *
 * To run a query within a React component, call `useFollowedListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowedListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowedListingsQuery({
 *   variables: {
 *      preferenceValues: // value for 'preferenceValues'
 *   },
 * });
 */
export function useFollowedListingsQuery(baseOptions?: Apollo.QueryHookOptions<Types.FollowedListingsQuery, Types.FollowedListingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FollowedListingsQuery, Types.FollowedListingsQueryVariables>(FollowedListingsDocument, options);
      }
export function useFollowedListingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FollowedListingsQuery, Types.FollowedListingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FollowedListingsQuery, Types.FollowedListingsQueryVariables>(FollowedListingsDocument, options);
        }
export type FollowedListingsQueryHookResult = ReturnType<typeof useFollowedListingsQuery>;
export type FollowedListingsLazyQueryHookResult = ReturnType<typeof useFollowedListingsLazyQuery>;
export type FollowedListingsQueryResult = Apollo.QueryResult<Types.FollowedListingsQuery, Types.FollowedListingsQueryVariables>;
export const GetBoaByShareLinkTokenDocument = gql`
    query GetBOAByShareLinkToken($input: GetBOAByShareLinkTokenInput!, $preferenceValues: Boolean! = false) {
  getBOAByShareLinkToken(input: $input) {
    ...BuyerOfferAnalysis
  }
}
    ${BuyerOfferAnalysisFragmentDoc}`;

/**
 * __useGetBoaByShareLinkTokenQuery__
 *
 * To run a query within a React component, call `useGetBoaByShareLinkTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBoaByShareLinkTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBoaByShareLinkTokenQuery({
 *   variables: {
 *      input: // value for 'input'
 *      preferenceValues: // value for 'preferenceValues'
 *   },
 * });
 */
export function useGetBoaByShareLinkTokenQuery(baseOptions: Apollo.QueryHookOptions<Types.GetBoaByShareLinkTokenQuery, Types.GetBoaByShareLinkTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetBoaByShareLinkTokenQuery, Types.GetBoaByShareLinkTokenQueryVariables>(GetBoaByShareLinkTokenDocument, options);
      }
export function useGetBoaByShareLinkTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetBoaByShareLinkTokenQuery, Types.GetBoaByShareLinkTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetBoaByShareLinkTokenQuery, Types.GetBoaByShareLinkTokenQueryVariables>(GetBoaByShareLinkTokenDocument, options);
        }
export type GetBoaByShareLinkTokenQueryHookResult = ReturnType<typeof useGetBoaByShareLinkTokenQuery>;
export type GetBoaByShareLinkTokenLazyQueryHookResult = ReturnType<typeof useGetBoaByShareLinkTokenLazyQuery>;
export type GetBoaByShareLinkTokenQueryResult = Apollo.QueryResult<Types.GetBoaByShareLinkTokenQuery, Types.GetBoaByShareLinkTokenQueryVariables>;
export const GetBuyerOfferAnalysisDocument = gql`
    query GetBuyerOfferAnalysis($uuid: String!, $preferenceValues: Boolean! = false) {
  getBuyerOfferAnalysis(uuid: $uuid) {
    ...BuyerOfferAnalysis
  }
}
    ${BuyerOfferAnalysisFragmentDoc}`;

/**
 * __useGetBuyerOfferAnalysisQuery__
 *
 * To run a query within a React component, call `useGetBuyerOfferAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuyerOfferAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuyerOfferAnalysisQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      preferenceValues: // value for 'preferenceValues'
 *   },
 * });
 */
export function useGetBuyerOfferAnalysisQuery(baseOptions: Apollo.QueryHookOptions<Types.GetBuyerOfferAnalysisQuery, Types.GetBuyerOfferAnalysisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetBuyerOfferAnalysisQuery, Types.GetBuyerOfferAnalysisQueryVariables>(GetBuyerOfferAnalysisDocument, options);
      }
export function useGetBuyerOfferAnalysisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetBuyerOfferAnalysisQuery, Types.GetBuyerOfferAnalysisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetBuyerOfferAnalysisQuery, Types.GetBuyerOfferAnalysisQueryVariables>(GetBuyerOfferAnalysisDocument, options);
        }
export type GetBuyerOfferAnalysisQueryHookResult = ReturnType<typeof useGetBuyerOfferAnalysisQuery>;
export type GetBuyerOfferAnalysisLazyQueryHookResult = ReturnType<typeof useGetBuyerOfferAnalysisLazyQuery>;
export type GetBuyerOfferAnalysisQueryResult = Apollo.QueryResult<Types.GetBuyerOfferAnalysisQuery, Types.GetBuyerOfferAnalysisQueryVariables>;
export const GetInviteeDetailByCodeDocument = gql`
    query GetInviteeDetailByCode($input: SendOTPFromInvitationLinkInput!) {
  getInviteeDetailByCode(input: $input) {
    id
    firstName
    lastName
    email
    phoneNumber
  }
}
    `;

/**
 * __useGetInviteeDetailByCodeQuery__
 *
 * To run a query within a React component, call `useGetInviteeDetailByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInviteeDetailByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInviteeDetailByCodeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetInviteeDetailByCodeQuery(baseOptions: Apollo.QueryHookOptions<Types.GetInviteeDetailByCodeQuery, Types.GetInviteeDetailByCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetInviteeDetailByCodeQuery, Types.GetInviteeDetailByCodeQueryVariables>(GetInviteeDetailByCodeDocument, options);
      }
export function useGetInviteeDetailByCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetInviteeDetailByCodeQuery, Types.GetInviteeDetailByCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetInviteeDetailByCodeQuery, Types.GetInviteeDetailByCodeQueryVariables>(GetInviteeDetailByCodeDocument, options);
        }
export type GetInviteeDetailByCodeQueryHookResult = ReturnType<typeof useGetInviteeDetailByCodeQuery>;
export type GetInviteeDetailByCodeLazyQueryHookResult = ReturnType<typeof useGetInviteeDetailByCodeLazyQuery>;
export type GetInviteeDetailByCodeQueryResult = Apollo.QueryResult<Types.GetInviteeDetailByCodeQuery, Types.GetInviteeDetailByCodeQueryVariables>;
export const GetOfferForBuyersAgentDocument = gql`
    query getOfferForBuyersAgent($input: GetOfferForBuyersAgentInput!) {
  getOfferForBuyersAgent(input: $input) {
    ...Offer
    contract {
      ...OfferContract
    }
    listing {
      id
      property {
        id
        slug
        state
      }
      mlsListing {
        ...MLSListing
      }
    }
  }
}
    ${OfferFragmentDoc}
${OfferContractFragmentDoc}
${MlsListingFragmentDoc}`;

/**
 * __useGetOfferForBuyersAgentQuery__
 *
 * To run a query within a React component, call `useGetOfferForBuyersAgentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfferForBuyersAgentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfferForBuyersAgentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOfferForBuyersAgentQuery(baseOptions: Apollo.QueryHookOptions<Types.GetOfferForBuyersAgentQuery, Types.GetOfferForBuyersAgentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetOfferForBuyersAgentQuery, Types.GetOfferForBuyersAgentQueryVariables>(GetOfferForBuyersAgentDocument, options);
      }
export function useGetOfferForBuyersAgentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetOfferForBuyersAgentQuery, Types.GetOfferForBuyersAgentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetOfferForBuyersAgentQuery, Types.GetOfferForBuyersAgentQueryVariables>(GetOfferForBuyersAgentDocument, options);
        }
export type GetOfferForBuyersAgentQueryHookResult = ReturnType<typeof useGetOfferForBuyersAgentQuery>;
export type GetOfferForBuyersAgentLazyQueryHookResult = ReturnType<typeof useGetOfferForBuyersAgentLazyQuery>;
export type GetOfferForBuyersAgentQueryResult = Apollo.QueryResult<Types.GetOfferForBuyersAgentQuery, Types.GetOfferForBuyersAgentQueryVariables>;
export const GetOfferForListingAgentDocument = gql`
    query getOfferForListingAgent($input: GetOfferForListingAgentInput!) {
  getOfferForListingAgent(input: $input) {
    ...Offer
    contract {
      ...OfferContract
    }
    listing {
      id
      property {
        id
        slug
        state
      }
      mlsListing {
        ...MLSListing
      }
    }
  }
}
    ${OfferFragmentDoc}
${OfferContractFragmentDoc}
${MlsListingFragmentDoc}`;

/**
 * __useGetOfferForListingAgentQuery__
 *
 * To run a query within a React component, call `useGetOfferForListingAgentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfferForListingAgentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfferForListingAgentQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOfferForListingAgentQuery(baseOptions: Apollo.QueryHookOptions<Types.GetOfferForListingAgentQuery, Types.GetOfferForListingAgentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetOfferForListingAgentQuery, Types.GetOfferForListingAgentQueryVariables>(GetOfferForListingAgentDocument, options);
      }
export function useGetOfferForListingAgentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetOfferForListingAgentQuery, Types.GetOfferForListingAgentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetOfferForListingAgentQuery, Types.GetOfferForListingAgentQueryVariables>(GetOfferForListingAgentDocument, options);
        }
export type GetOfferForListingAgentQueryHookResult = ReturnType<typeof useGetOfferForListingAgentQuery>;
export type GetOfferForListingAgentLazyQueryHookResult = ReturnType<typeof useGetOfferForListingAgentLazyQuery>;
export type GetOfferForListingAgentQueryResult = Apollo.QueryResult<Types.GetOfferForListingAgentQuery, Types.GetOfferForListingAgentQueryVariables>;
export const GetOfferRecsDocument = gql`
    query GetOfferRecs($input: GetOfferRecsInput!) {
  getOfferRecs(input: $input) {
    ...OfferRecs
  }
}
    ${OfferRecsFragmentDoc}`;

/**
 * __useGetOfferRecsQuery__
 *
 * To run a query within a React component, call `useGetOfferRecsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfferRecsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfferRecsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOfferRecsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetOfferRecsQuery, Types.GetOfferRecsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetOfferRecsQuery, Types.GetOfferRecsQueryVariables>(GetOfferRecsDocument, options);
      }
export function useGetOfferRecsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetOfferRecsQuery, Types.GetOfferRecsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetOfferRecsQuery, Types.GetOfferRecsQueryVariables>(GetOfferRecsDocument, options);
        }
export type GetOfferRecsQueryHookResult = ReturnType<typeof useGetOfferRecsQuery>;
export type GetOfferRecsLazyQueryHookResult = ReturnType<typeof useGetOfferRecsLazyQuery>;
export type GetOfferRecsQueryResult = Apollo.QueryResult<Types.GetOfferRecsQuery, Types.GetOfferRecsQueryVariables>;
export const GetSalesComparablesDocument = gql`
    query getSalesComparables($input: SalesComparablesInput!, $preferenceValues: Boolean = false) {
  getSalesComparables(input: $input) {
    comparables {
      ...MLSListing
    }
    listing {
      ...Listing
    }
  }
}
    ${MlsListingFragmentDoc}
${ListingFragmentDoc}`;

/**
 * __useGetSalesComparablesQuery__
 *
 * To run a query within a React component, call `useGetSalesComparablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesComparablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesComparablesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      preferenceValues: // value for 'preferenceValues'
 *   },
 * });
 */
export function useGetSalesComparablesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSalesComparablesQuery, Types.GetSalesComparablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSalesComparablesQuery, Types.GetSalesComparablesQueryVariables>(GetSalesComparablesDocument, options);
      }
export function useGetSalesComparablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSalesComparablesQuery, Types.GetSalesComparablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSalesComparablesQuery, Types.GetSalesComparablesQueryVariables>(GetSalesComparablesDocument, options);
        }
export type GetSalesComparablesQueryHookResult = ReturnType<typeof useGetSalesComparablesQuery>;
export type GetSalesComparablesLazyQueryHookResult = ReturnType<typeof useGetSalesComparablesLazyQuery>;
export type GetSalesComparablesQueryResult = Apollo.QueryResult<Types.GetSalesComparablesQuery, Types.GetSalesComparablesQueryVariables>;
export const GetUserToursDocument = gql`
    query GetUserTours {
  getUserTours {
    id
    tourName
  }
}
    `;

/**
 * __useGetUserToursQuery__
 *
 * To run a query within a React component, call `useGetUserToursQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserToursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserToursQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserToursQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetUserToursQuery, Types.GetUserToursQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserToursQuery, Types.GetUserToursQueryVariables>(GetUserToursDocument, options);
      }
export function useGetUserToursLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserToursQuery, Types.GetUserToursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserToursQuery, Types.GetUserToursQueryVariables>(GetUserToursDocument, options);
        }
export type GetUserToursQueryHookResult = ReturnType<typeof useGetUserToursQuery>;
export type GetUserToursLazyQueryHookResult = ReturnType<typeof useGetUserToursLazyQuery>;
export type GetUserToursQueryResult = Apollo.QueryResult<Types.GetUserToursQuery, Types.GetUserToursQueryVariables>;
export const HasSignedListingGuestbookDocument = gql`
    query HasSignedListingGuestbook($input: ListingInput!) {
  hasSignedListingGuestbook(input: $input) {
    hasSigned
  }
}
    `;

/**
 * __useHasSignedListingGuestbookQuery__
 *
 * To run a query within a React component, call `useHasSignedListingGuestbookQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasSignedListingGuestbookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasSignedListingGuestbookQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHasSignedListingGuestbookQuery(baseOptions: Apollo.QueryHookOptions<Types.HasSignedListingGuestbookQuery, Types.HasSignedListingGuestbookQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.HasSignedListingGuestbookQuery, Types.HasSignedListingGuestbookQueryVariables>(HasSignedListingGuestbookDocument, options);
      }
export function useHasSignedListingGuestbookLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.HasSignedListingGuestbookQuery, Types.HasSignedListingGuestbookQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.HasSignedListingGuestbookQuery, Types.HasSignedListingGuestbookQueryVariables>(HasSignedListingGuestbookDocument, options);
        }
export type HasSignedListingGuestbookQueryHookResult = ReturnType<typeof useHasSignedListingGuestbookQuery>;
export type HasSignedListingGuestbookLazyQueryHookResult = ReturnType<typeof useHasSignedListingGuestbookLazyQuery>;
export type HasSignedListingGuestbookQueryResult = Apollo.QueryResult<Types.HasSignedListingGuestbookQuery, Types.HasSignedListingGuestbookQueryVariables>;
export const IndigoContractsForCurrentUserDocument = gql`
    query indigoContractsForCurrentUser($filters: IndigoContractsForCurrentUserFilters, $cursor: String, $orderBy: String! = "-id", $perPage: Int! = 10, $includeFieldValues: Boolean = false, $includeContractDocuments: Boolean = false) {
  indigoContractsForCurrentUser(
    filters: $filters
    cursor: $cursor
    orderBy: $orderBy
    perPage: $perPage
  ) {
    pageInfo {
      ...PageInfo
    }
    results {
      ...IndigoContractsForCurrentUser
    }
  }
}
    ${PageInfoFragmentDoc}
${IndigoContractsForCurrentUserFragmentDoc}`;

/**
 * __useIndigoContractsForCurrentUserQuery__
 *
 * To run a query within a React component, call `useIndigoContractsForCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndigoContractsForCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndigoContractsForCurrentUserQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      cursor: // value for 'cursor'
 *      orderBy: // value for 'orderBy'
 *      perPage: // value for 'perPage'
 *      includeFieldValues: // value for 'includeFieldValues'
 *      includeContractDocuments: // value for 'includeContractDocuments'
 *   },
 * });
 */
export function useIndigoContractsForCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<Types.IndigoContractsForCurrentUserQuery, Types.IndigoContractsForCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IndigoContractsForCurrentUserQuery, Types.IndigoContractsForCurrentUserQueryVariables>(IndigoContractsForCurrentUserDocument, options);
      }
export function useIndigoContractsForCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IndigoContractsForCurrentUserQuery, Types.IndigoContractsForCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IndigoContractsForCurrentUserQuery, Types.IndigoContractsForCurrentUserQueryVariables>(IndigoContractsForCurrentUserDocument, options);
        }
export type IndigoContractsForCurrentUserQueryHookResult = ReturnType<typeof useIndigoContractsForCurrentUserQuery>;
export type IndigoContractsForCurrentUserLazyQueryHookResult = ReturnType<typeof useIndigoContractsForCurrentUserLazyQuery>;
export type IndigoContractsForCurrentUserQueryResult = Apollo.QueryResult<Types.IndigoContractsForCurrentUserQuery, Types.IndigoContractsForCurrentUserQueryVariables>;
export const InvitationsListDocument = gql`
    query invitationsList($filters: AccessControlsListFilters!) {
  invitationsList(filters: $filters, perPage: 100, orderBy: "id") {
    results {
      ...Invitation
      inviteeAccessRole
    }
  }
}
    ${InvitationFragmentDoc}`;

/**
 * __useInvitationsListQuery__
 *
 * To run a query within a React component, call `useInvitationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvitationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitationsListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useInvitationsListQuery(baseOptions: Apollo.QueryHookOptions<Types.InvitationsListQuery, Types.InvitationsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.InvitationsListQuery, Types.InvitationsListQueryVariables>(InvitationsListDocument, options);
      }
export function useInvitationsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.InvitationsListQuery, Types.InvitationsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.InvitationsListQuery, Types.InvitationsListQueryVariables>(InvitationsListDocument, options);
        }
export type InvitationsListQueryHookResult = ReturnType<typeof useInvitationsListQuery>;
export type InvitationsListLazyQueryHookResult = ReturnType<typeof useInvitationsListLazyQuery>;
export type InvitationsListQueryResult = Apollo.QueryResult<Types.InvitationsListQuery, Types.InvitationsListQueryVariables>;
export const IsFollowingListingDocument = gql`
    query IsFollowingListing($input: ListingInput!) {
  isFollowingListing(input: $input) {
    isFollowingListing
  }
}
    `;

/**
 * __useIsFollowingListingQuery__
 *
 * To run a query within a React component, call `useIsFollowingListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsFollowingListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsFollowingListingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIsFollowingListingQuery(baseOptions: Apollo.QueryHookOptions<Types.IsFollowingListingQuery, Types.IsFollowingListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IsFollowingListingQuery, Types.IsFollowingListingQueryVariables>(IsFollowingListingDocument, options);
      }
export function useIsFollowingListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IsFollowingListingQuery, Types.IsFollowingListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IsFollowingListingQuery, Types.IsFollowingListingQueryVariables>(IsFollowingListingDocument, options);
        }
export type IsFollowingListingQueryHookResult = ReturnType<typeof useIsFollowingListingQuery>;
export type IsFollowingListingLazyQueryHookResult = ReturnType<typeof useIsFollowingListingLazyQuery>;
export type IsFollowingListingQueryResult = Apollo.QueryResult<Types.IsFollowingListingQuery, Types.IsFollowingListingQueryVariables>;
export const IsOpenHouseGuestBookSignedDocument = gql`
    query IsOpenHouseGuestBookSigned($input: GetOpenHouseGuestbookStatusInput!) {
  isOpenHouseGuestbookSigned(input: $input)
}
    `;

/**
 * __useIsOpenHouseGuestBookSignedQuery__
 *
 * To run a query within a React component, call `useIsOpenHouseGuestBookSignedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsOpenHouseGuestBookSignedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsOpenHouseGuestBookSignedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIsOpenHouseGuestBookSignedQuery(baseOptions: Apollo.QueryHookOptions<Types.IsOpenHouseGuestBookSignedQuery, Types.IsOpenHouseGuestBookSignedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IsOpenHouseGuestBookSignedQuery, Types.IsOpenHouseGuestBookSignedQueryVariables>(IsOpenHouseGuestBookSignedDocument, options);
      }
export function useIsOpenHouseGuestBookSignedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IsOpenHouseGuestBookSignedQuery, Types.IsOpenHouseGuestBookSignedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IsOpenHouseGuestBookSignedQuery, Types.IsOpenHouseGuestBookSignedQueryVariables>(IsOpenHouseGuestBookSignedDocument, options);
        }
export type IsOpenHouseGuestBookSignedQueryHookResult = ReturnType<typeof useIsOpenHouseGuestBookSignedQuery>;
export type IsOpenHouseGuestBookSignedLazyQueryHookResult = ReturnType<typeof useIsOpenHouseGuestBookSignedLazyQuery>;
export type IsOpenHouseGuestBookSignedQueryResult = Apollo.QueryResult<Types.IsOpenHouseGuestBookSignedQuery, Types.IsOpenHouseGuestBookSignedQueryVariables>;
export const LatestUnsubmittedOfferForBuyersAgentDocument = gql`
    query latestUnsubmittedOfferForBuyersAgent($input: OffersForListingInput!, $isAgentVerified: Boolean = false, $onlyId: Boolean = false) {
  latestUnsubmittedOfferForBuyersAgent(input: $input) @include(if: $isAgentVerified) {
    id @include(if: $onlyId)
    ...Offer @skip(if: $onlyId)
    contract @skip(if: $onlyId) {
      ...OfferContract
    }
    party @include(if: $onlyId) {
      id
    }
  }
  latestUnsubmittedOfferForUnauthenticatedBuyersAgent(input: $input) @skip(if: $isAgentVerified) {
    id @include(if: $onlyId)
    ...Offer @skip(if: $onlyId)
    contract @skip(if: $onlyId) {
      ...OfferContract
    }
  }
}
    ${OfferFragmentDoc}
${OfferContractFragmentDoc}`;

/**
 * __useLatestUnsubmittedOfferForBuyersAgentQuery__
 *
 * To run a query within a React component, call `useLatestUnsubmittedOfferForBuyersAgentQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestUnsubmittedOfferForBuyersAgentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestUnsubmittedOfferForBuyersAgentQuery({
 *   variables: {
 *      input: // value for 'input'
 *      isAgentVerified: // value for 'isAgentVerified'
 *      onlyId: // value for 'onlyId'
 *   },
 * });
 */
export function useLatestUnsubmittedOfferForBuyersAgentQuery(baseOptions: Apollo.QueryHookOptions<Types.LatestUnsubmittedOfferForBuyersAgentQuery, Types.LatestUnsubmittedOfferForBuyersAgentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.LatestUnsubmittedOfferForBuyersAgentQuery, Types.LatestUnsubmittedOfferForBuyersAgentQueryVariables>(LatestUnsubmittedOfferForBuyersAgentDocument, options);
      }
export function useLatestUnsubmittedOfferForBuyersAgentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.LatestUnsubmittedOfferForBuyersAgentQuery, Types.LatestUnsubmittedOfferForBuyersAgentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.LatestUnsubmittedOfferForBuyersAgentQuery, Types.LatestUnsubmittedOfferForBuyersAgentQueryVariables>(LatestUnsubmittedOfferForBuyersAgentDocument, options);
        }
export type LatestUnsubmittedOfferForBuyersAgentQueryHookResult = ReturnType<typeof useLatestUnsubmittedOfferForBuyersAgentQuery>;
export type LatestUnsubmittedOfferForBuyersAgentLazyQueryHookResult = ReturnType<typeof useLatestUnsubmittedOfferForBuyersAgentLazyQuery>;
export type LatestUnsubmittedOfferForBuyersAgentQueryResult = Apollo.QueryResult<Types.LatestUnsubmittedOfferForBuyersAgentQuery, Types.LatestUnsubmittedOfferForBuyersAgentQueryVariables>;
export const ListingDocument = gql`
    query Listing($input: ListingInput!, $preferenceValues: Boolean = false) {
  listing(input: $input) {
    ...Listing
  }
}
    ${ListingFragmentDoc}`;

/**
 * __useListingQuery__
 *
 * To run a query within a React component, call `useListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingQuery({
 *   variables: {
 *      input: // value for 'input'
 *      preferenceValues: // value for 'preferenceValues'
 *   },
 * });
 */
export function useListingQuery(baseOptions: Apollo.QueryHookOptions<Types.ListingQuery, Types.ListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListingQuery, Types.ListingQueryVariables>(ListingDocument, options);
      }
export function useListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListingQuery, Types.ListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListingQuery, Types.ListingQueryVariables>(ListingDocument, options);
        }
export type ListingQueryHookResult = ReturnType<typeof useListingQuery>;
export type ListingLazyQueryHookResult = ReturnType<typeof useListingLazyQuery>;
export type ListingQueryResult = Apollo.QueryResult<Types.ListingQuery, Types.ListingQueryVariables>;
export const ListingActivitiesDocument = gql`
    query ListingActivities($input: ListingInput!) {
  listingActivities(input: $input) {
    id
    type
    user {
      ...PublicUser
    }
    metadata
    createdAt
  }
}
    ${PublicUserFragmentDoc}`;

/**
 * __useListingActivitiesQuery__
 *
 * To run a query within a React component, call `useListingActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingActivitiesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListingActivitiesQuery(baseOptions: Apollo.QueryHookOptions<Types.ListingActivitiesQuery, Types.ListingActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListingActivitiesQuery, Types.ListingActivitiesQueryVariables>(ListingActivitiesDocument, options);
      }
export function useListingActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListingActivitiesQuery, Types.ListingActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListingActivitiesQuery, Types.ListingActivitiesQueryVariables>(ListingActivitiesDocument, options);
        }
export type ListingActivitiesQueryHookResult = ReturnType<typeof useListingActivitiesQuery>;
export type ListingActivitiesLazyQueryHookResult = ReturnType<typeof useListingActivitiesLazyQuery>;
export type ListingActivitiesQueryResult = Apollo.QueryResult<Types.ListingActivitiesQuery, Types.ListingActivitiesQueryVariables>;
export const ListingAndOffersDocument = gql`
    query ListingAndOffers($listingId: String!, $preferenceValues: Boolean = true) {
  listing(input: {id: $listingId}) {
    ...Listing
  }
  offersForListing(input: {listingId: $listingId}) {
    ...OffersForListing
  }
}
    ${ListingFragmentDoc}
${OffersForListingFragmentDoc}`;

/**
 * __useListingAndOffersQuery__
 *
 * To run a query within a React component, call `useListingAndOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingAndOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingAndOffersQuery({
 *   variables: {
 *      listingId: // value for 'listingId'
 *      preferenceValues: // value for 'preferenceValues'
 *   },
 * });
 */
export function useListingAndOffersQuery(baseOptions: Apollo.QueryHookOptions<Types.ListingAndOffersQuery, Types.ListingAndOffersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListingAndOffersQuery, Types.ListingAndOffersQueryVariables>(ListingAndOffersDocument, options);
      }
export function useListingAndOffersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListingAndOffersQuery, Types.ListingAndOffersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListingAndOffersQuery, Types.ListingAndOffersQueryVariables>(ListingAndOffersDocument, options);
        }
export type ListingAndOffersQueryHookResult = ReturnType<typeof useListingAndOffersQuery>;
export type ListingAndOffersLazyQueryHookResult = ReturnType<typeof useListingAndOffersLazyQuery>;
export type ListingAndOffersQueryResult = Apollo.QueryResult<Types.ListingAndOffersQuery, Types.ListingAndOffersQueryVariables>;
export const ListingAnnouncementsDocument = gql`
    query listingAnnouncements($input: ListingAnnouncementInput!) {
  listingAnnouncements(input: $input) {
    ...ListingAnnouncement
  }
}
    ${ListingAnnouncementFragmentDoc}`;

/**
 * __useListingAnnouncementsQuery__
 *
 * To run a query within a React component, call `useListingAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingAnnouncementsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListingAnnouncementsQuery(baseOptions: Apollo.QueryHookOptions<Types.ListingAnnouncementsQuery, Types.ListingAnnouncementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListingAnnouncementsQuery, Types.ListingAnnouncementsQueryVariables>(ListingAnnouncementsDocument, options);
      }
export function useListingAnnouncementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListingAnnouncementsQuery, Types.ListingAnnouncementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListingAnnouncementsQuery, Types.ListingAnnouncementsQueryVariables>(ListingAnnouncementsDocument, options);
        }
export type ListingAnnouncementsQueryHookResult = ReturnType<typeof useListingAnnouncementsQuery>;
export type ListingAnnouncementsLazyQueryHookResult = ReturnType<typeof useListingAnnouncementsLazyQuery>;
export type ListingAnnouncementsQueryResult = Apollo.QueryResult<Types.ListingAnnouncementsQuery, Types.ListingAnnouncementsQueryVariables>;
export const ListingAnnouncementsCountDocument = gql`
    query listingAnnouncementsCount($input: ListingAnnouncementInput!) {
  listingAnnouncementsCount(input: $input)
}
    `;

/**
 * __useListingAnnouncementsCountQuery__
 *
 * To run a query within a React component, call `useListingAnnouncementsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingAnnouncementsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingAnnouncementsCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListingAnnouncementsCountQuery(baseOptions: Apollo.QueryHookOptions<Types.ListingAnnouncementsCountQuery, Types.ListingAnnouncementsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListingAnnouncementsCountQuery, Types.ListingAnnouncementsCountQueryVariables>(ListingAnnouncementsCountDocument, options);
      }
export function useListingAnnouncementsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListingAnnouncementsCountQuery, Types.ListingAnnouncementsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListingAnnouncementsCountQuery, Types.ListingAnnouncementsCountQueryVariables>(ListingAnnouncementsCountDocument, options);
        }
export type ListingAnnouncementsCountQueryHookResult = ReturnType<typeof useListingAnnouncementsCountQuery>;
export type ListingAnnouncementsCountLazyQueryHookResult = ReturnType<typeof useListingAnnouncementsCountLazyQuery>;
export type ListingAnnouncementsCountQueryResult = Apollo.QueryResult<Types.ListingAnnouncementsCountQuery, Types.ListingAnnouncementsCountQueryVariables>;
export const ListingComparableSetDocument = gql`
    query listingComparableSet($input: ListingComparableSetInput!) {
  listingComparableSet(input: $input) {
    ...ListingComparableSet
  }
}
    ${ListingComparableSetFragmentDoc}`;

/**
 * __useListingComparableSetQuery__
 *
 * To run a query within a React component, call `useListingComparableSetQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingComparableSetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingComparableSetQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListingComparableSetQuery(baseOptions: Apollo.QueryHookOptions<Types.ListingComparableSetQuery, Types.ListingComparableSetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListingComparableSetQuery, Types.ListingComparableSetQueryVariables>(ListingComparableSetDocument, options);
      }
export function useListingComparableSetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListingComparableSetQuery, Types.ListingComparableSetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListingComparableSetQuery, Types.ListingComparableSetQueryVariables>(ListingComparableSetDocument, options);
        }
export type ListingComparableSetQueryHookResult = ReturnType<typeof useListingComparableSetQuery>;
export type ListingComparableSetLazyQueryHookResult = ReturnType<typeof useListingComparableSetLazyQuery>;
export type ListingComparableSetQueryResult = Apollo.QueryResult<Types.ListingComparableSetQuery, Types.ListingComparableSetQueryVariables>;
export const ListingComparableSetForBoaTokenDocument = gql`
    query listingComparableSetForBOAToken($input: ListingComparableSetForBOATokenInput!) {
  listingComparableSetForBOAToken(input: $input) {
    ...ListingComparableSet
  }
}
    ${ListingComparableSetFragmentDoc}`;

/**
 * __useListingComparableSetForBoaTokenQuery__
 *
 * To run a query within a React component, call `useListingComparableSetForBoaTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingComparableSetForBoaTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingComparableSetForBoaTokenQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListingComparableSetForBoaTokenQuery(baseOptions: Apollo.QueryHookOptions<Types.ListingComparableSetForBoaTokenQuery, Types.ListingComparableSetForBoaTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListingComparableSetForBoaTokenQuery, Types.ListingComparableSetForBoaTokenQueryVariables>(ListingComparableSetForBoaTokenDocument, options);
      }
export function useListingComparableSetForBoaTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListingComparableSetForBoaTokenQuery, Types.ListingComparableSetForBoaTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListingComparableSetForBoaTokenQuery, Types.ListingComparableSetForBoaTokenQueryVariables>(ListingComparableSetForBoaTokenDocument, options);
        }
export type ListingComparableSetForBoaTokenQueryHookResult = ReturnType<typeof useListingComparableSetForBoaTokenQuery>;
export type ListingComparableSetForBoaTokenLazyQueryHookResult = ReturnType<typeof useListingComparableSetForBoaTokenLazyQuery>;
export type ListingComparableSetForBoaTokenQueryResult = Apollo.QueryResult<Types.ListingComparableSetForBoaTokenQuery, Types.ListingComparableSetForBoaTokenQueryVariables>;
export const ListingComparableSetForBuyerOfferAnalysisDocument = gql`
    query listingComparableSetForBuyerOfferAnalysis($input: ListingComparableSetForBuyerOfferAnalysisInput!) {
  listingComparableSetForBuyerOfferAnalysis(input: $input) {
    ...ListingComparableSet
  }
}
    ${ListingComparableSetFragmentDoc}`;

/**
 * __useListingComparableSetForBuyerOfferAnalysisQuery__
 *
 * To run a query within a React component, call `useListingComparableSetForBuyerOfferAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingComparableSetForBuyerOfferAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingComparableSetForBuyerOfferAnalysisQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListingComparableSetForBuyerOfferAnalysisQuery(baseOptions: Apollo.QueryHookOptions<Types.ListingComparableSetForBuyerOfferAnalysisQuery, Types.ListingComparableSetForBuyerOfferAnalysisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListingComparableSetForBuyerOfferAnalysisQuery, Types.ListingComparableSetForBuyerOfferAnalysisQueryVariables>(ListingComparableSetForBuyerOfferAnalysisDocument, options);
      }
export function useListingComparableSetForBuyerOfferAnalysisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListingComparableSetForBuyerOfferAnalysisQuery, Types.ListingComparableSetForBuyerOfferAnalysisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListingComparableSetForBuyerOfferAnalysisQuery, Types.ListingComparableSetForBuyerOfferAnalysisQueryVariables>(ListingComparableSetForBuyerOfferAnalysisDocument, options);
        }
export type ListingComparableSetForBuyerOfferAnalysisQueryHookResult = ReturnType<typeof useListingComparableSetForBuyerOfferAnalysisQuery>;
export type ListingComparableSetForBuyerOfferAnalysisLazyQueryHookResult = ReturnType<typeof useListingComparableSetForBuyerOfferAnalysisLazyQuery>;
export type ListingComparableSetForBuyerOfferAnalysisQueryResult = Apollo.QueryResult<Types.ListingComparableSetForBuyerOfferAnalysisQuery, Types.ListingComparableSetForBuyerOfferAnalysisQueryVariables>;
export const ListingDocumentTypesDocument = gql`
    query listingDocumentTypes($input: ListingDocumentTypesInput!) {
  listingDocumentTypes(input: $input) {
    ...ListingDocumentType
  }
}
    ${ListingDocumentTypeFragmentDoc}`;

/**
 * __useListingDocumentTypesQuery__
 *
 * To run a query within a React component, call `useListingDocumentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingDocumentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingDocumentTypesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListingDocumentTypesQuery(baseOptions: Apollo.QueryHookOptions<Types.ListingDocumentTypesQuery, Types.ListingDocumentTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListingDocumentTypesQuery, Types.ListingDocumentTypesQueryVariables>(ListingDocumentTypesDocument, options);
      }
export function useListingDocumentTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListingDocumentTypesQuery, Types.ListingDocumentTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListingDocumentTypesQuery, Types.ListingDocumentTypesQueryVariables>(ListingDocumentTypesDocument, options);
        }
export type ListingDocumentTypesQueryHookResult = ReturnType<typeof useListingDocumentTypesQuery>;
export type ListingDocumentTypesLazyQueryHookResult = ReturnType<typeof useListingDocumentTypesLazyQuery>;
export type ListingDocumentTypesQueryResult = Apollo.QueryResult<Types.ListingDocumentTypesQuery, Types.ListingDocumentTypesQueryVariables>;
export const ListingDocumentUploadsDocument = gql`
    query listingDocumentUploads($input: ListingDocumentUploadsInput!) {
  listingDocumentUploads(input: $input) {
    listingDocumentType {
      ...ListingDocumentType
      ...ListingDocumentTypeWithDocuments
    }
    ...ListingDocumentUpload
  }
}
    ${ListingDocumentTypeFragmentDoc}
${ListingDocumentTypeWithDocumentsFragmentDoc}
${ListingDocumentUploadFragmentDoc}`;

/**
 * __useListingDocumentUploadsQuery__
 *
 * To run a query within a React component, call `useListingDocumentUploadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingDocumentUploadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingDocumentUploadsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListingDocumentUploadsQuery(baseOptions: Apollo.QueryHookOptions<Types.ListingDocumentUploadsQuery, Types.ListingDocumentUploadsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListingDocumentUploadsQuery, Types.ListingDocumentUploadsQueryVariables>(ListingDocumentUploadsDocument, options);
      }
export function useListingDocumentUploadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListingDocumentUploadsQuery, Types.ListingDocumentUploadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListingDocumentUploadsQuery, Types.ListingDocumentUploadsQueryVariables>(ListingDocumentUploadsDocument, options);
        }
export type ListingDocumentUploadsQueryHookResult = ReturnType<typeof useListingDocumentUploadsQuery>;
export type ListingDocumentUploadsLazyQueryHookResult = ReturnType<typeof useListingDocumentUploadsLazyQuery>;
export type ListingDocumentUploadsQueryResult = Apollo.QueryResult<Types.ListingDocumentUploadsQuery, Types.ListingDocumentUploadsQueryVariables>;
export const ListingFollowersDocument = gql`
    query ListingFollowers($input: ListingInput!) {
  listingFollowers(input: $input) {
    listingFollowers {
      ...ListingFollower
    }
  }
}
    ${ListingFollowerFragmentDoc}`;

/**
 * __useListingFollowersQuery__
 *
 * To run a query within a React component, call `useListingFollowersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingFollowersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingFollowersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListingFollowersQuery(baseOptions: Apollo.QueryHookOptions<Types.ListingFollowersQuery, Types.ListingFollowersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListingFollowersQuery, Types.ListingFollowersQueryVariables>(ListingFollowersDocument, options);
      }
export function useListingFollowersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListingFollowersQuery, Types.ListingFollowersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListingFollowersQuery, Types.ListingFollowersQueryVariables>(ListingFollowersDocument, options);
        }
export type ListingFollowersQueryHookResult = ReturnType<typeof useListingFollowersQuery>;
export type ListingFollowersLazyQueryHookResult = ReturnType<typeof useListingFollowersLazyQuery>;
export type ListingFollowersQueryResult = Apollo.QueryResult<Types.ListingFollowersQuery, Types.ListingFollowersQueryVariables>;
export const ListingforMlsListingDocument = gql`
    query ListingforMLSListing($input: MLSListingInput!) {
  syncListingFromMLS(input: $input) {
    id
    property {
      id
      slug
    }
  }
}
    `;

/**
 * __useListingforMlsListingQuery__
 *
 * To run a query within a React component, call `useListingforMlsListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingforMlsListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingforMlsListingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListingforMlsListingQuery(baseOptions: Apollo.QueryHookOptions<Types.ListingforMlsListingQuery, Types.ListingforMlsListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListingforMlsListingQuery, Types.ListingforMlsListingQueryVariables>(ListingforMlsListingDocument, options);
      }
export function useListingforMlsListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListingforMlsListingQuery, Types.ListingforMlsListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListingforMlsListingQuery, Types.ListingforMlsListingQueryVariables>(ListingforMlsListingDocument, options);
        }
export type ListingforMlsListingQueryHookResult = ReturnType<typeof useListingforMlsListingQuery>;
export type ListingforMlsListingLazyQueryHookResult = ReturnType<typeof useListingforMlsListingLazyQuery>;
export type ListingforMlsListingQueryResult = Apollo.QueryResult<Types.ListingforMlsListingQuery, Types.ListingforMlsListingQueryVariables>;
export const ListingOpenHousesDocument = gql`
    query ListingOpenHouses($input: ListingOpenHousesInput!) {
  listingOpenHouses(input: $input) {
    results {
      ...OpenHouseCard
    }
  }
}
    ${OpenHouseCardFragmentDoc}`;

/**
 * __useListingOpenHousesQuery__
 *
 * To run a query within a React component, call `useListingOpenHousesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingOpenHousesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingOpenHousesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListingOpenHousesQuery(baseOptions: Apollo.QueryHookOptions<Types.ListingOpenHousesQuery, Types.ListingOpenHousesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListingOpenHousesQuery, Types.ListingOpenHousesQueryVariables>(ListingOpenHousesDocument, options);
      }
export function useListingOpenHousesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListingOpenHousesQuery, Types.ListingOpenHousesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListingOpenHousesQuery, Types.ListingOpenHousesQueryVariables>(ListingOpenHousesDocument, options);
        }
export type ListingOpenHousesQueryHookResult = ReturnType<typeof useListingOpenHousesQuery>;
export type ListingOpenHousesLazyQueryHookResult = ReturnType<typeof useListingOpenHousesLazyQuery>;
export type ListingOpenHousesQueryResult = Apollo.QueryResult<Types.ListingOpenHousesQuery, Types.ListingOpenHousesQueryVariables>;
export const ListingsForCurrentUserDocument = gql`
    query ListingsForCurrentUser($cursor: String, $preferenceValues: Boolean = false, $perPage: Int! = 20, $orderBy: String! = "-listDate", $filters: ListingsFilterArgs!) {
  listingsForCurrentUser(
    cursor: $cursor
    perPage: $perPage
    orderBy: $orderBy
    filters: $filters
  ) {
    pageInfo {
      hasNextPage
      hasPrevPage
      nextCursor
      perPage
      prevCursor
      totalCount
    }
    results {
      ...Listing
      isSharedListing
    }
  }
}
    ${ListingFragmentDoc}`;

/**
 * __useListingsForCurrentUserQuery__
 *
 * To run a query within a React component, call `useListingsForCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingsForCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingsForCurrentUserQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      preferenceValues: // value for 'preferenceValues'
 *      perPage: // value for 'perPage'
 *      orderBy: // value for 'orderBy'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useListingsForCurrentUserQuery(baseOptions: Apollo.QueryHookOptions<Types.ListingsForCurrentUserQuery, Types.ListingsForCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListingsForCurrentUserQuery, Types.ListingsForCurrentUserQueryVariables>(ListingsForCurrentUserDocument, options);
      }
export function useListingsForCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListingsForCurrentUserQuery, Types.ListingsForCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListingsForCurrentUserQuery, Types.ListingsForCurrentUserQueryVariables>(ListingsForCurrentUserDocument, options);
        }
export type ListingsForCurrentUserQueryHookResult = ReturnType<typeof useListingsForCurrentUserQuery>;
export type ListingsForCurrentUserLazyQueryHookResult = ReturnType<typeof useListingsForCurrentUserLazyQuery>;
export type ListingsForCurrentUserQueryResult = Apollo.QueryResult<Types.ListingsForCurrentUserQuery, Types.ListingsForCurrentUserQueryVariables>;
export const ListingsForOrganizationDocument = gql`
    query ListingsForOrganization($cursor: String, $perPage: Int! = 20, $orderBy: String! = "-listDate", $filters: ListingsForOrganizationFilterArgs!) {
  listingsForOrganization(
    cursor: $cursor
    perPage: $perPage
    orderBy: $orderBy
    filters: $filters
  ) {
    pageInfo {
      hasNextPage
      hasPrevPage
      nextCursor
      perPage
      prevCursor
      totalCount
    }
    results {
      ...ListingForOrganization
    }
  }
}
    ${ListingForOrganizationFragmentDoc}`;

/**
 * __useListingsForOrganizationQuery__
 *
 * To run a query within a React component, call `useListingsForOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingsForOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingsForOrganizationQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      perPage: // value for 'perPage'
 *      orderBy: // value for 'orderBy'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useListingsForOrganizationQuery(baseOptions: Apollo.QueryHookOptions<Types.ListingsForOrganizationQuery, Types.ListingsForOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ListingsForOrganizationQuery, Types.ListingsForOrganizationQueryVariables>(ListingsForOrganizationDocument, options);
      }
export function useListingsForOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ListingsForOrganizationQuery, Types.ListingsForOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ListingsForOrganizationQuery, Types.ListingsForOrganizationQueryVariables>(ListingsForOrganizationDocument, options);
        }
export type ListingsForOrganizationQueryHookResult = ReturnType<typeof useListingsForOrganizationQuery>;
export type ListingsForOrganizationLazyQueryHookResult = ReturnType<typeof useListingsForOrganizationLazyQuery>;
export type ListingsForOrganizationQueryResult = Apollo.QueryResult<Types.ListingsForOrganizationQuery, Types.ListingsForOrganizationQueryVariables>;
export const GetUserNotificationSettingsDocument = gql`
    query GetUserNotificationSettings {
  userNotificationSettings {
    email
    sms
  }
}
    `;

/**
 * __useGetUserNotificationSettingsQuery__
 *
 * To run a query within a React component, call `useGetUserNotificationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserNotificationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserNotificationSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserNotificationSettingsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetUserNotificationSettingsQuery, Types.GetUserNotificationSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserNotificationSettingsQuery, Types.GetUserNotificationSettingsQueryVariables>(GetUserNotificationSettingsDocument, options);
      }
export function useGetUserNotificationSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserNotificationSettingsQuery, Types.GetUserNotificationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserNotificationSettingsQuery, Types.GetUserNotificationSettingsQueryVariables>(GetUserNotificationSettingsDocument, options);
        }
export type GetUserNotificationSettingsQueryHookResult = ReturnType<typeof useGetUserNotificationSettingsQuery>;
export type GetUserNotificationSettingsLazyQueryHookResult = ReturnType<typeof useGetUserNotificationSettingsLazyQuery>;
export type GetUserNotificationSettingsQueryResult = Apollo.QueryResult<Types.GetUserNotificationSettingsQuery, Types.GetUserNotificationSettingsQueryVariables>;
export const OfferAutofillValuesDocument = gql`
    query offerAutofillValues($listingId: ID, $offerId: ID, $documentVersionIds: [ID!]!, $fieldValues: JSONObject!) {
  offerAutofillValues(
    listingId: $listingId
    offerId: $offerId
    documentVersionIds: $documentVersionIds
    fieldValues: $fieldValues
  ) {
    readOnlyAutofillValues
    autofillValues
    helpTextAutofillValues
    optionsAutofillValues
  }
}
    `;

/**
 * __useOfferAutofillValuesQuery__
 *
 * To run a query within a React component, call `useOfferAutofillValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferAutofillValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferAutofillValuesQuery({
 *   variables: {
 *      listingId: // value for 'listingId'
 *      offerId: // value for 'offerId'
 *      documentVersionIds: // value for 'documentVersionIds'
 *      fieldValues: // value for 'fieldValues'
 *   },
 * });
 */
export function useOfferAutofillValuesQuery(baseOptions: Apollo.QueryHookOptions<Types.OfferAutofillValuesQuery, Types.OfferAutofillValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OfferAutofillValuesQuery, Types.OfferAutofillValuesQueryVariables>(OfferAutofillValuesDocument, options);
      }
export function useOfferAutofillValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OfferAutofillValuesQuery, Types.OfferAutofillValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OfferAutofillValuesQuery, Types.OfferAutofillValuesQueryVariables>(OfferAutofillValuesDocument, options);
        }
export type OfferAutofillValuesQueryHookResult = ReturnType<typeof useOfferAutofillValuesQuery>;
export type OfferAutofillValuesLazyQueryHookResult = ReturnType<typeof useOfferAutofillValuesLazyQuery>;
export type OfferAutofillValuesQueryResult = Apollo.QueryResult<Types.OfferAutofillValuesQuery, Types.OfferAutofillValuesQueryVariables>;
export const OfferMetricsDocument = gql`
    query offerMetrics {
  offerMetrics {
    accepted_bid_ask
    not_accepted_earnest_money_deposit
    property_city
    property_state
    purchase_price_range
    not_accepted_due_diligence_fee
    not_accepted_due_diligence_and_deposit
    not_accepted_bid_ask
    interval_start
    accepted_earnest_money_deposit
    accepted_due_diligence_fee
    accepted_due_diligence_and_deposit
  }
}
    `;

/**
 * __useOfferMetricsQuery__
 *
 * To run a query within a React component, call `useOfferMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferMetricsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOfferMetricsQuery(baseOptions?: Apollo.QueryHookOptions<Types.OfferMetricsQuery, Types.OfferMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OfferMetricsQuery, Types.OfferMetricsQueryVariables>(OfferMetricsDocument, options);
      }
export function useOfferMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OfferMetricsQuery, Types.OfferMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OfferMetricsQuery, Types.OfferMetricsQueryVariables>(OfferMetricsDocument, options);
        }
export type OfferMetricsQueryHookResult = ReturnType<typeof useOfferMetricsQuery>;
export type OfferMetricsLazyQueryHookResult = ReturnType<typeof useOfferMetricsLazyQuery>;
export type OfferMetricsQueryResult = Apollo.QueryResult<Types.OfferMetricsQuery, Types.OfferMetricsQueryVariables>;
export const OffersForCurentUserDocument = gql`
    query offersForCurentUser($cursor: String, $orderBy: String! = "-id", $perPage: Int! = 10, $filters: OffersForCurrentUserFilters) {
  offersForCurrentUser(
    cursor: $cursor
    orderBy: $orderBy
    perPage: $perPage
    filters: $filters
  ) {
    pageInfo {
      ...PageInfo
    }
    results {
      ...OffersForCurrentUser
    }
  }
}
    ${PageInfoFragmentDoc}
${OffersForCurrentUserFragmentDoc}`;

/**
 * __useOffersForCurentUserQuery__
 *
 * To run a query within a React component, call `useOffersForCurentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useOffersForCurentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOffersForCurentUserQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      orderBy: // value for 'orderBy'
 *      perPage: // value for 'perPage'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useOffersForCurentUserQuery(baseOptions?: Apollo.QueryHookOptions<Types.OffersForCurentUserQuery, Types.OffersForCurentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OffersForCurentUserQuery, Types.OffersForCurentUserQueryVariables>(OffersForCurentUserDocument, options);
      }
export function useOffersForCurentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OffersForCurentUserQuery, Types.OffersForCurentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OffersForCurentUserQuery, Types.OffersForCurentUserQueryVariables>(OffersForCurentUserDocument, options);
        }
export type OffersForCurentUserQueryHookResult = ReturnType<typeof useOffersForCurentUserQuery>;
export type OffersForCurentUserLazyQueryHookResult = ReturnType<typeof useOffersForCurentUserLazyQuery>;
export type OffersForCurentUserQueryResult = Apollo.QueryResult<Types.OffersForCurentUserQuery, Types.OffersForCurentUserQueryVariables>;
export const OpenHousesForCurrentUserDocument = gql`
    query OpenHousesForCurrentUser($cursor: String, $perPage: Int! = 10, $orderBy: String! = "-date", $filters: OpenHousesFilterArgs) {
  openHousesForCurrentUser(
    cursor: $cursor
    perPage: $perPage
    orderBy: $orderBy
    filters: $filters
  ) {
    pageInfo {
      ...PageInfo
    }
    results {
      ...OpenHouseForCurrentUser
    }
  }
}
    ${PageInfoFragmentDoc}
${OpenHouseForCurrentUserFragmentDoc}`;

/**
 * __useOpenHousesForCurrentUserQuery__
 *
 * To run a query within a React component, call `useOpenHousesForCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpenHousesForCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenHousesForCurrentUserQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      perPage: // value for 'perPage'
 *      orderBy: // value for 'orderBy'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useOpenHousesForCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<Types.OpenHousesForCurrentUserQuery, Types.OpenHousesForCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OpenHousesForCurrentUserQuery, Types.OpenHousesForCurrentUserQueryVariables>(OpenHousesForCurrentUserDocument, options);
      }
export function useOpenHousesForCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OpenHousesForCurrentUserQuery, Types.OpenHousesForCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OpenHousesForCurrentUserQuery, Types.OpenHousesForCurrentUserQueryVariables>(OpenHousesForCurrentUserDocument, options);
        }
export type OpenHousesForCurrentUserQueryHookResult = ReturnType<typeof useOpenHousesForCurrentUserQuery>;
export type OpenHousesForCurrentUserLazyQueryHookResult = ReturnType<typeof useOpenHousesForCurrentUserLazyQuery>;
export type OpenHousesForCurrentUserQueryResult = Apollo.QueryResult<Types.OpenHousesForCurrentUserQuery, Types.OpenHousesForCurrentUserQueryVariables>;
export const OpenHouseDocument = gql`
    query OpenHouse($input: OpenHouseInput!) {
  openHouse(input: $input) {
    id
    date
    startTime
    endTime
    listing {
      id
      property {
        slug
      }
      mlsListing {
        id
        mlsId
        mls {
          originatingSystemName
        }
        address {
          full
          state
          city
          postalCode
        }
        photos
      }
    }
  }
}
    `;

/**
 * __useOpenHouseQuery__
 *
 * To run a query within a React component, call `useOpenHouseQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpenHouseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenHouseQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOpenHouseQuery(baseOptions: Apollo.QueryHookOptions<Types.OpenHouseQuery, Types.OpenHouseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OpenHouseQuery, Types.OpenHouseQueryVariables>(OpenHouseDocument, options);
      }
export function useOpenHouseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OpenHouseQuery, Types.OpenHouseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OpenHouseQuery, Types.OpenHouseQueryVariables>(OpenHouseDocument, options);
        }
export type OpenHouseQueryHookResult = ReturnType<typeof useOpenHouseQuery>;
export type OpenHouseLazyQueryHookResult = ReturnType<typeof useOpenHouseLazyQuery>;
export type OpenHouseQueryResult = Apollo.QueryResult<Types.OpenHouseQuery, Types.OpenHouseQueryVariables>;
export const OrgListingAggDeltaByStatusDocument = gql`
    query OrgListingAggDeltaByStatus($input: OrgListingAggDeltaByStatusInput!) {
  orgListingAggDeltaByStatus(input: $input) {
    organization {
      ...Organization
    }
    differences {
      status
      countDifference
      sumListPriceDifference
    }
  }
}
    ${OrganizationFragmentDoc}`;

/**
 * __useOrgListingAggDeltaByStatusQuery__
 *
 * To run a query within a React component, call `useOrgListingAggDeltaByStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgListingAggDeltaByStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgListingAggDeltaByStatusQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrgListingAggDeltaByStatusQuery(baseOptions: Apollo.QueryHookOptions<Types.OrgListingAggDeltaByStatusQuery, Types.OrgListingAggDeltaByStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OrgListingAggDeltaByStatusQuery, Types.OrgListingAggDeltaByStatusQueryVariables>(OrgListingAggDeltaByStatusDocument, options);
      }
export function useOrgListingAggDeltaByStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OrgListingAggDeltaByStatusQuery, Types.OrgListingAggDeltaByStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OrgListingAggDeltaByStatusQuery, Types.OrgListingAggDeltaByStatusQueryVariables>(OrgListingAggDeltaByStatusDocument, options);
        }
export type OrgListingAggDeltaByStatusQueryHookResult = ReturnType<typeof useOrgListingAggDeltaByStatusQuery>;
export type OrgListingAggDeltaByStatusLazyQueryHookResult = ReturnType<typeof useOrgListingAggDeltaByStatusLazyQuery>;
export type OrgListingAggDeltaByStatusQueryResult = Apollo.QueryResult<Types.OrgListingAggDeltaByStatusQuery, Types.OrgListingAggDeltaByStatusQueryVariables>;
export const OrgTransactionStatsByZipCodeDocument = gql`
    query orgTransactionStatsByZipCode($filters: AgentPerformanceFilters!) {
  orgTransactionStatsByZipCode(filters: $filters) {
    buy_side_homes_count
    unknown_homes_count
    zip_code
    buy_side_volume
    closed_homes_count
    closed_volume
    pending_homes_count
    pending_volume
    sell_side_homes_count
    sell_side_volume
    total_homes_count
    total_volume
  }
}
    `;

/**
 * __useOrgTransactionStatsByZipCodeQuery__
 *
 * To run a query within a React component, call `useOrgTransactionStatsByZipCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgTransactionStatsByZipCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgTransactionStatsByZipCodeQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useOrgTransactionStatsByZipCodeQuery(baseOptions: Apollo.QueryHookOptions<Types.OrgTransactionStatsByZipCodeQuery, Types.OrgTransactionStatsByZipCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OrgTransactionStatsByZipCodeQuery, Types.OrgTransactionStatsByZipCodeQueryVariables>(OrgTransactionStatsByZipCodeDocument, options);
      }
export function useOrgTransactionStatsByZipCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OrgTransactionStatsByZipCodeQuery, Types.OrgTransactionStatsByZipCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OrgTransactionStatsByZipCodeQuery, Types.OrgTransactionStatsByZipCodeQueryVariables>(OrgTransactionStatsByZipCodeDocument, options);
        }
export type OrgTransactionStatsByZipCodeQueryHookResult = ReturnType<typeof useOrgTransactionStatsByZipCodeQuery>;
export type OrgTransactionStatsByZipCodeLazyQueryHookResult = ReturnType<typeof useOrgTransactionStatsByZipCodeLazyQuery>;
export type OrgTransactionStatsByZipCodeQueryResult = Apollo.QueryResult<Types.OrgTransactionStatsByZipCodeQuery, Types.OrgTransactionStatsByZipCodeQueryVariables>;
export const OrganizationListingAggregatesByStatusDocument = gql`
    query OrganizationListingAggregatesByStatus($organizationId: String!) {
  organizationListingAggregatesByStatus(organizationId: $organizationId) {
    organization {
      ...Organization
    }
    aggregates {
      status
      count
      sumListPrice
      sumClosePrice
    }
  }
}
    ${OrganizationFragmentDoc}`;

/**
 * __useOrganizationListingAggregatesByStatusQuery__
 *
 * To run a query within a React component, call `useOrganizationListingAggregatesByStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationListingAggregatesByStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationListingAggregatesByStatusQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationListingAggregatesByStatusQuery(baseOptions: Apollo.QueryHookOptions<Types.OrganizationListingAggregatesByStatusQuery, Types.OrganizationListingAggregatesByStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OrganizationListingAggregatesByStatusQuery, Types.OrganizationListingAggregatesByStatusQueryVariables>(OrganizationListingAggregatesByStatusDocument, options);
      }
export function useOrganizationListingAggregatesByStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OrganizationListingAggregatesByStatusQuery, Types.OrganizationListingAggregatesByStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OrganizationListingAggregatesByStatusQuery, Types.OrganizationListingAggregatesByStatusQueryVariables>(OrganizationListingAggregatesByStatusDocument, options);
        }
export type OrganizationListingAggregatesByStatusQueryHookResult = ReturnType<typeof useOrganizationListingAggregatesByStatusQuery>;
export type OrganizationListingAggregatesByStatusLazyQueryHookResult = ReturnType<typeof useOrganizationListingAggregatesByStatusLazyQuery>;
export type OrganizationListingAggregatesByStatusQueryResult = Apollo.QueryResult<Types.OrganizationListingAggregatesByStatusQuery, Types.OrganizationListingAggregatesByStatusQueryVariables>;
export const OrganizationsForCurrentUserDocument = gql`
    query organizationsForCurrentUser {
  organizationsForCurrentUser {
    ...Organization
  }
}
    ${OrganizationFragmentDoc}`;

/**
 * __useOrganizationsForCurrentUserQuery__
 *
 * To run a query within a React component, call `useOrganizationsForCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsForCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsForCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationsForCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<Types.OrganizationsForCurrentUserQuery, Types.OrganizationsForCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OrganizationsForCurrentUserQuery, Types.OrganizationsForCurrentUserQueryVariables>(OrganizationsForCurrentUserDocument, options);
      }
export function useOrganizationsForCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OrganizationsForCurrentUserQuery, Types.OrganizationsForCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OrganizationsForCurrentUserQuery, Types.OrganizationsForCurrentUserQueryVariables>(OrganizationsForCurrentUserDocument, options);
        }
export type OrganizationsForCurrentUserQueryHookResult = ReturnType<typeof useOrganizationsForCurrentUserQuery>;
export type OrganizationsForCurrentUserLazyQueryHookResult = ReturnType<typeof useOrganizationsForCurrentUserLazyQuery>;
export type OrganizationsForCurrentUserQueryResult = Apollo.QueryResult<Types.OrganizationsForCurrentUserQuery, Types.OrganizationsForCurrentUserQueryVariables>;
export const PartyAndBuyerInfoDocument = gql`
    query PartyAndBuyerInfo($input: PartyAndBuyerInfoInput!) {
  partyAndBuyerInfo(input: $input) {
    ...Party
  }
}
    ${PartyFragmentDoc}`;

/**
 * __usePartyAndBuyerInfoQuery__
 *
 * To run a query within a React component, call `usePartyAndBuyerInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartyAndBuyerInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartyAndBuyerInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePartyAndBuyerInfoQuery(baseOptions: Apollo.QueryHookOptions<Types.PartyAndBuyerInfoQuery, Types.PartyAndBuyerInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PartyAndBuyerInfoQuery, Types.PartyAndBuyerInfoQueryVariables>(PartyAndBuyerInfoDocument, options);
      }
export function usePartyAndBuyerInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PartyAndBuyerInfoQuery, Types.PartyAndBuyerInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PartyAndBuyerInfoQuery, Types.PartyAndBuyerInfoQueryVariables>(PartyAndBuyerInfoDocument, options);
        }
export type PartyAndBuyerInfoQueryHookResult = ReturnType<typeof usePartyAndBuyerInfoQuery>;
export type PartyAndBuyerInfoLazyQueryHookResult = ReturnType<typeof usePartyAndBuyerInfoLazyQuery>;
export type PartyAndBuyerInfoQueryResult = Apollo.QueryResult<Types.PartyAndBuyerInfoQuery, Types.PartyAndBuyerInfoQueryVariables>;
export const PartyVariableValuesDocument = gql`
    query partyVariableValues($partyIds: [ID!]!) {
  partyAutofillVariableValues(partyIds: $partyIds) {
    buyer {
      ...AutofillConsumer
    }
    coBuyer {
      ...AutofillConsumer
    }
    buyersAgent {
      ...User
    }
    buyersAgentRESOMemberData {
      ...RESOMember
    }
    buyersAgentRESOOfficeData {
      ...RESOOffice
    }
    partyFinancing {
      id
      financingType
      loanAmount
    }
    buyerSignatures
    buyersAgentSignatures
  }
}
    ${AutofillConsumerFragmentDoc}
${UserFragmentDoc}
${ResoMemberFragmentDoc}
${ResoOfficeFragmentDoc}`;

/**
 * __usePartyVariableValuesQuery__
 *
 * To run a query within a React component, call `usePartyVariableValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartyVariableValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartyVariableValuesQuery({
 *   variables: {
 *      partyIds: // value for 'partyIds'
 *   },
 * });
 */
export function usePartyVariableValuesQuery(baseOptions: Apollo.QueryHookOptions<Types.PartyVariableValuesQuery, Types.PartyVariableValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PartyVariableValuesQuery, Types.PartyVariableValuesQueryVariables>(PartyVariableValuesDocument, options);
      }
export function usePartyVariableValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PartyVariableValuesQuery, Types.PartyVariableValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PartyVariableValuesQuery, Types.PartyVariableValuesQueryVariables>(PartyVariableValuesDocument, options);
        }
export type PartyVariableValuesQueryHookResult = ReturnType<typeof usePartyVariableValuesQuery>;
export type PartyVariableValuesLazyQueryHookResult = ReturnType<typeof usePartyVariableValuesLazyQuery>;
export type PartyVariableValuesQueryResult = Apollo.QueryResult<Types.PartyVariableValuesQuery, Types.PartyVariableValuesQueryVariables>;
export const PerAgentOfferAcceptRateDocument = gql`
    query perAgentOfferAcceptRate($filters: AgentPerformanceFilters!) {
  perAgentOfferAcceptRate(filters: $filters) {
    ...PerAgentOfferAcceptRate
  }
}
    ${PerAgentOfferAcceptRateFragmentDoc}`;

/**
 * __usePerAgentOfferAcceptRateQuery__
 *
 * To run a query within a React component, call `usePerAgentOfferAcceptRateQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerAgentOfferAcceptRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerAgentOfferAcceptRateQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePerAgentOfferAcceptRateQuery(baseOptions: Apollo.QueryHookOptions<Types.PerAgentOfferAcceptRateQuery, Types.PerAgentOfferAcceptRateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PerAgentOfferAcceptRateQuery, Types.PerAgentOfferAcceptRateQueryVariables>(PerAgentOfferAcceptRateDocument, options);
      }
export function usePerAgentOfferAcceptRateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PerAgentOfferAcceptRateQuery, Types.PerAgentOfferAcceptRateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PerAgentOfferAcceptRateQuery, Types.PerAgentOfferAcceptRateQueryVariables>(PerAgentOfferAcceptRateDocument, options);
        }
export type PerAgentOfferAcceptRateQueryHookResult = ReturnType<typeof usePerAgentOfferAcceptRateQuery>;
export type PerAgentOfferAcceptRateLazyQueryHookResult = ReturnType<typeof usePerAgentOfferAcceptRateLazyQuery>;
export type PerAgentOfferAcceptRateQueryResult = Apollo.QueryResult<Types.PerAgentOfferAcceptRateQuery, Types.PerAgentOfferAcceptRateQueryVariables>;
export const PerAgentTransactionStatsDocument = gql`
    query perAgentTransactionStats($filters: AgentPerformanceFilters!) {
  perAgentTransactionStats(filters: $filters) {
    ...PerAgentTransactionStats
  }
}
    ${PerAgentTransactionStatsFragmentDoc}`;

/**
 * __usePerAgentTransactionStatsQuery__
 *
 * To run a query within a React component, call `usePerAgentTransactionStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerAgentTransactionStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerAgentTransactionStatsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePerAgentTransactionStatsQuery(baseOptions: Apollo.QueryHookOptions<Types.PerAgentTransactionStatsQuery, Types.PerAgentTransactionStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PerAgentTransactionStatsQuery, Types.PerAgentTransactionStatsQueryVariables>(PerAgentTransactionStatsDocument, options);
      }
export function usePerAgentTransactionStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PerAgentTransactionStatsQuery, Types.PerAgentTransactionStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PerAgentTransactionStatsQuery, Types.PerAgentTransactionStatsQueryVariables>(PerAgentTransactionStatsDocument, options);
        }
export type PerAgentTransactionStatsQueryHookResult = ReturnType<typeof usePerAgentTransactionStatsQuery>;
export type PerAgentTransactionStatsLazyQueryHookResult = ReturnType<typeof usePerAgentTransactionStatsLazyQuery>;
export type PerAgentTransactionStatsQueryResult = Apollo.QueryResult<Types.PerAgentTransactionStatsQuery, Types.PerAgentTransactionStatsQueryVariables>;
export const PreferencesDocument = gql`
    query Preferences($input: PreferencesInput!) {
  preferences(input: $input) {
    ...Preference
  }
}
    ${PreferenceFragmentDoc}`;

/**
 * __usePreferencesQuery__
 *
 * To run a query within a React component, call `usePreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreferencesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePreferencesQuery(baseOptions: Apollo.QueryHookOptions<Types.PreferencesQuery, Types.PreferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PreferencesQuery, Types.PreferencesQueryVariables>(PreferencesDocument, options);
      }
export function usePreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PreferencesQuery, Types.PreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PreferencesQuery, Types.PreferencesQueryVariables>(PreferencesDocument, options);
        }
export type PreferencesQueryHookResult = ReturnType<typeof usePreferencesQuery>;
export type PreferencesLazyQueryHookResult = ReturnType<typeof usePreferencesLazyQuery>;
export type PreferencesQueryResult = Apollo.QueryResult<Types.PreferencesQuery, Types.PreferencesQueryVariables>;
export const PrioritizedListingsForCurrentUserDocument = gql`
    query PrioritizedListingsForCurrentUser($cursor: String, $preferenceValues: Boolean = false, $perPage: Int! = 15, $orderBy: String! = "-lastActivityDate") {
  prioritizedListingsForCurrentUser(
    cursor: $cursor
    perPage: $perPage
    orderBy: $orderBy
  ) {
    pageInfo {
      ...PageInfo
    }
    results {
      ...PrioritizedListing
    }
  }
}
    ${PageInfoFragmentDoc}
${PrioritizedListingFragmentDoc}`;

/**
 * __usePrioritizedListingsForCurrentUserQuery__
 *
 * To run a query within a React component, call `usePrioritizedListingsForCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrioritizedListingsForCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrioritizedListingsForCurrentUserQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      preferenceValues: // value for 'preferenceValues'
 *      perPage: // value for 'perPage'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function usePrioritizedListingsForCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<Types.PrioritizedListingsForCurrentUserQuery, Types.PrioritizedListingsForCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PrioritizedListingsForCurrentUserQuery, Types.PrioritizedListingsForCurrentUserQueryVariables>(PrioritizedListingsForCurrentUserDocument, options);
      }
export function usePrioritizedListingsForCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PrioritizedListingsForCurrentUserQuery, Types.PrioritizedListingsForCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PrioritizedListingsForCurrentUserQuery, Types.PrioritizedListingsForCurrentUserQueryVariables>(PrioritizedListingsForCurrentUserDocument, options);
        }
export type PrioritizedListingsForCurrentUserQueryHookResult = ReturnType<typeof usePrioritizedListingsForCurrentUserQuery>;
export type PrioritizedListingsForCurrentUserLazyQueryHookResult = ReturnType<typeof usePrioritizedListingsForCurrentUserLazyQuery>;
export type PrioritizedListingsForCurrentUserQueryResult = Apollo.QueryResult<Types.PrioritizedListingsForCurrentUserQuery, Types.PrioritizedListingsForCurrentUserQueryVariables>;
export const PublicFeaturedStorefrontsDocument = gql`
    query publicFeaturedStorefronts($perPage: Int = 10, $cursor: String, $orderBy: String = "-listDate") {
  featuredStorefronts(perPage: $perPage, cursor: $cursor, orderBy: $orderBy) {
    pageInfo {
      ...PageInfo
    }
    results {
      ...PublicFeaturedStorefront
    }
  }
}
    ${PageInfoFragmentDoc}
${PublicFeaturedStorefrontFragmentDoc}`;

/**
 * __usePublicFeaturedStorefrontsQuery__
 *
 * To run a query within a React component, call `usePublicFeaturedStorefrontsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicFeaturedStorefrontsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicFeaturedStorefrontsQuery({
 *   variables: {
 *      perPage: // value for 'perPage'
 *      cursor: // value for 'cursor'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function usePublicFeaturedStorefrontsQuery(baseOptions?: Apollo.QueryHookOptions<Types.PublicFeaturedStorefrontsQuery, Types.PublicFeaturedStorefrontsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublicFeaturedStorefrontsQuery, Types.PublicFeaturedStorefrontsQueryVariables>(PublicFeaturedStorefrontsDocument, options);
      }
export function usePublicFeaturedStorefrontsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublicFeaturedStorefrontsQuery, Types.PublicFeaturedStorefrontsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublicFeaturedStorefrontsQuery, Types.PublicFeaturedStorefrontsQueryVariables>(PublicFeaturedStorefrontsDocument, options);
        }
export type PublicFeaturedStorefrontsQueryHookResult = ReturnType<typeof usePublicFeaturedStorefrontsQuery>;
export type PublicFeaturedStorefrontsLazyQueryHookResult = ReturnType<typeof usePublicFeaturedStorefrontsLazyQuery>;
export type PublicFeaturedStorefrontsQueryResult = Apollo.QueryResult<Types.PublicFeaturedStorefrontsQuery, Types.PublicFeaturedStorefrontsQueryVariables>;
export const PublicListingDocument = gql`
    query PublicListing($input: PublicListingInput!) {
  publicListing(input: $input) {
    ...PublicListing
  }
}
    ${PublicListingFragmentDoc}`;

/**
 * __usePublicListingQuery__
 *
 * To run a query within a React component, call `usePublicListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicListingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublicListingQuery(baseOptions: Apollo.QueryHookOptions<Types.PublicListingQuery, Types.PublicListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublicListingQuery, Types.PublicListingQueryVariables>(PublicListingDocument, options);
      }
export function usePublicListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublicListingQuery, Types.PublicListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublicListingQuery, Types.PublicListingQueryVariables>(PublicListingDocument, options);
        }
export type PublicListingQueryHookResult = ReturnType<typeof usePublicListingQuery>;
export type PublicListingLazyQueryHookResult = ReturnType<typeof usePublicListingLazyQuery>;
export type PublicListingQueryResult = Apollo.QueryResult<Types.PublicListingQuery, Types.PublicListingQueryVariables>;
export const PublicListingAnnouncementsDocument = gql`
    query publicListingAnnouncements($input: PublicListingAnnouncementInput!) {
  publicListingAnnouncements(input: $input) {
    ...PublicListingAnnouncement
  }
}
    ${PublicListingAnnouncementFragmentDoc}`;

/**
 * __usePublicListingAnnouncementsQuery__
 *
 * To run a query within a React component, call `usePublicListingAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicListingAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicListingAnnouncementsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublicListingAnnouncementsQuery(baseOptions: Apollo.QueryHookOptions<Types.PublicListingAnnouncementsQuery, Types.PublicListingAnnouncementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublicListingAnnouncementsQuery, Types.PublicListingAnnouncementsQueryVariables>(PublicListingAnnouncementsDocument, options);
      }
export function usePublicListingAnnouncementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublicListingAnnouncementsQuery, Types.PublicListingAnnouncementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublicListingAnnouncementsQuery, Types.PublicListingAnnouncementsQueryVariables>(PublicListingAnnouncementsDocument, options);
        }
export type PublicListingAnnouncementsQueryHookResult = ReturnType<typeof usePublicListingAnnouncementsQuery>;
export type PublicListingAnnouncementsLazyQueryHookResult = ReturnType<typeof usePublicListingAnnouncementsLazyQuery>;
export type PublicListingAnnouncementsQueryResult = Apollo.QueryResult<Types.PublicListingAnnouncementsQuery, Types.PublicListingAnnouncementsQueryVariables>;
export const PublicListingComparableSetDocument = gql`
    query publicListingComparableSet($input: PublicListingComparableSetInput!) {
  publicListingComparableSet(input: $input) {
    ...PublicListingComparableSet
  }
}
    ${PublicListingComparableSetFragmentDoc}`;

/**
 * __usePublicListingComparableSetQuery__
 *
 * To run a query within a React component, call `usePublicListingComparableSetQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicListingComparableSetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicListingComparableSetQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublicListingComparableSetQuery(baseOptions: Apollo.QueryHookOptions<Types.PublicListingComparableSetQuery, Types.PublicListingComparableSetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublicListingComparableSetQuery, Types.PublicListingComparableSetQueryVariables>(PublicListingComparableSetDocument, options);
      }
export function usePublicListingComparableSetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublicListingComparableSetQuery, Types.PublicListingComparableSetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublicListingComparableSetQuery, Types.PublicListingComparableSetQueryVariables>(PublicListingComparableSetDocument, options);
        }
export type PublicListingComparableSetQueryHookResult = ReturnType<typeof usePublicListingComparableSetQuery>;
export type PublicListingComparableSetLazyQueryHookResult = ReturnType<typeof usePublicListingComparableSetLazyQuery>;
export type PublicListingComparableSetQueryResult = Apollo.QueryResult<Types.PublicListingComparableSetQuery, Types.PublicListingComparableSetQueryVariables>;
export const PublicListingDocumentTypesDocument = gql`
    query publicListingDocumentTypes($input: PublicListingDocumentTypesInput!) {
  publicListingDocumentTypes(input: $input) {
    ...PublicListingDocumentType
  }
}
    ${PublicListingDocumentTypeFragmentDoc}`;

/**
 * __usePublicListingDocumentTypesQuery__
 *
 * To run a query within a React component, call `usePublicListingDocumentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicListingDocumentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicListingDocumentTypesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublicListingDocumentTypesQuery(baseOptions: Apollo.QueryHookOptions<Types.PublicListingDocumentTypesQuery, Types.PublicListingDocumentTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublicListingDocumentTypesQuery, Types.PublicListingDocumentTypesQueryVariables>(PublicListingDocumentTypesDocument, options);
      }
export function usePublicListingDocumentTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublicListingDocumentTypesQuery, Types.PublicListingDocumentTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublicListingDocumentTypesQuery, Types.PublicListingDocumentTypesQueryVariables>(PublicListingDocumentTypesDocument, options);
        }
export type PublicListingDocumentTypesQueryHookResult = ReturnType<typeof usePublicListingDocumentTypesQuery>;
export type PublicListingDocumentTypesLazyQueryHookResult = ReturnType<typeof usePublicListingDocumentTypesLazyQuery>;
export type PublicListingDocumentTypesQueryResult = Apollo.QueryResult<Types.PublicListingDocumentTypesQuery, Types.PublicListingDocumentTypesQueryVariables>;
export const PublicListingDocumentUploadsDocument = gql`
    query publicListingDocumentUploads($input: PublicListingDocumentUploadsInput!) {
  publicListingDocumentUploads(input: $input) {
    listingDocumentType {
      ...PublicListingDocumentType
      ...PublicListingDocumentTypeWithDocuments
    }
    ...PublicListingDocumentUpload
  }
}
    ${PublicListingDocumentTypeFragmentDoc}
${PublicListingDocumentTypeWithDocumentsFragmentDoc}
${PublicListingDocumentUploadFragmentDoc}`;

/**
 * __usePublicListingDocumentUploadsQuery__
 *
 * To run a query within a React component, call `usePublicListingDocumentUploadsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicListingDocumentUploadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicListingDocumentUploadsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublicListingDocumentUploadsQuery(baseOptions: Apollo.QueryHookOptions<Types.PublicListingDocumentUploadsQuery, Types.PublicListingDocumentUploadsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublicListingDocumentUploadsQuery, Types.PublicListingDocumentUploadsQueryVariables>(PublicListingDocumentUploadsDocument, options);
      }
export function usePublicListingDocumentUploadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublicListingDocumentUploadsQuery, Types.PublicListingDocumentUploadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublicListingDocumentUploadsQuery, Types.PublicListingDocumentUploadsQueryVariables>(PublicListingDocumentUploadsDocument, options);
        }
export type PublicListingDocumentUploadsQueryHookResult = ReturnType<typeof usePublicListingDocumentUploadsQuery>;
export type PublicListingDocumentUploadsLazyQueryHookResult = ReturnType<typeof usePublicListingDocumentUploadsLazyQuery>;
export type PublicListingDocumentUploadsQueryResult = Apollo.QueryResult<Types.PublicListingDocumentUploadsQuery, Types.PublicListingDocumentUploadsQueryVariables>;
export const PublicPreferencesDocument = gql`
    query PublicPreferences($input: PublicPreferencesInput!) {
  publicPreferences(input: $input) {
    ...PublicPreference
  }
}
    ${PublicPreferenceFragmentDoc}`;

/**
 * __usePublicPreferencesQuery__
 *
 * To run a query within a React component, call `usePublicPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicPreferencesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublicPreferencesQuery(baseOptions: Apollo.QueryHookOptions<Types.PublicPreferencesQuery, Types.PublicPreferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublicPreferencesQuery, Types.PublicPreferencesQueryVariables>(PublicPreferencesDocument, options);
      }
export function usePublicPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublicPreferencesQuery, Types.PublicPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublicPreferencesQuery, Types.PublicPreferencesQueryVariables>(PublicPreferencesDocument, options);
        }
export type PublicPreferencesQueryHookResult = ReturnType<typeof usePublicPreferencesQuery>;
export type PublicPreferencesLazyQueryHookResult = ReturnType<typeof usePublicPreferencesLazyQuery>;
export type PublicPreferencesQueryResult = Apollo.QueryResult<Types.PublicPreferencesQuery, Types.PublicPreferencesQueryVariables>;
export const PublicAgentUserListingsDocument = gql`
    query PublicAgentUserListings($cursor: String, $perPage: Int! = 20, $orderBy: String! = "-listDate", $input: PublicAgentUserListingsInput!) {
  publicAgentUserListings(
    cursor: $cursor
    perPage: $perPage
    orderBy: $orderBy
    input: $input
  ) {
    pageInfo {
      ...PageInfo
    }
    results {
      ...PublicUserListing
    }
  }
}
    ${PageInfoFragmentDoc}
${PublicUserListingFragmentDoc}`;

/**
 * __usePublicAgentUserListingsQuery__
 *
 * To run a query within a React component, call `usePublicAgentUserListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicAgentUserListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicAgentUserListingsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      perPage: // value for 'perPage'
 *      orderBy: // value for 'orderBy'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublicAgentUserListingsQuery(baseOptions: Apollo.QueryHookOptions<Types.PublicAgentUserListingsQuery, Types.PublicAgentUserListingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublicAgentUserListingsQuery, Types.PublicAgentUserListingsQueryVariables>(PublicAgentUserListingsDocument, options);
      }
export function usePublicAgentUserListingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublicAgentUserListingsQuery, Types.PublicAgentUserListingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublicAgentUserListingsQuery, Types.PublicAgentUserListingsQueryVariables>(PublicAgentUserListingsDocument, options);
        }
export type PublicAgentUserListingsQueryHookResult = ReturnType<typeof usePublicAgentUserListingsQuery>;
export type PublicAgentUserListingsLazyQueryHookResult = ReturnType<typeof usePublicAgentUserListingsLazyQuery>;
export type PublicAgentUserListingsQueryResult = Apollo.QueryResult<Types.PublicAgentUserListingsQuery, Types.PublicAgentUserListingsQueryVariables>;
export const PublicUserWithLicensesDocument = gql`
    query PublicUserWithLicenses($input: GetUserInput!) {
  getPublicUserWithLicenses(input: $input) {
    ...PublicUserWithLicenses
  }
}
    ${PublicUserWithLicensesFragmentDoc}`;

/**
 * __usePublicUserWithLicensesQuery__
 *
 * To run a query within a React component, call `usePublicUserWithLicensesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicUserWithLicensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicUserWithLicensesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublicUserWithLicensesQuery(baseOptions: Apollo.QueryHookOptions<Types.PublicUserWithLicensesQuery, Types.PublicUserWithLicensesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PublicUserWithLicensesQuery, Types.PublicUserWithLicensesQueryVariables>(PublicUserWithLicensesDocument, options);
      }
export function usePublicUserWithLicensesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PublicUserWithLicensesQuery, Types.PublicUserWithLicensesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PublicUserWithLicensesQuery, Types.PublicUserWithLicensesQueryVariables>(PublicUserWithLicensesDocument, options);
        }
export type PublicUserWithLicensesQueryHookResult = ReturnType<typeof usePublicUserWithLicensesQuery>;
export type PublicUserWithLicensesLazyQueryHookResult = ReturnType<typeof usePublicUserWithLicensesLazyQuery>;
export type PublicUserWithLicensesQueryResult = Apollo.QueryResult<Types.PublicUserWithLicensesQuery, Types.PublicUserWithLicensesQueryVariables>;
export const SearchMlsListingsByAddressDocument = gql`
    query SearchMlsListingsByAddress($input: SearchMlsListingsByAddressInput!) {
  searchMlsListingsByAddress(input: $input) {
    ...MLSListing
  }
}
    ${MlsListingFragmentDoc}`;

/**
 * __useSearchMlsListingsByAddressQuery__
 *
 * To run a query within a React component, call `useSearchMlsListingsByAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchMlsListingsByAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchMlsListingsByAddressQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchMlsListingsByAddressQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchMlsListingsByAddressQuery, Types.SearchMlsListingsByAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchMlsListingsByAddressQuery, Types.SearchMlsListingsByAddressQueryVariables>(SearchMlsListingsByAddressDocument, options);
      }
export function useSearchMlsListingsByAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchMlsListingsByAddressQuery, Types.SearchMlsListingsByAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchMlsListingsByAddressQuery, Types.SearchMlsListingsByAddressQueryVariables>(SearchMlsListingsByAddressDocument, options);
        }
export type SearchMlsListingsByAddressQueryHookResult = ReturnType<typeof useSearchMlsListingsByAddressQuery>;
export type SearchMlsListingsByAddressLazyQueryHookResult = ReturnType<typeof useSearchMlsListingsByAddressLazyQuery>;
export type SearchMlsListingsByAddressQueryResult = Apollo.QueryResult<Types.SearchMlsListingsByAddressQuery, Types.SearchMlsListingsByAddressQueryVariables>;
export const SearchMlsListingsWithFiltersDocument = gql`
    query SearchMlsListingsWithFilters($input: SearchMLSListingsWithFiltersInput!) {
  searchMlsListingsWithFilters(input: $input) {
    totalCount
    mlsListings {
      mlsListing {
        ...MLSListing
      }
      listing {
        ...ListingForSearchMlsListing
      }
    }
  }
}
    ${MlsListingFragmentDoc}
${ListingForSearchMlsListingFragmentDoc}`;

/**
 * __useSearchMlsListingsWithFiltersQuery__
 *
 * To run a query within a React component, call `useSearchMlsListingsWithFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchMlsListingsWithFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchMlsListingsWithFiltersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchMlsListingsWithFiltersQuery(baseOptions: Apollo.QueryHookOptions<Types.SearchMlsListingsWithFiltersQuery, Types.SearchMlsListingsWithFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SearchMlsListingsWithFiltersQuery, Types.SearchMlsListingsWithFiltersQueryVariables>(SearchMlsListingsWithFiltersDocument, options);
      }
export function useSearchMlsListingsWithFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchMlsListingsWithFiltersQuery, Types.SearchMlsListingsWithFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SearchMlsListingsWithFiltersQuery, Types.SearchMlsListingsWithFiltersQueryVariables>(SearchMlsListingsWithFiltersDocument, options);
        }
export type SearchMlsListingsWithFiltersQueryHookResult = ReturnType<typeof useSearchMlsListingsWithFiltersQuery>;
export type SearchMlsListingsWithFiltersLazyQueryHookResult = ReturnType<typeof useSearchMlsListingsWithFiltersLazyQuery>;
export type SearchMlsListingsWithFiltersQueryResult = Apollo.QueryResult<Types.SearchMlsListingsWithFiltersQuery, Types.SearchMlsListingsWithFiltersQueryVariables>;
export const StorefrontActivitiesDocument = gql`
    query StorefrontActivities($input: StorefrontActivitiesInput!) {
  storefrontActivities(input: $input) {
    id
    type
    user {
      ...PublicUser
    }
    metadata
    createdAt
  }
}
    ${PublicUserFragmentDoc}`;

/**
 * __useStorefrontActivitiesQuery__
 *
 * To run a query within a React component, call `useStorefrontActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorefrontActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorefrontActivitiesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStorefrontActivitiesQuery(baseOptions: Apollo.QueryHookOptions<Types.StorefrontActivitiesQuery, Types.StorefrontActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.StorefrontActivitiesQuery, Types.StorefrontActivitiesQueryVariables>(StorefrontActivitiesDocument, options);
      }
export function useStorefrontActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.StorefrontActivitiesQuery, Types.StorefrontActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.StorefrontActivitiesQuery, Types.StorefrontActivitiesQueryVariables>(StorefrontActivitiesDocument, options);
        }
export type StorefrontActivitiesQueryHookResult = ReturnType<typeof useStorefrontActivitiesQuery>;
export type StorefrontActivitiesLazyQueryHookResult = ReturnType<typeof useStorefrontActivitiesLazyQuery>;
export type StorefrontActivitiesQueryResult = Apollo.QueryResult<Types.StorefrontActivitiesQuery, Types.StorefrontActivitiesQueryVariables>;
export const StorefrontActivitiesSummaryDocument = gql`
    query StorefrontActivitiesSummary($input: StorefrontActivitiesSummaryInput!) {
  storefrontActivitiesSummary(input: $input) {
    ...StorefrontActivitySummary
  }
}
    ${StorefrontActivitySummaryFragmentDoc}`;

/**
 * __useStorefrontActivitiesSummaryQuery__
 *
 * To run a query within a React component, call `useStorefrontActivitiesSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorefrontActivitiesSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorefrontActivitiesSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStorefrontActivitiesSummaryQuery(baseOptions: Apollo.QueryHookOptions<Types.StorefrontActivitiesSummaryQuery, Types.StorefrontActivitiesSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.StorefrontActivitiesSummaryQuery, Types.StorefrontActivitiesSummaryQueryVariables>(StorefrontActivitiesSummaryDocument, options);
      }
export function useStorefrontActivitiesSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.StorefrontActivitiesSummaryQuery, Types.StorefrontActivitiesSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.StorefrontActivitiesSummaryQuery, Types.StorefrontActivitiesSummaryQueryVariables>(StorefrontActivitiesSummaryDocument, options);
        }
export type StorefrontActivitiesSummaryQueryHookResult = ReturnType<typeof useStorefrontActivitiesSummaryQuery>;
export type StorefrontActivitiesSummaryLazyQueryHookResult = ReturnType<typeof useStorefrontActivitiesSummaryLazyQuery>;
export type StorefrontActivitiesSummaryQueryResult = Apollo.QueryResult<Types.StorefrontActivitiesSummaryQuery, Types.StorefrontActivitiesSummaryQueryVariables>;
export const StorefrontDocViewsSummaryDocument = gql`
    query StorefrontDocViewsSummary($input: StorefrontActivitySummaryInput!) {
  storefrontActivitySummary(input: $input) {
    count
    users {
      ...User
    }
    activities {
      id
      type
      createdAt
      user {
        id
      }
    }
  }
}
    ${UserFragmentDoc}`;

/**
 * __useStorefrontDocViewsSummaryQuery__
 *
 * To run a query within a React component, call `useStorefrontDocViewsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorefrontDocViewsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorefrontDocViewsSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStorefrontDocViewsSummaryQuery(baseOptions: Apollo.QueryHookOptions<Types.StorefrontDocViewsSummaryQuery, Types.StorefrontDocViewsSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.StorefrontDocViewsSummaryQuery, Types.StorefrontDocViewsSummaryQueryVariables>(StorefrontDocViewsSummaryDocument, options);
      }
export function useStorefrontDocViewsSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.StorefrontDocViewsSummaryQuery, Types.StorefrontDocViewsSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.StorefrontDocViewsSummaryQuery, Types.StorefrontDocViewsSummaryQueryVariables>(StorefrontDocViewsSummaryDocument, options);
        }
export type StorefrontDocViewsSummaryQueryHookResult = ReturnType<typeof useStorefrontDocViewsSummaryQuery>;
export type StorefrontDocViewsSummaryLazyQueryHookResult = ReturnType<typeof useStorefrontDocViewsSummaryLazyQuery>;
export type StorefrontDocViewsSummaryQueryResult = Apollo.QueryResult<Types.StorefrontDocViewsSummaryQuery, Types.StorefrontDocViewsSummaryQueryVariables>;
export const GuestbookSignersDocument = gql`
    query GuestbookSigners($input: ListingInput!) {
  guestbookSigners(input: $input) {
    signers {
      ...GuestbookSigner
    }
  }
}
    ${GuestbookSignerFragmentDoc}`;

/**
 * __useGuestbookSignersQuery__
 *
 * To run a query within a React component, call `useGuestbookSignersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuestbookSignersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuestbookSignersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGuestbookSignersQuery(baseOptions: Apollo.QueryHookOptions<Types.GuestbookSignersQuery, Types.GuestbookSignersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GuestbookSignersQuery, Types.GuestbookSignersQueryVariables>(GuestbookSignersDocument, options);
      }
export function useGuestbookSignersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GuestbookSignersQuery, Types.GuestbookSignersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GuestbookSignersQuery, Types.GuestbookSignersQueryVariables>(GuestbookSignersDocument, options);
        }
export type GuestbookSignersQueryHookResult = ReturnType<typeof useGuestbookSignersQuery>;
export type GuestbookSignersLazyQueryHookResult = ReturnType<typeof useGuestbookSignersLazyQuery>;
export type GuestbookSignersQueryResult = Apollo.QueryResult<Types.GuestbookSignersQuery, Types.GuestbookSignersQueryVariables>;
export const ZipCodesGeoDocument = gql`
    query zipCodesGeo($filters: ZipCodesGeoFilters!) {
  zipCodesGeo(filters: $filters) {
    zip_code
    city
    county_names
    geo_shape
  }
}
    `;

/**
 * __useZipCodesGeoQuery__
 *
 * To run a query within a React component, call `useZipCodesGeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useZipCodesGeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZipCodesGeoQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useZipCodesGeoQuery(baseOptions: Apollo.QueryHookOptions<Types.ZipCodesGeoQuery, Types.ZipCodesGeoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ZipCodesGeoQuery, Types.ZipCodesGeoQueryVariables>(ZipCodesGeoDocument, options);
      }
export function useZipCodesGeoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ZipCodesGeoQuery, Types.ZipCodesGeoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ZipCodesGeoQuery, Types.ZipCodesGeoQueryVariables>(ZipCodesGeoDocument, options);
        }
export type ZipCodesGeoQueryHookResult = ReturnType<typeof useZipCodesGeoQuery>;
export type ZipCodesGeoLazyQueryHookResult = ReturnType<typeof useZipCodesGeoLazyQuery>;
export type ZipCodesGeoQueryResult = Apollo.QueryResult<Types.ZipCodesGeoQuery, Types.ZipCodesGeoQueryVariables>;