import { ArrowDownIcon } from '@chakra-ui/icons';
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Divider,
  Grid,
  GridProps,
  Icon,
  Text,
  TextProps,
} from '@chakra-ui/react';
import EventEmitter from 'eventemitter3';
import React, {
  FC,
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

export interface OrderBy {
  key: string;
  order: 'asc' | 'desc';
}

export interface GridTableProps {
  gridTemplateColumns: BoxProps['gridTemplateColumns'];
  children: React.ReactNode;
  orderBy?: OrderBy | null;
  onOrderBy?: (orderBy: OrderBy) => void;
}

export interface GridTableContextValues {
  gridTemplateColumns: BoxProps['gridTemplateColumns'];
  width: BoxProps['width'];
  gridTemplateColumnsRef: React.MutableRefObject<{
    gridTemplateColumns: string;
  }>;
  headRef?: React.RefObject<HTMLDivElement | null>;
  orderBy?: OrderBy | null;
  onOrderBy?: (orderBy: OrderBy) => void;
}

export const GridTableContext = createContext<GridTableContextValues>({
  gridTemplateColumns: '',
  gridTemplateColumnsRef: { current: { gridTemplateColumns: '' } },
  width: '100%',
});

export const GridTable: FC<GridTableProps & BoxProps> = memo(
  function GridTable({
    gridTemplateColumns,
    width,
    orderBy,
    onOrderBy,
    children,
    ...props
  }) {
    const headRef = useRef<HTMLDivElement>(null);
    const gridTemplateColumnsRef = useRef<{
      gridTemplateColumns: string;
    }>({ gridTemplateColumns: gridTemplateColumns as string });

    return (
      <GridTableContext.Provider
        value={{
          gridTemplateColumns,
          width,
          headRef,
          gridTemplateColumnsRef,
          orderBy,
          onOrderBy,
        }}
      >
        <Box
          bg="white"
          borderRadius="sm"
          {...props}
          _dark={{ bg: 'transparent' }}
        >
          {children}
        </Box>
      </GridTableContext.Provider>
    );
  }
);

export const GridTableHead: FC<GridProps> = ({ children, ...props }) => {
  const { gridTemplateColumns, gridTemplateColumnsRef, width, headRef } =
    useContext(GridTableContext);

  useEffect(() => {
    const onResize = () => {
      const headItemWidths = Array.from(
        headRef?.current?.childNodes.values() || []
      ).map((child: HTMLDivElement) => {
        return child.offsetWidth;
      });

      const gridTemplateColumns = headItemWidths
        .map((width) => `${width}px`)
        .join(' ');

      window.document
        .querySelectorAll('.grid-table-row')
        .forEach((row: HTMLDivElement) => {
          row.style.gridTemplateColumns = gridTemplateColumns;
        });

      gridTemplateColumnsRef.current = { gridTemplateColumns };
    };

    setTimeout(onResize, 500);

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [gridTemplateColumnsRef, headRef]);

  return (
    <>
      <Grid
        ref={headRef}
        columnGap={2}
        gridTemplateColumns={gridTemplateColumns}
        overflowX="hidden"
        px={0}
        py={3}
        {...props}
        borderBottom="1px solid"
        borderColor="border.divider"
        width={width}
      >
        {children}
      </Grid>
    </>
  );
};

interface GridTableHeadItemProps extends TextProps {
  orderByKey?: string;
}

export const GridTableHeadItem: FC<GridTableHeadItemProps> = memo(
  function GridTableHeadItem({ children, orderByKey, ...props }) {
    const { orderBy, onOrderBy } = useContext(GridTableContext);
    const onClick = useCallback(() => {
      if (orderByKey) {
        onOrderBy?.({
          key: orderByKey,
          order:
            orderBy?.key === orderByKey
              ? orderBy.order === 'asc'
                ? 'desc'
                : 'asc'
              : 'desc',
        });
      }
    }, [onOrderBy, orderByKey, orderBy]);
    const isActive = orderByKey === orderBy?.key;

    const icon = useMemo(() => {
      const opacity = isActive ? '1 !important' : '0';

      return (
        <Icon
          _light={{ color: 'blackAlpha.900' }}
          as={ArrowDownIcon}
          color="whiteAlpha.900"
          height="14px"
          mb="2px"
          ml={1}
          opacity={opacity}
          transform={`rotate(${
            isActive ? (orderBy?.order === 'asc' ? 180 : 0) : 0
          }deg)`}
          transition="opacity,transform 0.2s ease-in-out"
          verticalAlign="middle"
          width="14px"
        />
      );
    }, [isActive, orderBy?.order]);

    return (
      <Text
        _dark={{
          color: isActive ? 'whiteAlpha.900 !important' : 'whiteAlpha.600',
        }}
        _first={{
          pl: 4,
        }}
        _hover={
          orderByKey
            ? {
                svg: {
                  opacity: 0.7,
                },
                color: 'whiteAlpha.700',
              }
            : {}
        }
        _last={{
          pr: 4,
        }}
        _light={{ color: 'blackAlpha.900' }}
        cursor={orderByKey ? 'pointer' : 'default'}
        fontSize="xs"
        fontWeight="600"
        pl="14px"
        textTransform="uppercase"
        transition="color 0.2s ease-in-out"
        userSelect="none"
        whiteSpace="nowrap"
        onClick={orderByKey ? onClick : undefined}
        {...props}
      >
        {children}
        {orderByKey ? icon : null}
      </Text>
    );
  }
);

export const GridTableRowNavLinkButton: FC<NavLinkProps & ButtonProps> = memo(
  function GridTableRowNavLinkButton({ children, width, ...props }) {
    return (
      <>
        <Button
          _activeLink={{ bgColor: 'primary.400', color: 'white' }}
          as={NavLink}
          borderRadius="none"
          display="grid"
          gap={4}
          height="50px"
          px={0}
          variant="ghost"
          whiteSpace={['break-spaces', 'inherit']}
          width={width}
          {...props}
        >
          {children}
        </Button>
        <Divider width={width} />
      </>
    );
  }
);

export const GridTableRowNavLink: FC<NavLinkProps & ButtonProps> = (props) => {
  const { gridTemplateColumns, width } = useContext(GridTableContext);

  return (
    <GridTableRowNavLinkButton
      gridTemplateColumns={gridTemplateColumns}
      width={width}
      {...props}
    />
  );
};

export const GridTableBody: FC<BoxProps> = memo(function GridTableBody({
  children,
  ...props
}) {
  const { headRef } = useContext(GridTableContext);

  const onScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      if (headRef?.current) {
        headRef.current.scrollTo({
          left: e.currentTarget.scrollLeft,
        });
      }
    },
    [headRef]
  );

  return (
    <Box overflowX="auto" {...props} onScroll={onScroll}>
      {children}
    </Box>
  );
});

export const GridTableRow: FC<
  GridProps & {
    innerRef: (ref: HTMLDivElement) => void;
    visibleEventEmitter?: EventEmitter;
    index?: number;
  }
> = memo(function GridTableRow({
  children,
  innerRef,
  visibleEventEmitter,
  index,
  ...props
}) {
  const [visible, setVisible] = useState(false);
  const { gridTemplateColumnsRef } = useContext(GridTableContext);

  useEffect(() => {
    const onVisibleRowsUpdated = (visibleRows: string[]) => {
      setVisible(visibleRows.includes(index?.toString() || ''));
    };

    if (visibleEventEmitter) {
      visibleEventEmitter.on('visibleRowsUpdated', onVisibleRowsUpdated);
    }

    return () => {
      if (visibleEventEmitter) {
        visibleEventEmitter.off('visibleRowsUpdated', onVisibleRowsUpdated);
      }
    };
  }, [index, visibleEventEmitter]);

  return (
    <Grid
      children={visible ? children : null}
      ref={innerRef}
      _light={{ color: 'blackAlpha.900' }}
      alignItems="center"
      borderBottom="1px solid"
      borderColor="border.divider"
      className="grid-table-row"
      color="whiteAlpha.900"
      columnGap={2}
      data-index={index}
      py={2}
      style={gridTemplateColumnsRef.current}
      width="max-content"
      {...props}
    />
  );
});

export const GridTableCell: FC<TextProps> = memo(function GridTableCell({
  children,
  ...props
}) {
  return (
    <Box
      _first={{
        pl: 4,
      }}
      _last={{
        pr: 4,
      }}
      textAlign="center"
      {...props}
    >
      {children}
    </Box>
  );
});
