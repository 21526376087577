export const classify = (str: string) => {
  return str
    .split(' ')
    .map((word) => {
      const lower = word.toLowerCase();
      const firstLetter = lower.charAt(0).toUpperCase();

      return firstLetter + lower.substring(1);
    })
    .join(' ');
};
